import { Col, Row, Alert, Segmented, Tooltip, Select } from 'antd'

import { DurationSlider } from './components'
import Icon from '../../../../components/Icon'
import { AnimationListComponent } from './animationListComponent'
import { AnimationsInputNumber } from './animationsInputNumber'

import { useAnimationsState } from './useAnimationsState'
import { DEFAULT_ANIMATION_EASING } from '../../slide/canvas/animations/easings'

import { animations } from '../../../../data/animations'
import { ANIMATION_TYPES, FADES, DOWN_ARROW_ICON } from './constants'

export const Animations = (props) => {
  const { data, updateSlide, activeObjectProps, onChangeTime, onChangeDuration, onChangeEasing } = props

  const {
    type,
    options,
    animationType,
    animationsArray,
    handleChangeTime,
    easingSelectData,
    animationTimeValue,
    easingSelectOptions,
    changeAnimationType,
    onInputAnimationTimeChange,
    disabledAnimationTooltipTitle,
  } = useAnimationsState({
    activeObjectProps,
    onChangeTime,
    data,
  })

  return (
    <div className="animations">
      <Row gutter={[0, 16]} align="middle">
        <Alert
          message={
            activeObjectProps
              ? 'Animation is applied only to selected element'
              : 'Animation is applied to the whole slide. To animate a separate element, please select it and then choose animation.'
          }
          type="info"
        />
        <Col span={24}>
          <Segmented
            className="segmented-default"
            block
            options={Object.values(ANIMATION_TYPES)}
            onChange={changeAnimationType}
          />
        </Col>
        {activeObjectProps && (
          <>
            <Col span={24} translate="no">
              <Tooltip title={disabledAnimationTooltipTitle}>
                <Select
                  className="animation-type-select"
                  popupClassName="animation-type-dropdown"
                  value={
                    (type === ANIMATION_TYPES.enter
                      ? activeObjectProps.animation?.type
                      : activeObjectProps.animation?.exitType) || animations.default.name
                  }
                  onChange={(v) => updateSlide(v === animations.default.name ? null : v, type)}
                  getPopupContainer={(triggerNode) => triggerNode}
                  disabled={disabledAnimationTooltipTitle.length}
                >
                  {animationsArray.map((a) => (
                    <Select.Option
                      key={a.id || animations.default.name}
                      value={a.id || animations.default.name}
                      title={a.name}
                      className="animation-type-option"
                    >
                      <>
                        <span className={`effect-preview-wrapper ${a.thumbnail.video ? '' : 'without-preview'}`}>
                          <video className="effect-preview-video" src={a.thumbnail.video} autoPlay={true} loop muted />
                          <span
                            className="effect-image-wrapper"
                            style={{ backgroundImage: `url(${a.thumbnail.image})` }}
                          />
                        </span>
                        <span className="effect-name" translate="yes">
                          {a.name}
                        </span>
                      </>
                    </Select.Option>
                  ))}
                </Select>
              </Tooltip>
            </Col>
            <Col span={12} translate="yes">
              <span>{type === ANIMATION_TYPES.enter ? 'Appearance' : 'Disappear'} time</span>
            </Col>
            <Col span={12} translate="no">
              <Select
                value={animationTimeValue}
                disabled={!activeObjectProps.animation?.[type === ANIMATION_TYPES.enter ? 'type' : 'exitType']}
                className="animation-time-select"
                popupClassName="animation-time-dropdown"
                suffixIcon={DOWN_ARROW_ICON}
                getPopupContainer={(triggerNode) => triggerNode}
                onChange={handleChangeTime}
                options={options}
                dropdownRender={(menu) => (
                  <div translate="yes">
                    {menu}
                    <Tooltip
                      title={
                        type === ANIMATION_TYPES.enter
                          ? 'Animation start time (s). By default, all objects appear one after another.'
                          : 'Animation end time (s). If negative (-0.7), animation will start at set time before slide end.'
                      }
                      placement="bottom"
                    >
                      <div className="input-time-option">
                        <Icon name="clock" className="animation-clock" />
                        <AnimationsInputNumber
                          type={type}
                          activeObjectProps={activeObjectProps}
                          onInputAnimationTimeChange={onInputAnimationTimeChange}
                          onChangeTime={onChangeTime}
                        />
                      </div>
                    </Tooltip>
                  </div>
                )}
              />
            </Col>
            <Col span={10}>
              <span>Animation duration</span>
            </Col>
            <Col push={2} span={12}>
              <DurationSlider
                type={type}
                defaultValue={type === ANIMATION_TYPES.enter ? 0.4 : 0.3}
                min={type === ANIMATION_TYPES.enter ? 0.4 : 0.3}
                value={
                  type === ANIMATION_TYPES.enter
                    ? activeObjectProps.animation?.duration
                    : activeObjectProps.animation?.exitDuration
                }
                disabled={
                  type === ANIMATION_TYPES.enter
                    ? !activeObjectProps.animation?.type
                    : !activeObjectProps.animation?.exitType
                }
                onChange={(v) => onChangeDuration(v, type)}
              />
            </Col>
            {!!animationType && !FADES.includes(animationType) && (
              <>
                <Col span={10}>
                  <span>Easing type</span>
                </Col>
                <Col push={2} span={12}>
                  <Select
                    className="easings-select"
                    popupClassName="easings-select-dropdown"
                    defaultValue={DEFAULT_ANIMATION_EASING}
                    value={easingSelectData.value}
                    disabled={easingSelectData.isDisabled}
                    suffixIcon={DOWN_ARROW_ICON}
                    onChange={(v) => onChangeEasing(v, type)}
                    getPopupContainer={(triggerNode) => triggerNode}
                  >
                    {easingSelectOptions}
                  </Select>
                </Col>
              </>
            )}
          </>
        )}
      </Row>
      {!activeObjectProps && (
        <Row gutter={[8, 8]} style={{ marginTop: 16 }}>
          {animationsArray.map((a) => (
            <AnimationListComponent type={type} animation={a} updateSlide={updateSlide} animationType={animationType} />
          ))}
        </Row>
      )}
    </div>
  )
}
