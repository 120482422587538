import { PAUSE_EMOJI } from './constants'

// Map key - search regex, value - emoji
const replacements = new Map([
  [/<break time=['"]0\.25s['"] \/>/g, PAUSE_EMOJI.small],
  [/<break time=['"]0\.5s['"] \/>/g, PAUSE_EMOJI.standard],
  [/<break time=['"]1s['"] \/>/g, PAUSE_EMOJI.medium],
  [/<break time=['"]1\.5s['"] \/>/g, PAUSE_EMOJI.large],
])

const pausEmojiToTextMap = new Map([
  [PAUSE_EMOJI.small, '<break time="0.25s" />'],
  [PAUSE_EMOJI.standard, '<break time="0.5s" />'],
  [PAUSE_EMOJI.medium, '<break time="1s" />'],
  [PAUSE_EMOJI.large, '<break time="1.5s" />'],
])

// more straighforward way
export const replaceBreaksToEmojis = (value) => {
  let userFriendly = value
  replacements.forEach((emoji, searchRegex) => {
    userFriendly = userFriendly.replace(searchRegex, emoji)
  })

  return userFriendly
}

export const replaceEmojisToBreaks = (value) => {
  let normalized = value
  pausEmojiToTextMap.forEach((replacementText, emoji) => {
    normalized = normalized.split(emoji).join(replacementText)
  })
  return normalized
}

export const findFirstDifferenceIndex = (str1, str2) => {
  const minLength = Math.min(str1.length, str2.length)
  for (let i = 0; i < minLength; i++) {
    if (str1[i] !== str2[i]) {
      return i
    }
  }
  return minLength
}

// Removes XML special characters (<, >, &, ", ') from the input text
// to prevent XML parsing issues when the text is used in XML/SSML context
const normalizeTextForPhonemeXML = (text) => {
  return text.replace(/[<>&"']/g, '')
}

// Normalizes text for IPA (International Phonetic Alphabet) processing by:
// - Converting to lowercase
// - Removing all whitespace
// - Removing all numbers and special characters
const normalizeTextForPhonemeIPA = (text) => {
  let normalized = text.toLowerCase()
  normalized = normalized.replace(/\s+/g, '')
  normalized = normalized.replace(/[0-9!@#$%^&*()_+{}[\]:;<>,.?~`/\\|=-]/g, '')
  return normalized
}

// Performs complete text normalization for phoneme processing by:
// 1. First removing XML special characters
// 2. Then normalizing for IPA processing
// This ensures the text is properly formatted for both XML/SSML and IPA contexts
export const normalizeTextForPhoneme = (text) => {
  if (!text) {
    return text
  }

  const xmlNormalized = normalizeTextForPhonemeXML(text)
  const ipaNormalized = normalizeTextForPhonemeIPA(xmlNormalized)
  return ipaNormalized
}
