import React from 'react'
import { Button, Tooltip } from 'antd'
import { SyncOutlined } from '@ant-design/icons'
import Icon from '../../../../../components/Icon'

const EditSlideButtons = ({ isStatic, isApplyingLayout, setCurrentView }) => {
  return (
    <div className="pptx-edit-slide-buttons">
      <Tooltip
        title={
          isStatic
            ? 'Switch back to the AI-converted slide with dynamic and customizable elements.'
            : 'Convert this slide into a single static image to preserve it’s original design exactly as in PowerPoint. Any dynamic or editable elements will be flattened.'
        }
        placement="bottom"
      >
        <Button
          block
          type="default"
          disabled={isApplyingLayout}
          className="pptx-convert-button"
          icon={<SyncOutlined spin={isApplyingLayout} />}
          onClick={() => setCurrentView(isStatic ? 'pptxDynamic' : `pptxStatic`)}
        >
          {!isApplyingLayout && isStatic && 'Convert slide back to dynamic'}
          {isApplyingLayout && isStatic && 'Reverting slide to static image'}
          {!isApplyingLayout && !isStatic && 'Revert slide to static image'}
          {isApplyingLayout && !isStatic && 'Converting slide back to dynamic'}
        </Button>
      </Tooltip>
      <Button block type="default" onClick={() => setCurrentView('changeLayout')} icon={<Icon name="web_layout" />}>
        Change slide layout
      </Button>
    </div>
  )
}

export default EditSlideButtons
