import { memo, useState, useCallback, useEffect, useRef } from 'react'
import { TreeSelect } from 'antd'
import uniqBy from 'lodash/uniqBy'

import { useElaiNotification } from '../../../../hooks/useElaiNotification'

import Icon from '../../../../components/Icon'

import { fetchAllPanoptoFolders } from './utils/fetchAllPanoptoFolders'

import { DEFAULT_FOLDER_NAME } from '../../constants'

import './exportPanoptoModalFolderSelector.less'

const STYLE = {
  titleText: {
    marginLeft: '8px',
  },
  select: { width: '100%' },
}

let cachedFolders = null

const createTreeNode = ({ folder }) => {
  return {
    id: folder.Id,
    pId: folder.ParentFolder?.Id || null,
    value: folder.Id,
    title: (
      <span>
        <Icon name="folder" />
        <span style={STYLE.titleText}>{folder.Name}</span>
      </span>
    ),

    // custom field not related to TreeSelect
    originalFolder: folder,
  }
}

export const ExportPanoptoModalFolderSelector = memo(({ value, onChange }) => {
  const notification = useElaiNotification()
  const [treeData, setTreeData] = useState([])
  const [isInitialLoading, setIsInitialLoading] = useState(false)

  const fetchFolders = useCallback(async () => {
    const timeout = setTimeout(() => {
      notification.info({
        message: 'Loading your folders. This may take longer than usual, please wait.',
      })
    }, 5000)

    let response = null
    if (cachedFolders) {
      response = cachedFolders
    } else {
      const initialResponse = await fetchAllPanoptoFolders()
      response = initialResponse ? uniqBy(initialResponse, 'Id') : initialResponse
    }

    cachedFolders = response

    clearTimeout(timeout)

    const defaultFolder = response.find((folder) => folder.Name === DEFAULT_FOLDER_NAME)

    // always place default folder on top of the list
    const preparedFolders = defaultFolder
      ? [{ ...defaultFolder, ParentFolder: null }, ...response.filter((folder) => folder !== defaultFolder)]
      : response

    const treeNodes = preparedFolders?.map((folder) => createTreeNode({ folder }))

    setTreeData(treeNodes)

    if (defaultFolder) {
      onChange(defaultFolder)
    }
  }, [onChange])

  const handleChange = useCallback(
    (id) => {
      const folder = treeData.find((node) => node.id === id)?.originalFolder

      onChange(folder)
    },
    [treeData, onChange],
  )

  // Search by default folder to initialize select data
  const initializedRef = useRef(false)
  useEffect(() => {
    if (initializedRef.current === true) {
      return
    }
    setIsInitialLoading(true)
    fetchFolders()
      .then(() => {
        setIsInitialLoading(false)
      })
      .catch(() => {
        setIsInitialLoading(false)
      })
    initializedRef.current = true
  }, [fetchFolders])

  return (
    <TreeSelect
      treeDataSimpleMode
      style={STYLE.select}
      popupClassName="export-panopto-modal-folder-selector"
      value={value?.Id}
      onChange={handleChange}
      treeData={treeData}
      loading={isInitialLoading}
      showSearch
      filterTreeNode={(inputValue, treeNode) =>
        treeNode.title.props.children[1].props.children.toLowerCase().includes(inputValue.toLowerCase())
      }
    />
  )
})
