import Icon from '../../../../components/Icon'
import { LeavingPopconfirm } from '../leavingPopconfirm'
import { useNavigateToAnotherPage } from './useNavigateToAnotherPage'

const EditAsStoryButton = ({ video, isVideoSaved }) => {
  if (video.template || video.status === 'rendering') return null

  const { isLeavingPopconfirmOpen, setIsLeavingPopconfirmOpen, handleNavigate } = useNavigateToAnotherPage({
    isVideoSaved,
    videoId: video._id,
    navigateUrl: `/story/${video._id}`,
  })

  return (
    <LeavingPopconfirm
      isLeavingPopconfirmOpen={isLeavingPopconfirmOpen}
      setIsLeavingPopconfirmOpen={setIsLeavingPopconfirmOpen}
      onConfirm={() => handleNavigate(true, true)}
    >
      <a className="video-name-link" onClick={() => handleNavigate(false, true)}>
        <Icon name="clipboard" />
        <span>Edit as a story</span>
      </a>
    </LeavingPopconfirm>
  )
}

export default EditAsStoryButton
