import { useEffect, useRef, useState } from 'react'
import { useStore } from '../../store'
import fabric, { checkObjectExists } from '../../utils/fabric/fabric'
import { promiseAllWithLimit, requestIdleCallback, updateVideoVersionInCache } from '../../utils/helpers'
import { CANVAS_HEIGHT, CANVAS_WIDTH } from './slide/canvas/constans'
import { THUMBNAIL_MODES } from './constants'
import { useVideoFormat } from './useVideoFormat'
import { useSlidesThumbnails } from './useSlidesThumbnails'
import { toggleCanvasVisibility } from './slide/canvas/helpers'

const REQUEST_IDLE_TIMEOUT = 300
const TEMPLATE_THUMBMAIL_MAX_OBJECT = 5

/**
 * The CanvasRenderingContext2D is primarily CPU-related, but the rendering process can benefit from GPU acceleration in modern web browsers
 */
const checkIsGPUIntegrated = () => {
  try {
    const canvas = document.createElement('canvas')
    const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl')
    const debugInfo = gl.getExtension('WEBGL_debug_renderer_info')

    return /intel|legacy|mali|google/i.test(gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL))
  } catch {
    return false
  }
}

const isLowEndDevice =
  (!!navigator.deviceMemory && navigator.deviceMemory < 8) ||
  navigator.hardwareConcurrency < 8 ||
  checkIsGPUIntegrated()

const MAX_SIMULTANEOUSLY_SLIDES = isLowEndDevice ? 2 : 5

const idle = () => new Promise((resolve) => requestIdleCallback(resolve, { timeout: REQUEST_IDLE_TIMEOUT }))

const createAndAttachCanvas = (parent, slideId) => {
  const el = document.createElement('canvas')
  el.id = `canvas-${slideId}`
  el.style.position = 'absolute'
  parent.appendChild(el)
}

const checkForPreviousCanvasInstance = (slide) => {
  const canvas = document.querySelector('#canvas-' + slide.id)
  if (canvas && canvas.parentNode.classList.contains('canvas-container')) {
    const root = canvas.parentNode.parentElement
    root.removeChild(canvas.parentNode)

    createAndAttachCanvas(root, slide.id)
  }
}

const createBaseCanvas = ({ element, zoom }) =>
  new fabric.Canvas(element, {
    height: Math.round(CANVAS_HEIGHT * zoom),
    width: Math.round(CANVAS_WIDTH * zoom),
    preserveObjectStacking: true,
    /**
     * prevent fabric canvas from rerendering whenever an object is added or removed
     */
    renderOnAddRemove: false,
    // avoid cache generation during scaling
    noScaleCache: true,
    // by default it's true, just to make it explicit
    objectCache: true,
    maxCacheSideLimit: 1280,
    minCacheSideLimit: 320,
    perfLimitSizeTotal: 7340032,
  })

const disposeCanvas = (canvas) => {
  if (!canvas || canvas._disposed) return
  canvas.stopAnimations()
  canvas.dispose()
  canvas._disposed = true
}

const checkObjectVisibilityForTemplate = (canvas, obj) => {
  if (!obj.animation?.startTime) return true
  // NOTE: in can be improved to check if overlapping with other objects etc.
  return canvas.getObjects().filter((o) => o.type !== 'avatar' && !o.bg).length <= TEMPLATE_THUMBMAIL_MAX_OBJECT
}

const showControls = (canvas, visibility, thumbnailMode = THUMBNAIL_MODES.REGULAR) => {
  canvas.getObjects().forEach((obj) => {
    obj.forEachControl((control) => (control.visible = visibility))
    obj.borderColor = visibility ? 'rgba(72, 104, 255, 1)' : 'rgba(72, 104, 255, 0)'
    if (visibility && Object.hasOwn(obj, 'visiblePrev')) {
      obj.visible = obj.visiblePrev
      delete obj.visiblePrev
    } else {
      obj.visiblePrev = obj.visible
      // if it's a template mode, we should force it to show only the objects at the beginning of the slide
      // otherwise it will show all objects and it looks messy
      obj.visible =
        visibility || thumbnailMode === THUMBNAIL_MODES.REGULAR ? true : checkObjectVisibilityForTemplate(canvas, obj)
    }
  })
  canvas.renderAllSafe()
}

export const useCanvasRegistry = (
  video,
  videoRef,
  selectedSlideId,
  workingAreaRef,
  canvasesContainerRef,
  isOpenSidebar,
  activeSlide,
  handleCanvasSelected,
  handleMissingMedia,
) => {
  const fontsReady = useStore((stores) => stores.videosStore.fontsReady)
  const canvasRegistry = useRef({})
  const currentCanvas = useRef()
  const missingMedia = useRef([])
  const [ready, setReady] = useState(false)
  const [readyInitialSlide, setReadyInitialSlide] = useState(false)

  const { calculateZoom, resizeFormatLimiters, clearFormatLimiters } = useVideoFormat(
    video,
    canvasRegistry,
    workingAreaRef,
    canvasesContainerRef,
    isOpenSidebar,
  )

  const handleCanvasReady = (missingMedia) => {
    if (missingMedia.length) handleMissingMedia?.(missingMedia, selectedSlideId)
  }

  const {
    applyThumbnailFromCache,
    clearTemplateThumbnails,
    createOptimizedThumbnailAsync,
    deleteThumbnailsCache,
    dropThumbnailCache,
    flushThumbnailsCache,
    clearThumbnails,
    syncThumbnailsWithSlides,
    templateThumbnails,
    templateThumbnailsReady,
    thumbnailCacheEnabled,
    thumbnailsRef,
    thumbnailsReady,
    updatedThumbnails,
    updateThumbnail,
    updateThumbnailImmediately,
  } = useSlidesThumbnails({
    calculateZoom,
    canvasRegistry,
    createBaseCanvas,
    disposeCanvas,
    handleCanvasReady,
    ready,
    showControls,
    video,
    videoRef,
  })

  const createCanvas = (slide, initial = false) => {
    checkForPreviousCanvasInstance(slide)
    const zoom = calculateZoom()
    const canvas = createBaseCanvas({ element: 'canvas-' + slide.id, zoom })
    canvas.setZoom(zoom)
    canvas.wrapperEl.style.display = 'none'
    if (!initial) {
      canvas.loadFromJSON(slide.canvas, (missingMedia) => {
        handleCanvasReady(missingMedia)
        handleCanvasSelected?.()
      })
      canvas.initialized = true
    }
    return canvas
  }

  const createCanvasAsync = async (slide) => {
    const canvas = createCanvas(slide, true)

    if (!slide.canvas) return canvas

    await canvas.loadFromJSON(slide.canvas, handleCanvasReady)
    await updateThumbnail(slide, { canvas, showControlsWithDelay: false, forceCache: !ready })

    return canvas
  }

  const preloadSlideCanvas = async (slide, { immediate = false } = {}) => {
    const isAppliedFromCache = await applyThumbnailFromCache(slide.id, video._id)
    if (isAppliedFromCache) {
      return
    }

    if (!immediate) await idle()

    if (!canvasRegistry.current[slide.id]) {
      // no need to iterate over all objects if we can find one object
      if (slide.id !== selectedSlideId) {
        await createOptimizedThumbnailAsync(slide)
      } else {
        const canvas = await createCanvasAsync(slide)
        canvasRegistry.current[slide.id] = canvas
      }
    } else {
      updateThumbnail(slide, { showControlsWithDelay: false, forceCache: !ready })
    }
  }

  const preloadCanvases = async (slides) => {
    await promiseAllWithLimit(slides, preloadSlideCanvas, MAX_SIMULTANEOUSLY_SLIDES)
    if (!ready) updateVideoVersionInCache(video)
  }

  const updateCanvases = async (slides, { activeObject, thumbnailMode = THUMBNAIL_MODES.REGULAR } = {}) => {
    let activeObjectUpdated = null
    for (const slide of slides) {
      let canvas = canvasRegistry.current[slide.id]
      if (!activeObject && slide.id === selectedSlideId)
        await updateThumbnail(slide, { showControlsWithDelay: true, thumbnailMode })
      else {
        if (!canvas || canvas._disposed) canvas = selectCanvasInternal(slide, true)
        const updated = await canvas
          ?.loadFromJSON(slide.canvas)
          .then(() => updateThumbnail(slide, { thumbnailMode }))
          .then(() => {
            if (activeObject && typeof activeObject !== 'boolean' && slide.id === selectedSlideId) {
              const ao = canvas.getObjects().find((obj) => obj.id === activeObject.id)
              if (ao) {
                canvas.setActiveObject(activeObject).renderAllSafe()
                return ao
              }
            }
            return null
          })
        activeObjectUpdated = activeObjectUpdated || updated
      }
      await idle()
    }
    return activeObjectUpdated
  }

  const updateCanvasesInStoryMode = async (slides) => {
    clearThumbnails()
    await promiseAllWithLimit(slides, updateCanvasInStoryMode, MAX_SIMULTANEOUSLY_SLIDES)
  }

  const updateCanvasInStoryMode = async (slide) => {
    let canvas = canvasRegistry.current[slide.id]
    if (!canvas || canvas._disposed) canvas = selectCanvasInternal(slide, true)
    await canvas?.loadFromJSON(slide.canvas)
    updateThumbnail(slide)
  }

  const toggleCanvasesReadOnly = (readOnly) => {
    Object.values(canvasRegistry.current).forEach((canvas) => {
      canvas.wrapperEl?.classList.toggle('read-only', readOnly)
    })
  }

  useEffect(() => {
    if (video && video.version !== Number(localStorage.getItem('cache_video_version_' + video._id)))
      dropThumbnailCache(video)
  }, [video?._id])

  useEffect(() => {
    const handleError = () => {
      // for ensure that thumbnails cache will be consistent we should clear it if any error occurred
      dropThumbnailCache(video)
    }
    window.addEventListener('error', handleError)
    return () => {
      window.removeEventListener('error', handleError)
      updatedThumbnails.length = 0
    }
  }, [])

  /**
   * For preloading initial opened slide before preloading other slides
   */
  useEffect(() => {
    if (!fontsReady || !video?.slides.length || readyInitialSlide) return
    //only for story mode, because there isn't active slide in story mode
    if (activeSlide === undefined) return setReadyInitialSlide(true)
    resizeFormatLimiters()
    deleteThumbnailsCache()

    const preload = async () => {
      await preloadSlideCanvas(video.slides[activeSlide], { immediate: true })
      setReadyInitialSlide(video.slides[activeSlide].id)
    }
    preload()
  }, [video?.slides.length, fontsReady, readyInitialSlide, activeSlide])

  /**
   * For syncing canvas registry
   */
  useEffect(() => {
    if (!fontsReady || !video?.slides.length || !readyInitialSlide) return
    preloadCanvases(video.slides).then(() => setReady(true))
  }, [video?.slides.length, fontsReady, readyInitialSlide])

  useEffect(() => {
    if (!video?.slides || !handleMissingMedia) return

    syncThumbnailsWithSlides()
  }, [video?.slides])

  const deselectCanvas = (canvas) => {
    if (!canvas) return

    toggleCanvasVisibility(canvas, false)
    canvas.discardActiveObject()

    canvas.stopAnimations()
  }

  const hideCanvases = () =>
    Object.values(canvasRegistry.current).forEach((canvas) => toggleCanvasVisibility(canvas, false))

  const selectCanvasInternal = (slide, initial = false) => {
    let canvas = canvasRegistry.current[slide.id]
    if (canvas) {
      // check that it presents in DOM
      const el = document.querySelector('#canvas-' + slide.id)
      if (el && !el.classList.contains('lower-canvas')) {
        disposeCanvas(canvas)
        canvas = createCanvas(slide, initial)
      } else if (canvas._disposed) {
        canvas = createCanvas(slide, initial)
      }
      // re-check for missing media after returning from another slide
      const missingMedia = canvas.getObjects().filter((obj) => !checkObjectExists(obj))
      if (missingMedia.length) handleMissingMedia?.(missingMedia, selectedSlideId)
    } else {
      canvas = createCanvas(slide, initial)
      const shouldUpdateThumbnail = !thumbnailCacheEnabled || slide.newlyAdded || !slide.status
      if (!initial && shouldUpdateThumbnail) {
        setTimeout(() => updateThumbnail(slide), 500)
      }
    }
    canvasRegistry.current[slide.id] = canvas
    canvas.slideId = slide.id
    return canvas
  }

  const selectCanvas = (slide) => {
    if (currentCanvas.current?.slideId === slide.id) return false
    if (currentCanvas.current) deselectCanvas(currentCanvas.current)
    return (currentCanvas.current = selectCanvasInternal(slide))
  }

  const deleteCanvas = (slide) => {
    const canvas = canvasRegistry.current[slide.id]
    if (canvas) disposeCanvas(canvas)
    delete canvasRegistry.current[slide.id]
    setTimeout(syncThumbnailsWithSlides, 1500)
  }

  const clearRegistry = () => {
    Object.values(canvasRegistry.current).forEach(disposeCanvas)
    canvasRegistry.current = {}
    missingMedia.current = []
    clearThumbnails()
    fabric.dropCache()
    clearFormatLimiters()
  }

  return {
    ready,
    thumbnailsRef,
    thumbnailsReady,
    templateThumbnails,
    templateThumbnailsReady,
    readyInitialSlide,
    selectCanvas,
    deleteCanvas,
    toggleCanvasesReadOnly,
    updateCanvases,
    updateCanvasesInStoryMode,
    updateCanvasInStoryMode,
    updateThumbnail,
    updateThumbnailImmediately,
    flushThumbnailsCache,
    dropThumbnailCache,
    clearRegistry,
    hideCanvases,
    clearTemplateThumbnails,
  }
}
