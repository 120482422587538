import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'antd'
import Icon from '../../../../components/Icon'
import interactivity_popup_background from '../../../../assets/images/interactivity-popup-background.jpg'
import coming_soon_in_panopto from '../../../../assets/images/coming-soon-in-panopto.png'
import './interactivityModal.less'
import { useStore } from '../../../../store'

const deprecatedQuestions = (video) =>
  video.slides.some((slide) => {
    return slide.canvas.objects.some((obj) => {
      if (obj.type !== 'question') return false
      const answersGroup = obj.objects.find((obj) => obj.question.element === 'answers_group')
      const firstAnswer = answersGroup.objects[0]
      const checkbox = firstAnswer.objects.find((obj) => obj.question.element === 'answer_checkbox')
      return checkbox.question.type !== 'radio'
    })
  })

const InteractivityModal = ({ video }) => {
  const [isOpenModal, setIsOpenModal] = useState(false)

  const handleCloseModal = () => {
    localStorage.setItem('interactivityPopupShown', 'true')
    setIsOpenModal(false)
  }

  useEffect(() => {
    const interactivityPopupShown = localStorage.getItem('interactivityPopupShown')
    if (interactivityPopupShown) return
    if (deprecatedQuestions(video)) setIsOpenModal(true)
  }, [])

  return (
    <Modal centered open={isOpenModal} width={592} className="interactivity-modal" footer={null} closable={false}>
      <img src={interactivity_popup_background} width="169" alt="Interactive elements" />
      <div className="interactivity-modal-content">
        <h3>
          Interactive “Questions” are no longer allowed with other interactive elements and 2 correct answers for the
          same question are not allowed.
        </h3>
        <p>
          This change was made to provide video watchers with a better experience and keep them focused on answering a
          quiz. The interactive elements that were previously added to a slide with a quiz have now been removed.
        </p>
        <p>
          Your current questions have not changed, so if you want to switch to a new logic, change your current question
          type. All new questions will use single-correct answer logic only.
        </p>
        <Button type="primary" className="btn-ok" onClick={handleCloseModal}>
          Ok
        </Button>
      </div>
    </Modal>
  )
}

const ComingSoonPanel = () => {
  const { user } = useStore((stores) => stores.authStore)
  const account = user?.account
  const [isVisible, setIsVisible] = useState(false)

  const handleClose = () => {
    localStorage.setItem('comingSoonModalShown', 'true')
    setIsVisible(false)
  }

  useEffect(() => {
    const modalShown = localStorage.getItem('comingSoonModalShown')
    if (!modalShown && account?.source?.provider === 'panopto') {
      setIsVisible(true)
    }
  }, [account])

  if (!isVisible) return null

  return (
    <div className="coming-soon-panel">
      <div className="coming-soon-panel-main">
        <div className="coming-soon-panel-close" onClick={handleClose}>
          <Icon name="cancel" style={{ fontSize: 10 }} />
        </div>
        <div className="coming-soon-panel-content">
          <div className="coming-soon-panel-header">
            <Icon name="panopto_logo" style={{ fontSize: 22, marginTop: -12, marginRight: 8 }} />
            <h3 className="coming-soon-panel-h3">Coming soon in Panopto</h3>
          </div>
          <div className="coming-soon-panel-image">
            <img src={coming_soon_in_panopto} alt="Coming soon features" />
          </div>
          <p className="coming-soon-panel-p">
            Interactive elements, chapter navigation, and branching will be supported in Panopto as part of a future
            release.
          </p>
          <div className="coming-soon-panel-footer">
            <Button type="primary" onClick={handleClose} className="ok-button">
              OK
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export { InteractivityModal, ComingSoonPanel }
