import { useRef } from 'react'

/**
 * Custom hook that returns a stable reference to a given value.
 * The reference is updated whenever the value changes.
 *
 * @param {*} value - The value to be referenced.
 * @returns {Object} - A React ref object with the current value.
 */
export const useStableRef = (value) => {
  const stableRef = useRef(value)
  stableRef.current = value

  return stableRef
}
