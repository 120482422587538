import { useState, useEffect, useRef } from 'react'
import { Popover, Typography, Divider, Switch, Badge, Segmented, Button, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import Icon from '../../components/Icon'
import { upgradeAccount } from '../../utils/payment'
import { formatSeconds } from '../../utils/videos'
import { isPhonemeValidationError, PhonemeValidationError } from '../../components/PhonemeValidationError'
import './renderPopover.less'

const subtitlesInfo = [
  {
    id: 'vtt',
    title:
      'Subtitles will be added to your video in HTML player. You can also download vtt file and use it on any platform. (Recommended)',
  },
  {
    id: 'srt',
    title: 'Download subtitles in SRT format. Use only if you need specifically SRT.',
  },
  {
    id: 'burn',
    title: 'Subtitles will be added to your mp4 file. They will be always visible on any platform.',
  },
]

export const RenderPopover = ({ video, duration, user, renderVideo, updateVideo, cancelRender }) => {
  const [isOpenRenderPopover, setIsOpenRenderPopover] = useState(false)
  const [subtitlesHelpTitle, setSubtitlesHelpTitle] = useState(subtitlesInfo[0].title)
  const popoverContentRef = useRef(null)
  const renderButtonRef = useRef(null)

  const { account } = user
  const isBasicPlan = account.plan === 'basic'
  const secondsAvailable = account.minutesAvailable * 60
  const secondsUsed = account.minutesUsed * 60
  const secondsLeft = secondsAvailable - secondsUsed
  const isVideoStatusError = video.status === 'error'

  const toggleSubtitles = (subtitlesEnabled) => {
    if (subtitlesEnabled === true) subtitlesEnabled = 'vtt'
    updateVideo({ data: { ...video.data, subtitlesEnabled } })
    setSubtitlesHelpTitle(subtitlesInfo.find((entry) => entry.id === subtitlesEnabled)?.title)
  }

  const toggleNavigation = (navigationEnabled) => {
    updateVideo({ data: { ...video.data, interactivity: { ...video.data.interactivity, locked: navigationEnabled } } })
  }

  const toggleResolution = (resolution) => {
    updateVideo({ data: { ...video.data, resolution } })
  }

  const handleClickOutside = (event) => {
    if (
      popoverContentRef.current &&
      !popoverContentRef.current.contains(event.target) &&
      !renderButtonRef.current.contains(event.target)
    ) {
      setIsOpenRenderPopover(false)
    }
  }

  useEffect(() => {
    if (video.data?.subtitlesEnabled === true) {
      video.data.subtitlesEnabled = 'vtt'
      toggleSubtitles('vtt')
    }
    setSubtitlesHelpTitle(subtitlesInfo.find((entry) => entry.id === video.data?.subtitlesEnabled)?.title)
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [popoverContentRef])

  return (
    <Popover
      content={
        <div ref={popoverContentRef} className="container">
          {isVideoStatusError && (
            <div className="alert-message">
              <Icon name="warning" />
              {isPhonemeValidationError(video.data?.appError) ? (
                <PhonemeValidationError text={video.data.appError} />
              ) : (
                <p>{video.data?.appError || 'Internal issue. Please contact us to resolve.'}</p>
              )}
            </div>
          )}
          {account.status === 'trial' && video.slides.length > 3 && (
            <div className="alert-message">
              <InfoCircleOutlined />
              <p>
                Trial users can render only 3 slides per video. Please{' '}
                <a
                  onClick={async () => {
                    await upgradeAccount(account)
                    setIsOpenRenderPopover(false)
                  }}
                >
                  upgrade
                </a>{' '}
                if you need more than 3 slides. Otherwise just start render.
              </p>
            </div>
          )}
          {account.status === 'paid' && account.lifetimeMinutesUsed < 5 && (
            <div className="alert-message">
              <InfoCircleOutlined />
              <p>Minutes are deducted each time you click "Render"</p>
            </div>
          )}
          <Typography.Text type="secondary">Minutes</Typography.Text>
          <div className="option-wrapper">
            <p>Approximate video duration:</p>
            <span>{formatSeconds(duration) || '0s'}</span>
          </div>
          <div className="option-wrapper">
            <p>Minutes available:</p>
            <span>
              {account.plan === 'unlimited' || account.allowOverlimit ? '♾️' : formatSeconds(secondsLeft) || '0'}
            </span>
          </div>
          <Divider />
          <Typography.Text type="secondary">Navigation</Typography.Text>
          <div className="option-wrapper">
            <div className="title-info-wrapper">
              <span>Disable slide navigation</span>
              <Tooltip
                title="User will be able to navigate only using interactive objects, no ability to scroll or skip sections. This is usually used together with interactivity."
                placement="topRight"
              >
                <Icon name="info" />
              </Tooltip>
            </div>
            <Switch size="small" checked={video.data?.interactivity?.locked} onChange={toggleNavigation} />
          </div>
          <Divider />
          <Typography.Text type="secondary">Subtitles</Typography.Text>
          <div className="option-wrapper">
            <div className="title-info-wrapper">
              <span>Turn on subtitles</span>
              <Tooltip title="Auto subtitles / closed captions. Will be visible after the render." placement="topRight">
                <Icon name="info" />
              </Tooltip>
            </div>
            <Switch size="small" checked={video.data?.subtitlesEnabled} onChange={toggleSubtitles} />
          </div>
          {video.data?.subtitlesEnabled && (
            <>
              <Segmented
                defaultValue="vtt"
                id="segmented-subtitles-helper"
                value={video.data?.subtitlesEnabled === true ? 'vtt' : video.data?.subtitlesEnabled}
                options={[
                  { label: 'WebVTT', value: 'vtt' },
                  { label: 'SRT', value: 'srt' },
                  { label: 'Burn into video', value: 'burn' },
                ]}
                onChange={toggleSubtitles}
              />
              <Typography.Text type="secondary">{subtitlesHelpTitle}</Typography.Text>
            </>
          )}
          <Divider />
          <Typography.Text type="secondary">Resolution</Typography.Text>
          <Tooltip
            title="Upgrade to Advanced or Enterprise plan to render in 4K."
            overlayStyle={{
              visibility: isBasicPlan ? 'visible' : 'hidden',
            }}
          >
            <Badge count="Upgrade" style={{ display: isBasicPlan ? 'flex' : 'none' }}>
              <Segmented
                value={video.data?.resolution ?? 'FullHD'}
                options={[
                  { label: '1080p', value: 'FullHD' },
                  { label: '4K', value: '4K', disabled: isBasicPlan },
                ]}
                onChange={toggleResolution}
              />
            </Badge>
          </Tooltip>
          <Button type="primary" onClick={renderVideo}>
            {video.status === 'rendering' ? 'Confirm update' : 'Start render'}
          </Button>
          {user.isAdmin && (
            <Button danger type="primary" onClick={cancelRender} style={{ marginTop: 20 }}>
              Cancel render
            </Button>
          )}
        </div>
      }
      open={isOpenRenderPopover}
      arrow={false}
      placement="bottomRight"
      overlayClassName="render-popover"
      getPopupContainer={(trigger) => trigger.parentElement}
    >
      <Tooltip
        key="render"
        title={
          user.status !== 'verified'
            ? 'Please verify your email to render videos'
            : "Your role doesn't allow rendering videos"
        }
        placement="topRight"
        overlayStyle={{
          visibility: user.status !== 'verified' || user.accountRole === 'reviewer' ? 'visible' : 'hidden',
          minWidth: 280,
        }}
      >
        <Button
          ref={renderButtonRef}
          type="primary"
          icon={isVideoStatusError ? <Icon name="warning" /> : <Icon name="volume" />}
          disabled={user.status !== 'verified' || user.accountRole === 'reviewer'}
          loading={video.status === 'validating'}
          className="btn-render"
          onClick={() => setIsOpenRenderPopover(!isOpenRenderPopover)}
        >
          {video.status === 'rendering' ? 'Update' : isVideoStatusError ? 'Retry' : 'Render'}
          <Icon name="down_arrow" style={{ marginLeft: -4 }} />
        </Button>
      </Tooltip>
    </Popover>
  )
}

export default RenderPopover
