import { useState, useEffect, useRef, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from 'antd'
import { useStore } from '../../store'
import { request } from '../../utils/api'
import { useUpdateSave } from '../video/useUpdateSave'
import { useCanvasRegistry } from '../video/useCanvasRegistry'
import { useGetSlidesThumbnails } from '../video/useSlidesThumbnails'
import { useSlideDuration } from '../../hooks/useSlideDuration'
import { useLayoutsData } from './useLayoutsData'
import { maxVideoNameLength } from '../../utils/constants'
import { inputFieldsPlaceholders, MAX_TOKENS_FOR_TRIAL, EDITOR_SYNC_TIMEOUT_MS, MAX_LIST_ITEMS } from './constants'
import { useElaiNotification } from '../../hooks/useElaiNotification'
import './videoStory.less'
import { isEmptyString } from '../../utils/helpers'
import {
  formatInnerHtmlToText,
  storyInternalFields,
  waitForElementInDOM,
  isEmptySlideStory,
} from '../../utils/videoStory/helpers'

export const useVideoStory = () => {
  const { videoId } = useParams()
  const navigate = useNavigate()
  const authStore = useStore((stores) => stores.authStore)
  const [video, setVideo] = useState()
  const videoRef = useRef() // for timeout/interval
  const [isOpenOutlineModal, setIsOpenOutlineModal] = useState(false)
  const [editingSlide, setEditingSlide] = useState()
  const [openImageIndex, setOpenImageIndex] = useState(false)
  const [isOpenTemplatesModal, setIsOpenTemplatesModal] = useState(false)
  const [customPromptModal, setCustomPromptModal] = useState(false)
  const [gptInputValue, setGptInputValue] = useState()
  const [isVideoGenerating, setIsVideoGenerating] = useState(false)
  const [videoErrors, setVideoErrors] = useState(false)
  const [isSwitching, setIsSwitching] = useState(false)
  const [isOpenErrorsModal, setIsOpenErrorsModal] = useState(false)
  const [gptLoading, setGptLoading] = useState({ key: '', message: '', isLoading: false })
  const [undoRequest, setUndoRequest] = useState(false)
  const [isParsingHtml, setIsParsingHtml] = useState(false)
  const [isEditorBusy, setIsEditorBusy] = useState(false)
  const activeTarget = useRef(null)
  const outlineList = useRef(null)
  const textEditorScrollbarRef = useRef(null)
  const notification = useElaiNotification()

  const canvasRegistry = useCanvasRegistry(video, videoRef)
  const thumbnails = useGetSlidesThumbnails({ thumbnailsRef: canvasRegistry.thumbnailsRef })
  const { updateCanvasesInStoryMode, updateCanvasInStoryMode, dropThumbnailCache } = canvasRegistry
  const layoutsData = useLayoutsData({ video, thumbnails })

  const { changesHistory, undoLastChanges, updateVideo, saveVideo, videoSavingStatus, ensureVideoSaved } =
    useUpdateSave({
      video,
      setVideo,
      videoRef,
      canvasRegistry,
      user: authStore.user,
      checkAdminLoginedAsUser: authStore.checkAdminLoginedAsUser,
      isStoryCreation: true,
      checkSlideInEditingMode: () => !isVideoGenerating,
    })

  const { getApproxDuration } = useSlideDuration({})

  const checkEmptySlide = useCallback((slide) => {
    return (
      (!slide.story ||
        Object.keys(slide.story)
          .filter((field) => !storyInternalFields.includes(field))
          .every(
            (field) =>
              !slide.story[field] ||
              (field === 'images' && slide.story.images.every((item) => !item)) ||
              (field === 'list' && slide.story.list.every((item) => !item)),
          )) &&
      !slide.speech
    )
  }, [])

  const isBlankVideo = video?.slides.every((slide) => checkEmptySlide(slide))

  useEffect(() => {
    setIsEditorBusy(gptLoading.isLoading || isParsingHtml)
  }, [gptLoading.isLoading, isParsingHtml])

  useEffect(() => {
    const loadVideo = async () => {
      const v = await request({ method: 'get', url: `videos/${videoId}?createMissingStories=true` })
      if (!v) return
      v.data = v.data ?? {}
      updateVideo(v, { replaceState: true, initialRequest: true })

      const gptValue = sessionStorage.getItem('gptValue')
      if (gptValue) {
        setGptInputValue(gptValue)
        await generateOutline(gptValue)
        sessionStorage.removeItem('gptValue')
      }
    }
    loadVideo()
  }, [])

  /**
   * Sync video with related ref for timeout/interval
   * https://felixgerschau.com/react-hooks-settimeout/#using-state-in-settimeout
   */
  useEffect(() => {
    videoRef.current = video
  }, [video])

  /**
   * Workaround to ensure that undo will be processed in React context
   */
  useEffect(() => {
    if (undoRequest) {
      setUndoRequest(false)
      undoLastChanges()
    }
  }, [undoRequest])

  /**
   * UNDO
   * Update onkeydown for each slide
   */
  useEffect(() => {
    const undoChangesOnKey = (e) => {
      const tagName = e.target.tagName?.toLowerCase()
      if (
        (e.metaKey || e.ctrlKey) &&
        e.code === 'KeyZ' &&
        tagName !== 'textarea' &&
        (tagName !== 'input' || e.target.type === 'radio') &&
        e.target.dataset.name !== 'list-outline'
      ) {
        const target = activeTarget.current

        setUndoRequest(true)

        if (target)
          setTimeout(() => {
            const selection = window.getSelection()
            const field = queryActualEditorField(target)
            if (!field) return
            selection.selectAllChildren(field)
            selection.collapseToEnd()
          }, EDITOR_SYNC_TIMEOUT_MS)

        // try to fix issue with open last closed page for Safari
        e.preventDefault()
        e.stopPropagation()
        return false
      }
    }
    document.addEventListener('keydown', undoChangesOnKey)
    return () => document.removeEventListener('keydown', undoChangesOnKey)
  }, [video?._id, changesHistory.length])

  useEffect(() => {
    document.addEventListener('paste', handlePaste)
    return () => {
      document.removeEventListener('paste', handlePaste)
    }
  }, [video, isEditorBusy])

  // useEffect(() => {
  //   document.addEventListener('keydown', handleKeyDown)
  //   return () => document.removeEventListener('keydown', handleKeyDown)
  // }, [video, activeTarget])

  useEffect(() => {
    document.addEventListener('selectionchange', handleSelectionChanged)
    return () => document.removeEventListener('selectionchange', handleSelectionChanged)
  }, [activeTarget, isBlankVideo])

  const callEnhancement = async (requestPath, requestData) => {
    const { account } = authStore.user
    const isTrial = account.status === 'trial'
    if (isTrial && (account.gptUsageCounters?.tokens || 0) > MAX_TOKENS_FOR_TRIAL) {
      notification.error({
        message: `You have reached the limit of ${MAX_TOKENS_FOR_TRIAL} tokens for free plan. Please upgrade your plan to continue.`,
      })
      return false
    }
    const data = await request({
      method: 'post',
      url: 'gpt/' + requestPath,
      data: requestData,
    })
    if (data && isTrial) {
      const usedTokens = await authStore.syncGptUsageCounters()
      if (usedTokens > MAX_TOKENS_FOR_TRIAL * 0.7)
        notification.warning({
          key: 'used-tokens',
          message: 'GPT is limited for trial accounts',
          description: `You have used ${usedTokens} GPT tokens from ${MAX_TOKENS_FOR_TRIAL}. Please upgrade to paid plan to use GPT without limitations.`,
          duration: 12,
        })
    }
    return data
  }

  /**
   * Apply gpt enhancement for whole video
   * @param {string} enhancement
   * @param {Object} [config]
   * @param {number} [config.count] - target count of slides (optional)
   * @param {string[]} [config.list] - outline list (optional)
   */
  const applyVideoEnhancement = async (enhancement, config) => {
    await ensureVideoSaved()

    const enhancedVideo = await callEnhancement(video._id, { enhancement, config })
    if (enhancedVideo) updateVideo(enhancedVideo, { skipAutoSave: true })
    return enhancedVideo
  }

  const applySlideEnhancement = async (slideId, enhancement, config) => {
    await ensureVideoSaved()

    const enhancedVideo = await callEnhancement(video._id + '/' + slideId, { enhancement, config })
    if (enhancedVideo) updateVideo(enhancedVideo, { skipAutoSave: true })
    return enhancedVideo
  }

  const queryActualEditorField = (target) =>
    document.querySelector(`[data-slide="${target.dataset.slide}"][data-name="${target.dataset.name}"]`)

  const markSlideStatusAsStory = useCallback(
    (slideIndex) => {
      video.slides[slideIndex] = { ...video.slides[slideIndex], status: 'story' }
    },
    [video],
  )

  const handleSelectionChanged = () => {
    changeActiveTarget()
    resetPlaceholdersState()
  }

  const resetPlaceholdersState = () => {
    if (activeTarget.current) {
      const slideNode = activeTarget.current.closest('.slide-item')
      if (slideNode && slideNode.classList.contains('error')) {
        const slide = video.slides.find((slide) => slide.id === +activeTarget.current.dataset.slide)
        const slideIndex = video.slides.indexOf(slide)
        setVideoErrors((errors) => {
          errors.forEach((value, key) => {
            const field = value.filter((index) => index !== slideIndex)
            errors.set(key, field)
          })
          return errors
        })
      }
    }
  }

  const changeActiveTarget = () => {
    const range = document.getSelection()
    if (!range?.anchorNode) {
      activeTarget.current = null
      return
    }
    const anchorNode = range.anchorNode.nodeName !== '#text' ? range.anchorNode : range.anchorNode.parentElement
    activeTarget.current = anchorNode.closest(['[data-slide]'])
  }

  const getCaretOffset = (element) => {
    let caretOffset = 0
    const document = element.ownerDocument || element.document
    const window = document.defaultView || document.parentWindow
    let selection
    if (typeof window.getSelection !== 'undefined') {
      selection = window.getSelection()
      if (selection.rangeCount > 0) {
        const range = window.getSelection().getRangeAt(0)
        const preCaretRange = range.cloneRange()
        preCaretRange.selectNodeContents(element)
        preCaretRange.setEnd(range.endContainer, range.endOffset)
        caretOffset = preCaretRange.toString().length
      }
    } else if ((selection = document.selection) && selection.type !== 'Control') {
      const textRange = selection.createRange()
      const preCaretTextRange = document.body.createTextRange()
      preCaretTextRange.moveToElementText(element)
      preCaretTextRange.setEndPoint('EndToEnd', textRange)
      caretOffset = preCaretTextRange.text.length
    }
    return caretOffset
  }

  const getListSelectionNode = (selectionNode) => {
    let selectionElement
    if (selectionNode.tagName === 'LI') {
      selectionElement = selectionNode
    } else if (selectionNode.tagName === 'UL') {
      selectionElement = selectionNode.childNodes[0]
    } else {
      selectionElement = selectionNode.parentElement.closest('li')
    }
    return selectionElement
  }

  // const getSelectedSlides = (range) => {
  //   return video.slides
  //     .map((slide) => {
  //       const selectedFields = { slideId: slide.id, fields: [], selected: false }
  //       const slideSelectableFields = []

  //       selectableFields.forEach((field) => {
  //         if (typeof slide.story[field] === 'string' || field === 'speech') {
  //           const slideFieldNode = document.querySelector(`[data-slide="${slide.id}"][data-name="${field}"]`)
  //           if (range.containsNode(slideFieldNode, true)) {
  //             selectedFields.fields.push(field)
  //           }
  //           slideSelectableFields.push(field)
  //         }
  //       })

  //       if (selectedFields.fields.length === slideSelectableFields.length) selectedFields.selected = true

  //       return selectedFields
  //     })
  //     .filter((slide) => slide.fields.length)
  // }

  // const deleteSelectedSlides = (range) => {
  //   const selectedSlides = getSelectedSlides(range)
  //   const newExtraLayouts = []
  //   const slides = video.slides
  //     .map((slide, idx) => {
  //       const selectedSlide = selectedSlides.find((s) => s.slideId === slide.id)
  //       if (!selectedSlide) return slide
  //       if (selectedSlide.selected) {
  //         // fully removing
  //         const layoutCandidate = makeLayoutFromSlide(slide)
  //         if (!video.extraLayouts.some((l) => ifLayoutsSimilar(l, layoutCandidate)))
  //           newExtraLayouts.push(layoutCandidate)
  //         return false
  //       } else {
  //         // remove only fields but keep the slide
  //         selectedSlide.fields.forEach((field) => {
  //           slide.story[field] = field === 'list' ? [''] : ''
  //           if (field !== 'speech') markSlideStatusAsStory(idx)
  //         })
  //         return slide
  //       }
  //     })
  //     .filter((slide) => slide)

  //   const slidesToSave = slides.length ? slides : [{ ...video.slides[0], speech: '', story: {} }]
  //   updateVideo({
  //     slides: slidesToSave,
  //     extraLayouts: [...video.extraLayouts, ...newExtraLayouts],
  //   })
  // }

  // const handleKeyDown = (e) => {
  //   const selection = window.getSelection()
  //   if (!activeTarget.current) return

  //   if (e.key === 'Backspace' || e.key === 'Delete') {
  //     // if (!selection.anchorNode || !selection.extentNode) return e.preventDefault()
  //     // const anchorNode =
  //     //   selection.anchorNode.nodeName !== '#text' ? selection.anchorNode : selection.anchorNode.parentElement
  //     // const extentNode =
  //     //   selection.extentNode.nodeName !== '#text' ? selection.extentNode : selection.extentNode.parentElement
  //     // const queryCondition = `[data-name="list"][data-slide="${activeTarget.current.dataset.slide}"]`
  //     // console.log(selection.type, anchorNode, extentNode, activeTarget.current.dataset.name)
  //     // if (
  //     //   selection.type === 'Range' &&
  //     //   anchorNode !== extentNode &&
  //     //   !(
  //     //     activeTarget.current.dataset.name === 'list' &&
  //     //     anchorNode.closest(queryCondition) &&
  //     //     extentNode.closest(queryCondition)
  //     //   )
  //     // ) {
  //     //   e.preventDefault()
  //     //   deleteSelectedSlides(selection)
  //     //   window.getSelection().removeAllRanges()
  //     // } else {
  //     //   const caretOffset = getCaretOffset(activeTarget.current)
  //     //   if (caretOffset === 0) e.preventDefault()
  //     // }
  //   } else if (e.code === 'KeyA' && (e.metaKey || e.ctrlKey)) {
  //     //selectAllTextInField(e, selection)
  //   } else if (selection.type === 'Range' && selection.anchorNode.parentNode !== selection.extentNode.parentNode) {
  //     const selectedSlides = getSelectedSlides(selection)
  //     if (selectedSlides.length > 1) e.preventDefault()
  //   }
  // }

  // const selectAllTextInField = (e, selection) => {
  //   e.preventDefault()
  //   const range = document.createRange()
  //   range.selectNodeContents(activeTarget.current)
  //   selection.removeAllRanges()
  //   selection.addRange(range)
  // }

  const getNextEditableSibling = (node, selection) => {
    const anchorParent =
      selection?.anchorNode?.tagName === 'LI' ? selection.anchorNode : selection?.anchorNode?.parentElement
    if (anchorParent?.tagName === 'LI' && anchorParent.nextSibling) return anchorParent.nextSibling

    let parent = node.closest('div[contenteditable="false"]')
    if (parent.nextSibling) {
      return parent.nextSibling.querySelector('[contenteditable="true"]')
    }
    if (node.parentNode) {
      const closest = node.closest('.slide-item')?.parentNode
      if (closest?.nextSibling) return closest.nextSibling.querySelector('[contenteditable="true"]')
    }
    return node
  }

  const setCaretOnNextSibling = (e, selection) => {
    e.preventDefault()

    const sibling = getNextEditableSibling(activeTarget.current, selection)
    if (!sibling) return

    const editableElement = sibling.dataset.name === 'list' ? sibling.childNodes[0] : sibling

    selection.selectAllChildren(editableElement)
    selection.collapseToEnd()

    editableElement.click()
  }

  const handleKeyDownContainerContent = (e) => {
    const selection = window.getSelection()
    if (!activeTarget.current) return

    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()

      if (activeTarget.current.dataset.name === 'list') {
        if (activeTarget.current.querySelectorAll('li').length >= MAX_LIST_ITEMS) return

        const activeElement =
          selection.anchorNode.nodeName !== '#text' ? selection.anchorNode : selection.anchorNode.parentElement
        const activeLi = activeElement.tagName === 'UL' ? activeElement.childNodes[0] : activeElement

        const caretOffset = getCaretOffset(activeLi)
        let textToBreak = activeLi.innerText.slice(caretOffset)
        if (textToBreak !== inputFieldsPlaceholders.list) activeLi.innerText = activeLi.innerText.slice(0, caretOffset)
        else textToBreak = ''

        const li = document.createElement('li')
        activeLi.after(li)
        const liIdx = Array.prototype.indexOf.call(activeLi.parentNode.children, li)
        const liId = `list-item-${Number(activeTarget.current.dataset.slide)}-${liIdx}`
        li.id = liId
        li.innerText = textToBreak

        setTimeout(() => {
          waitForElementInDOM('#' + liId).then((li) => {
            if (!li) return

            const range = document.createRange()
            if (textToBreak) li.innerText = textToBreak
            range.setStart(li, 0)
            range.collapse(true)
            selection.removeAllRanges()
            selection.addRange(range)
          })

          const ulChildNodes = [...activeTarget.current.children]
          ulChildNodes.forEach((child) => {
            if (!child.innerText) child.innerHTML = `<span class="placeholder">${inputFieldsPlaceholders.list}</span>`
          })
        }, EDITOR_SYNC_TIMEOUT_MS)
      } else if (selection.anchorNode.parentNode === selection.extentNode.parentNode) {
        if (activeTarget.current.dataset.name === 'header' || activeTarget.current.dataset.name === 'subHeader')
          setCaretOnNextSibling(e, selection)
        else {
          if (activeTarget.current.innerText === inputFieldsPlaceholders[activeTarget.current.dataset.name])
            activeTarget.current.innerText = ''
          document.execCommand('insertLineBreak')
        }
      }
    } else if (e.key === 'Delete') {
      const caretOffset = getCaretOffset(activeTarget.current)
      if (selection.type === 'Caret' && caretOffset === activeTarget.current.innerText.length) e.preventDefault()
    } else if (e.key === 'Tab') {
      setCaretOnNextSibling(e, selection)
    }

    if (activeTarget.current.dataset.name === 'list') {
      if (!selection.anchorNode) return
      const activeLi = getListSelectionNode(selection.anchorNode)
      const extentNode = getListSelectionNode(selection.extentNode)

      const queryCondition = `[data-name="list"][data-slide="${activeTarget.current.dataset.slide}"]`
      if (!activeLi?.closest(queryCondition) || !extentNode?.closest(queryCondition)) return

      if (e.key === 'Backspace' || e.key === 'Delete') {
        const liIdx = Array.from(activeTarget.current.children).indexOf(activeLi)
        const previuosLi = Array.from(activeTarget.current.children)[liIdx - 1]
        if (selection.type === 'Caret') {
          if (liIdx !== 0 || (liIdx === 0 && activeLi.innerText.length === 1)) {
            if (activeLi.innerText.length === 1) {
              e.preventDefault()
              activeLi.innerHTML = `<span class="placeholder">${inputFieldsPlaceholders.list}</span>`
              const range = document.createRange()
              range.setStart(activeLi, 0)
              range.collapse(true)
              selection.removeAllRanges()
              selection.addRange(range)
            } else if (activeLi.innerText === inputFieldsPlaceholders.list) {
              e.preventDefault()
              activeLi.remove()

              if (previuosLi.innerText === inputFieldsPlaceholders.list) {
                const range = document.createRange()
                range.setStart(previuosLi, 0)
                range.collapse(true)
                selection.removeAllRanges()
                selection.addRange(range)
              } else {
                selection.selectAllChildren(previuosLi)
                selection.collapseToEnd()
              }
            } else if (
              previuosLi.innerText === inputFieldsPlaceholders.list &&
              activeLi.innerText !== inputFieldsPlaceholders.list
            ) {
              const caretOffset = getCaretOffset(activeLi)
              if (caretOffset === 0) {
                e.preventDefault()
                previuosLi.remove()
              }
            }
          }
          if (e.key === 'Delete' && liIdx === activeTarget.current.children.length - 1) {
            const caretOffset = getCaretOffset(activeLi)
            if (caretOffset === activeLi.innerText.length) e.preventDefault()
          } else if (activeLi.innerText.length === 1 || activeLi.innerText === inputFieldsPlaceholders.list) {
            const id = activeLi.id
            setTimeout(() => {
              const target = document.querySelector(`#${id}`)
              if (!target) return
              const range = document.createRange()
              range.setStart(target, 0)
              range.collapse(true)
              selection.removeAllRanges()
              selection.addRange(range)
            }, EDITOR_SYNC_TIMEOUT_MS)
          }
        } else if (selection.type === 'Range') {
          setTimeout(() => {
            const activeLiNode = document.querySelector(
              `#list-item-${Number(activeTarget.current.dataset.slide)}-${liIdx}`,
            )
            if (!activeLiNode) return
            if (activeLiNode.innerText === '\n')
              activeLiNode.innerHTML = `<span class="placeholder">${inputFieldsPlaceholders.list}</span>`

            const innerNode = activeLiNode.firstChild

            const range = document.createRange()

            if (innerNode?.nodeName === '#text') {
              const caretOffset = getCaretOffset(activeLiNode)
              range.setStart(innerNode, caretOffset)
            } else {
              range.setStart(activeLiNode, 0)
            }

            range.collapse(true)
            selection.removeAllRanges()
            selection.addRange(range)
          }, EDITOR_SYNC_TIMEOUT_MS)
        }
      }

      if (activeLi.innerText === inputFieldsPlaceholders.list) {
        if (e.key === 'Backspace' || e.key === 'Delete') {
          e.preventDefault()
        } else activeLi.innerText = ''
      }
    } else if (activeTarget.current.innerText === inputFieldsPlaceholders[activeTarget.current.dataset.name]) {
      if (e.key === 'Backspace' || e.key === 'Delete') e.preventDefault()
      else activeTarget.current.innerText = ''
    }
  }

  const saveTextEditorChanges = async (target) => {
    target = target || activeTarget.current
    if (!target) return

    const video = videoRef.current
    const editingSlide = video.slides.find((slide) => slide.id === Number(target.dataset.slide))
    if (!editingSlide) return

    const editingSlideIndex = video.slides.indexOf(editingSlide)
    const inputText = !target.innerHTML.startsWith('<span class="placeholder">')
      ? formatInnerHtmlToText(target.innerText)
      : ''

    if (target.dataset.name === 'speech') {
      video.slides[editingSlideIndex].speech = inputText
      video.slides[editingSlideIndex].duration = undefined
      if (video.slides[editingSlideIndex].language)
        video.slides[editingSlideIndex].approxDuration = getApproxDuration({
          speech: inputText,
          language: video.slides[editingSlideIndex].language,
          voice: video.slides[editingSlideIndex].voice,
          voiceProvider: video.slides[editingSlideIndex].voiceProvider,
        })
    } else if (target.dataset.name === 'list') {
      const targetChildren = [...target.children]
      const itemsText = targetChildren.map((child) =>
        child.innerText === inputFieldsPlaceholders.list ? '' : formatInnerHtmlToText(child.innerHTML),
      )
      video.slides[editingSlideIndex].story = { ...editingSlide.story, list: itemsText }
      markSlideStatusAsStory(editingSlideIndex)
    } else {
      video.slides[editingSlideIndex].story = {
        ...editingSlide.story,
        [target.dataset.name]: inputText,
      }
      markSlideStatusAsStory(editingSlideIndex)
    }
    const { story } = video.slides[editingSlideIndex]
    if (!video.slides[editingSlideIndex].speech && !story.header && !story.subHeader) {
      resetLanguageAndVoice(editingSlideIndex)
    }
    updateVideo({ slides: video.slides })
  }

  const resetLanguageAndVoice = (slideIndex) => {
    video.slides[slideIndex] = {
      ...video.slides[slideIndex],
      voice: undefined,
      voiceProvider: undefined,
      language: undefined,
      status: 'story',
    }
  }

  const getActiveNode = () => {
    const node = document.getSelection().anchorNode
    return node?.nodeType === 3 ? node.parentNode : node
  }

  const handlePaste = async (e) => {
    if (isEditorBusy) {
      e.preventDefault()
      return
    }

    const slideItem = getActiveNode()?.closest('.slide-item')
    if (!slideItem) return

    e.preventDefault()

    const html = e.clipboardData.getData('text/html')
    const text = e.clipboardData.getData('text/plain')
    if (!html && !text) return

    const activeSlideId = Number(slideItem?.dataset?.sliderow)
    const editingSlide = video.slides.find((slide) => slide.id === activeSlideId)
    // const isPastingToSpeechText = getActiveNode()?.classList.contains('text-editor-speech')

    const newLinesCount = html.match(/<br/gi)?.length
    const pCount = html.match(/<p/gi)?.length
    const hasHeaders = /<h\d|<strong|<b/gi.test(html)
    // console.log({ text: text.length, newLinesCount, pCount, hasHeaders })

    if (
      text.length > 1400 ||
      (isEmptySlideStory(editingSlide) &&
        (text.includes('\n\n') || newLinesCount > 1 || pCount > 1 || (hasHeaders && pCount)))
    ) {
      document.activeElement.blur() // set target to body to prevent double paste that throws errors
      setIsParsingHtml(true)
      await saveVideo()
      const updatedVideo = await request({
        method: 'post',
        url: `story/paste/${videoId}`,
        data: {
          from: html || text,
          activeSlideId,
        },
      })
      if (updatedVideo) {
        updateVideo(updatedVideo, { skipAutoSave: true })
      }
      setIsParsingHtml(false)
      return
    }

    document.execCommand('insertText', false, text)
    saveTextEditorChanges()
  }

  const handleClickField = useCallback((e) => {
    const activeField = e.target.closest('[data-slide]')
    if (e.target.innerText === inputFieldsPlaceholders[activeField?.dataset.name || 'list']) {
      const selection = window.getSelection()
      const range = document.createRange()
      range.setStart(e.target, 0)
      range.collapse(true)
      selection.removeAllRanges()
      selection.addRange(range)
    }
  }, [])

  const validateVideo = () => {
    const errors = new Map([
      ['header', []],
      ['subHeader', []],
      ['list', []],
      ['images', []],
      ['speech', []],
    ])

    video.slides.forEach((slide, index) => {
      if (isEmptyString(slide.story.header)) errors.get('header').push(index)
      if (isEmptyString(slide.story.subHeader)) errors.get('subHeader').push(index)
      if (slide.story.list && (!slide.story.list.length || slide.story.list.every((item) => !item)))
        errors.get('list').push(index)
      if (slide.voiceType === 'text' && !slide.speech) errors.get('speech').push(index)
    })
    return [...errors.entries()].some((error) => error[1].length) ? errors : false
  }

  const generateVideo = async (fixWithGpt) => {
    setIsVideoGenerating(true)
    const data = await request({
      method: 'post',
      url: `/story/generate-slides/${videoId}`,
      data: { fixWithGpt: !!fixWithGpt },
    })
    setIsVideoGenerating(false)
    if (!data) return
    // drop thumbnails cache to prevent broken thumbnails
    await dropThumbnailCache(video)
    navigate(`/video/${videoId}`)
  }

  const validateAndGenerateVideo = async () => {
    setIsVideoGenerating(true)
    await saveVideo()
    const errors = validateVideo()
    setVideoErrors(errors)
    if (errors) {
      setIsOpenErrorsModal(true)
      setIsVideoGenerating(false)
      return
    }
    await generateVideo()
  }

  const handleSubmitGptInput = async (e) => {
    e.stopPropagation()
    if (!gptInputValue)
      return notification.error({
        message: isBlankVideo ? 'Please input topic of your video' : 'Please input prompt for GPT',
      })
    await generateOutline(gptInputValue)
  }

  const generateOutline = async (topic) => {
    setGptLoading({ key: 'create-outline', message: 'Generating outline', isLoading: true })
    const data = await callEnhancement('generate-outline', { topic })
    setGptLoading({ isLoading: false })
    if (!data) return
    outlineList.current = data.outline
    const name = topic.length > maxVideoNameLength ? topic.substring(0, maxVideoNameLength - 1) + '…' : topic
    updateVideo({ name })
    if (outlineList.current) setIsOpenOutlineModal(true)
  }

  const handleSlideEnhancementsMenu = useCallback(
    async ({ key }, slide) => {
      setGptLoading({ isLoading: true, slide: slide.id, message: 'Changing your slides' })
      if (key === 'custom-prompt') {
        setCustomPromptModal({ prompt: '', slideId: slide.id })
      } else {
        await applySlideEnhancement(slide.id, key)
      }
      setGptLoading({ isLoading: false })
    },
    [video, videoSavingStatus, applyVideoEnhancement, saveVideo, callEnhancement, updateVideo],
  )

  const handleContinue = () => {
    setIsOpenTemplatesModal(false)
    validateAndGenerateVideo()
  }

  const generateModalFooter = (handleChangeTemplate, openedTemplate, isChangingTemplate) => {
    return isSwitching
      ? [
          <Button
            key="changeTemplate"
            type="primary"
            disabled={openedTemplate ? false : true}
            loading={isChangingTemplate}
            onClick={handleChangeTemplate}
          >
            Apply new template
          </Button>,
        ]
      : [
          <Button key="continue" type="secondary" onClick={handleContinue}>
            Continue with default
          </Button>,
          <Button
            key="changeTemplate"
            type="primary"
            disabled={openedTemplate ? false : true}
            loading={isChangingTemplate}
            onClick={() => handleChangeTemplate(!isSwitching)}
          >
            Apply new template
          </Button>,
        ]
  }

  return {
    video,
    videoSavingStatus,
    isVideoGenerating,
    isBlankVideo,
    isEditorBusy,
    setIsEditorBusy,
    outlineList,
    layoutsData,
    isOpenOutlineModal,
    setIsOpenOutlineModal,
    videoErrors,
    setVideoErrors,
    isOpenErrorsModal,
    setIsOpenErrorsModal,
    gptLoading,
    setGptLoading,
    gptInputValue,
    setGptInputValue,
    isSwitching,
    setIsSwitching,
    customPromptModal,
    setCustomPromptModal,
    editingSlide,
    setEditingSlide,
    openImageIndex,
    setOpenImageIndex,
    isOpenTemplatesModal,
    setIsOpenTemplatesModal,
    changesHistory,
    undoLastChanges,
    updateVideo,
    saveVideo,
    validateAndGenerateVideo,
    handleKeyDownContainerContent,
    saveTextEditorChanges,
    markSlideStatusAsStory,
    handleClickField,
    handleSlideEnhancementsMenu,
    handleSubmitGptInput,
    applyVideoEnhancement,
    applySlideEnhancement,
    checkEmptySlide,
    generateModalFooter,
    generateVideo,
    ensureVideoSaved,
    updateCanvasesInStoryMode,
    updateCanvasInStoryMode,
    textEditorScrollbarRef,
  }
}
