import { DragSource, DropTarget } from 'react-dnd'
import { forwardRef, useImperativeHandle, useRef, useEffect, useCallback, memo } from 'react'

import { SlideItemContent } from './itemContent'
import { SlideItemWrapper } from './itemWrapper'
import { SlideNumber } from './slideNumber'

const ItemTypes = {
  SLIDE: 'slide',
}

const SlideItem = forwardRef(function Slide(props, ref) {
  const {
    index,
    slide,
    isLastSlide,
    isSlidesLimitReached,
    isMoreThanOneSlide,
    videoFormat,
    stableUpdateActiveSlide,
    stableDeleteSlide,
    stableDuplicateSlide,
    setTransitionModal,
    isDragging,
    connectDragSource,
    connectDropTarget,
    isBuilderReadyRef,
    isActive,
    playerActivePreview,
    playerStatus,
    thumbnailsRef,
  } = props

  const elementRef = useRef(null)
  const opacity = { opacity: isDragging ? 0 : 1 }
  useImperativeHandle(ref, () => ({
    getNode: () => elementRef.current,
  }))

  const handleChangeActiveSlide = useCallback(
    (index) => {
      if (isBuilderReadyRef.current) stableUpdateActiveSlide(index)
    },
    [stableUpdateActiveSlide, isBuilderReadyRef],
  )

  const handleSetTransition = () => {
    setTransitionModal(index)
  }

  useEffect(() => {
    connectDragSource(elementRef)
    connectDropTarget(elementRef)
  }, [connectDragSource, connectDropTarget])

  return (
    <SlideItemWrapper isActive={isActive} ref={elementRef}>
      <div className="item-wrapper" style={opacity}>
        <SlideNumber slideIndex={index} updateActiveSlide={handleChangeActiveSlide} />
        <SlideItemContent
          index={index}
          slideId={slide.id}
          isSlidesLimitReached={isSlidesLimitReached}
          isMoreThanOneSlide={isMoreThanOneSlide}
          videoFormat={videoFormat}
          updateActiveSlide={handleChangeActiveSlide}
          stableDeleteSlide={stableDeleteSlide}
          stableDuplicateSlide={stableDuplicateSlide}
          playerActivePreview={playerActivePreview}
          playerStatus={playerStatus}
          thumbnailsRef={thumbnailsRef}
        />
      </div>
      {!isLastSlide && (
        <div className={`transition ${slide.transition ? 'active' : ''}`}>
          <svg
            onClick={handleSetTransition}
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="5.6" cy="8.31776" r="5.1" stroke="#999999" />
            <path
              d="M10.8508 9.11532C11.0593 8.93881 11.255 8.74118 11.4347 8.52271C13.0488 6.56084 12.7668 3.66196 10.805 2.04788C8.84311 0.433799 5.94422 0.715739 4.33014 2.67761C4.18941 2.84867 4.06309 3.02684 3.95105 3.21067C3.39644 3.46484 2.88139 3.82421 2.43756 4.28572C2.62968 3.48734 3.00114 2.71902 3.55791 2.04227C5.52288 -0.346092 9.05195 -0.689323 11.4403 1.27564C13.8287 3.24061 14.1719 6.76968 12.2069 9.15805C11.601 9.89452 10.8464 10.4365 10.0223 10.7764C10.4105 10.2651 10.6856 9.70105 10.8508 9.11532Z"
              fill="#999999"
            />
          </svg>
        </div>
      )}
    </SlideItemWrapper>
  )
})

export default memo(
  DropTarget(
    ItemTypes.SLIDE,
    {
      hover(props, monitor, component) {
        if (!component) {
          return null
        }
        const node = component.getNode()
        if (!node) {
          return null
        }
        const dragIndex = monitor.getItem().index
        const hoverIndex = props.index

        if (dragIndex === hoverIndex) {
          return
        }

        const hoverBoundingRect = node.getBoundingClientRect()
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
        const clientOffset = monitor.getClientOffset()
        const hoverClientY = clientOffset.y - hoverBoundingRect.top

        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return
        }

        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return
        }

        props.stableMoveSlide(dragIndex, hoverIndex)
        monitor.getItem().index = hoverIndex
      },
    },
    (connect) => ({
      connectDropTarget: connect.dropTarget(),
    }),
  )(
    DragSource(
      ItemTypes.SLIDE,
      {
        beginDrag: (props) => ({
          id: props.id,
          index: props.index,
        }),
      },
      (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
      }),
    )(SlideItem),
  ),
)
