import { Card, Space, Button } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { memo, useMemo } from 'react'
import ShortcutTooltip from '../../shortcutTooltip'
import { osMap } from '../../../../../utils/constants'
import { os } from '../../../constants'

import { useGetSlideThumbnail } from '../../../useSlidesThumbnails'

import Icon from '../../../../../components/Icon'
import { videoFormats } from '../../../../../data/formats'

const slideItemWidth = 182

const styles = {
  wrapper: { position: 'relative', transform: 'translate(0px, 0px)' },
  container: { width: '180px', height: '101px' },
}

const iconCopy = <Icon name="copy" />
const iconDelete = <Icon name="delete" />

export const SlideItemContent = memo((props) => {
  const {
    index,
    slideId,
    isSlidesLimitReached,
    isMoreThanOneSlide,
    videoFormat,
    updateActiveSlide,
    stableDeleteSlide,
    stableDuplicateSlide,
    playerActivePreview,
    playerStatus,
    thumbnailsRef,
  } = props

  const thumbnail = useGetSlideThumbnail({ slideId, thumbnailsRef })

  const videoFormatWidth = useMemo(
    () => (videoFormats[videoFormat || '16_9'].width * slideItemWidth) / 640,
    [videoFormat, slideItemWidth],
  )

  const limiterWidth = useMemo(
    () => ({ width: (slideItemWidth - videoFormatWidth) / 2 }),
    [slideItemWidth, videoFormatWidth],
  )

  const handleUpdate = () => {
    window.getSelection().removeAllRanges()
    updateActiveSlide(index)
  }

  const handleRef = (ref) => {
    if (!ref) return

    if (thumbnail && playerStatus !== 'playing' && !thumbnail.reactNode) {
      ref.innerHTML = ''
      thumbnail.style.width = '100%'
      ref.appendChild(thumbnail)
    } else {
      const canvas = ref.querySelector('canvas')
      if (canvas) ref.removeChild(canvas)
    }
  }

  const handleCopySlide = () => {
    stableDuplicateSlide(index)
  }

  const handleDeleteSlide = () => {
    stableDeleteSlide(index)
  }

  return (
    <div className="item-card-wrapper">
      <Card
        className="item"
        size="small"
        cover={
          <div className="item-thumbnail" onClick={handleUpdate}>
            {thumbnail?.reactNode ? (
              <div key="react-node" style={styles.container}>
                {thumbnail?.reactNode}
              </div>
            ) : (
              <div key="html-element" style={styles.container} ref={handleRef}>
                {!thumbnail && (
                  <div className="item-thumbnail-loading">
                    <LoadingOutlined />
                  </div>
                )}
              </div>
            )}
            <div className="video-format-limiter" style={limiterWidth} />
            <div className="video-format-limiter right" style={limiterWidth}></div>
          </div>
        }
        data-slide-index={index}
      />
      <Space size={4} direction="vertical" className="item-btns-wrapper">
        {!playerActivePreview && (
          <ShortcutTooltip
            title={!isSlidesLimitReached ? 'Duplicate slide' : 'Maximum slides limit reached'}
            keyName="D"
            placement="top"
            showKeys={!isSlidesLimitReached}
          >
            <Button
              className="item-btn copy"
              type="primary"
              icon={iconCopy}
              onClick={handleCopySlide}
              disabled={isSlidesLimitReached}
            />
          </ShortcutTooltip>
        )}
        {isMoreThanOneSlide && !playerActivePreview && (
          <ShortcutTooltip
            title="Remove slide"
            keyName={os === osMap.MAC ? 'DEL' : 'BCKSP'}
            placement="bottom"
            showMetaKey={false}
          >
            <Button className="item-btn delete" type="default" icon={iconDelete} onClick={handleDeleteSlide} />
          </ShortcutTooltip>
        )}
      </Space>
    </div>
  )
})

SlideItemContent.displayName = 'SlideItemContent'
