import { useEffect, useRef, useState } from 'react'
import { Button, Input, Modal } from 'antd'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

import Icon from '../../components/Icon'

import { useStore } from '../../store'
import { request } from '../../utils/api'
import { escapeRegExp } from '../../utils/helpers'

import { searchOutlinedIcon } from './constants'
import { useElaiNotification } from '../../hooks/useElaiNotification'

const { confirm } = Modal

// NOTE: remove current page from localStorage to reset pagination after web page has completely reloaded
localStorage.removeItem('page')

export const useVideosState = () => {
  /* hooks */
  const notification = useElaiNotification()
  const navigate = useNavigate()
  const lastPage = localStorage.getItem('page') || 1

  const { folderId } = useParams()

  const location = useLocation()
  const isDeletedVideos = location.pathname.includes('/deleted-videos')

  /* refs */
  const inputSearchVideos = useRef(null)
  const inputNewFolderNameRef = useRef(null)

  /* state */
  const [folders, setFolders] = useState()
  const [openFolder, setOpenFolder] = useState()
  const [videosData, setVideosData] = useState()
  const [filterInfo, setFilterInfo] = useState({})
  const [allVideoTags, setAllVideoTags] = useState([])
  const [selectedVideos, setSelectedVideos] = useState([]) // TODO: store only id of the videos
  const [isVideoCreating, setIsVideoCreating] = useState(false)
  const [isFolderCreating, setIsFolderCreating] = useState(false)
  const [isOpenNewFolderModal, setIsOpenNewFolderModal] = useState(false)

  const [videosListFormat, setVideosListFormat] = useState(localStorage.getItem('videosListFormat'))

  /* store */
  const fetchTemplates = useStore((stores) => stores.videosStore.fetchTemplates)
  const { setIsNewVideoModalOpen, setHeader } = useStore((stores) => stores.domStore)

  /* variables */
  const basicDeletedVideosQuery = {
    deleted: true,
    template: true,
    $or: [
      {
        $or: [{ folderId: { $exists: false } }, { parentFolderDeleted: false }],
      },
      { template: { $exists: true } },
    ],
  }

  /* functions */
  const fetchVideos = async (condition, signal) => {
    setVideosData(null)
    const data = await request({ method: 'post', url: 'videos/lookup', data: condition, signal })
    if (data) setVideosData(data)
    if (data?.tags) {
      setAllVideoTags(data.tags)
    }
  }

  const fetchFolders = async (condition, signal) => {
    setFolders(null)
    const data = { match: { deleted: condition?.match?.deleted }, sort: condition.sort }
    if (folderId) {
      const folder = await request({
        method: 'post',
        url: `/folders/folder/${folderId}`,
        data,
      })
      setOpenFolder(folder)
      setFolders(folder.children)
    } else {
      const folders = await request({
        method: 'post',
        url: '/folders/lookup',
        data,
        signal,
      })
      if (folders) setFolders(folders)
    }
  }

  const onSearchVideos = (value) => {
    if (value) {
      setFilterInfo((filterInfo) => ({
        ...filterInfo,
        filters: { ...filterInfo.filters, name: [value] },
        condition: {
          ...filterInfo.condition,
          match: { ...filterInfo.condition?.match, name: { $regex: escapeRegExp(value), $options: 'i' } },
        },
      }))
    } else {
      setFilterInfo((filterInfo) => {
        delete filterInfo.filters.name
        delete filterInfo.condition.match.name
        return { ...filterInfo, condition: { ...filterInfo.condition } }
      })
    }
  }

  const deleteSelectedVideos = async () => {
    if (isDeletedVideos) {
      await Promise.all(
        selectedVideos.map(async (video) => await request({ method: 'delete', url: `videos/${video._id}` })),
      )
    } else {
      await Promise.all(
        selectedVideos.map(
          async (video) =>
            await request({ method: 'patch', url: `videos/${video._id}`, data: { deleted: true, public: false } }),
        ),
      )
    }
    await fetchVideos(filterInfo.condition)
    setSelectedVideos([])
  }

  const showDeletingConfirm = () => {
    confirm({
      title: isDeletedVideos
        ? 'Are you sure you want to delete the selected videos permanently? This action is irreversible'
        : 'Are you sure you want to delete the selected videos?',
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          {selectedVideos.map((video) => (
            <p
              key={video._id}
              style={{ marginBottom: '3px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
            >
              {video.name}
            </p>
          ))}
        </div>
      ),
      onOk: deleteSelectedVideos,
      okText: 'Ok',
      cancelText: 'Cancel',
    })
  }

  const restoreVideo = async (id) => {
    setIsVideoCreating(id)
    const video = await request({ method: 'patch', url: `/videos/${id}`, data: { deleted: false } })
    notification.success({ message: 'Your video has been restored' })
    if (video.template) {
      await fetchTemplates()
    }
    await fetchVideos(filterInfo.condition)
    setIsVideoCreating(false)
  }

  const handleTableChange = async (pagination, filters, sorter) => {
    localStorage.setItem('page', pagination.current)
    const condition = { page: pagination.current }
    condition.match = {}
    if (sorter.order) condition.sort = { [sorter.field]: sorter.order === 'ascend' ? 1 : -1 }
    for (const key in filters)
      if (filters[key]) {
        if (key === 'name') {
          condition.match.name = { $regex: escapeRegExp(filters.name[0]), $options: 'i' }
        } else if (key === 'tags') {
          condition.match.tags = { $in: filters.tags }
        } else {
          condition.match[key] = filters[key][0]
        }
      }

    if (folderId) {
      condition.match = { ...condition.match, deleted: isDeletedVideos, folderId }
    } else {
      const videosCondition = isDeletedVideos
        ? basicDeletedVideosQuery
        : { deleted: false, folderId: { $exists: false } }
      condition.match = { ...condition.match, ...videosCondition }
    }

    if (Object.keys(condition.match).length === 0) {
      delete condition.match
    }
    setFilterInfo({ sorter, filters, condition })
    await fetchFolders(condition)
  }

  const onSelectTag = async (tag, video) => {
    if (/^\s*$/.test(tag)) {
      return
    }
    setAllVideoTags((videoTags) => {
      if (!videoTags.find((videoTag) => videoTag === tag)) {
        return [...videoTags, tag]
      }
      return videoTags
    })
    if (!video.tags?.length) {
      video.tags = []
    }
    video.tags.push(tag)
    await request({ method: 'patch', url: `/videos/${video._id}`, data: { tags: video.tags } })
  }

  const onDeselectTag = async (tag, video) => {
    setAllVideoTags((videoTags) => {
      videoTags.splice(videoTags.indexOf(tag), 1)
      return [...videoTags]
    })
    if (video.tags?.length) video.tags.splice(video.tags.indexOf(tag), 1)
    const data = await request({ method: 'patch', url: `/videos/${video._id}`, data: { tags: video.tags } })
    const result = videosData.videos.map((video) => {
      if (video._id === data._id) {
        video = data
      }
      return video
    })
    setVideosData({ ...videosData, videos: result })
  }

  const handleSortItems = ({ key }) => {
    const [field, order] = key.split('_')
    setFilterInfo({
      ...filterInfo,
      sorter: { field, order },
      condition: { ...filterInfo.condition, sort: { [field]: order === 'ascend' ? 1 : -1 } },
    })
  }

  const createFolder = async () => {
    const name = inputNewFolderNameRef.current.input.value
    if (!name) {
      inputNewFolderNameRef.current.focus()
      return notification.error({ message: 'Please input folder name' })
    }
    if (name.length > 100) {
      inputNewFolderNameRef.current.focus()
      return notification.error({ message: 'Folder name must be less than 100 characters' })
    }
    setIsFolderCreating(true)
    const res = await request({
      method: 'post',
      url: '/folders',
      data: { name, parentFolderId: folderId },
    })
    if (res) {
      setIsOpenNewFolderModal(false)
      setIsFolderCreating(false)
      await fetchFolders(filterInfo.condition)
    }
    setIsFolderCreating(false)
  }

  const showRestoringConfirm = (id) => {
    confirm({
      title: 'This video has been deleted',
      icon: <ExclamationCircleOutlined />,
      content: 'Restore this video to open it',
      okText: 'Restore',
      onOk() {
        restoreVideo(id)
      },
    })
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={confirm}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={searchOutlinedIcon}
            size="small"
            style={{ width: '49%' }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters()
              confirm()
            }}
            size="small"
            style={{ width: '49%' }}
          >
            Reset
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  })

  const navigateToVideos = () => {
    navigate('/videos')
  }

  /* lifecycle */
  useEffect(() => {
    const abortController = new AbortController()

    const fetchData = async (signal) => {
      const { condition } = filterInfo
      if (!condition) {
        return
      }

      await Promise.all([fetchVideos(condition, signal), fetchFolders(condition, signal)])
    }
    fetchData(abortController.signal)

    return () => abortController.abort()
  }, [filterInfo.condition])

  //Re-render component when switch videos to deleted videos
  useEffect(() => {
    const updateComponent = async () => {
      const input = (
        <Input.Search
          ref={inputSearchVideos}
          key="input-search"
          placeholder="Video name"
          className="input-search-videos"
          onSearch={onSearchVideos}
        />
      )
      if (isDeletedVideos)
        setHeader({
          title: 'Deleted videos',
          icon: <Icon name="delete" />,
          link: '/deleted-videos',
          extra: [input],
        })
      else
        setHeader({
          title: 'Videos',
          icon: <Icon name="video_camera" />,
          link: '/videos',
          extra: [
            input,
            <Button
              key="btn-create"
              type="primary"
              icon={<Icon name="add_video" />}
              onClick={() => setIsNewVideoModalOpen(true)}
            >
              Create video
            </Button>,
          ],
        })
    }
    updateComponent()
  }, [isDeletedVideos])

  useEffect(() => {
    const fetchData = async () => {
      notification.destroy('moved')
      setVideosData(null)
      setFolders(null)

      if (folderId) {
        setFilterInfo({
          sorter: {},
          filters: {},
          condition: {
            page: lastPage,
            match: { deleted: isDeletedVideos, folderId },
          },
        })
      } else {
        setOpenFolder(false)

        const videosCondition = isDeletedVideos
          ? basicDeletedVideosQuery
          : { deleted: false, folderId: { $exists: false } }

        setFilterInfo({
          sorter: {},
          filters: {},
          condition: {
            page: lastPage,
            match: { ...videosCondition },
          },
        })
      }
    }

    fetchData()
  }, [folderId, isDeletedVideos])

  useEffect(() => {
    if (!videosListFormat) {
      localStorage.setItem('videosListFormat', 'grid')
      setVideosListFormat('grid')
    }
    return () => notification.destroy('moved')
  }, [])

  useEffect(() => {
    if (isOpenNewFolderModal) {
      setTimeout(() => inputNewFolderNameRef.current.focus({ cursor: 'all' }), 100)
    }
  }, [isOpenNewFolderModal])

  useEffect(() => {
    const tags = videosData?.videos.map((video) => video.tags)
    const result = new Set(['gpt', ...(tags ? tags.flat() : [])])
    setAllVideoTags([...result])
  }, [videosData])

  return {
    folders,
    navigate,
    folderId,
    filterInfo,
    videosData,
    openFolder,
    onSelectTag,
    fetchVideos,
    fetchFolders,
    allVideoTags,
    createFolder,
    onDeselectTag,
    setFilterInfo,
    selectedVideos,
    isVideoCreating,
    isDeletedVideos,
    handleSortItems,
    videosListFormat,
    navigateToVideos,
    isFolderCreating,
    handleTableChange,
    setSelectedVideos,
    setIsVideoCreating,
    setVideosListFormat,
    showDeletingConfirm,
    showRestoringConfirm,
    isOpenNewFolderModal,
    getColumnSearchProps,
    inputNewFolderNameRef,
    setIsNewVideoModalOpen,
    setIsOpenNewFolderModal,
  }
}
