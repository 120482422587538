import configService from '../config'

const defaultWeight = 'normal'

const { fallbackFonts, fontWeightMap } = configService.get()

export const getFontFamilyWithFallback = (font) => `${quotesIfMistakenFontName(font)}, ${fallbackFonts.join(', ')}`

const mapHumanReadableFontWeight = (weight) => {
  if (!weight) return defaultWeight

  for (const [key, value] of Object.entries(fontWeightMap)) {
    if (weight.includes(key)) {
      return value
    }
  }
  return defaultWeight
}

export const mapSubfamilyToStyle = (subfamily) => {
  const fontWeight = mapHumanReadableFontWeight(subfamily)
  const fontStyle = subfamily?.includes('Italic') ? 'italic' : 'normal'

  return { fontWeight, fontStyle }
}

export const quotesIfMistakenFontName = (string) => {
  if (/\d/.test(string)) return string.startsWith('"') && string.endsWith('"') ? string : `"${string}"`
  return string
}
