import { useRef, memo, useState, useEffect, useCallback } from 'react'
import { Row, Col, Dropdown, Divider, Button, Tooltip } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import ContentEditable from 'react-contenteditable'
import { useDrag, useDrop } from 'react-dnd'
import Icon from '../../../../components/Icon'
import { EditSlidePopover } from './components/editSlidePopover'
import { getRandomNumber, uniqueId, clone } from '../../../../utils/helpers'
import { EDITOR_SYNC_TIMEOUT_MS, inputFieldsPlaceholders, MAX_LIST_ITEMS, SAVE_TRIGGER_DELAY_MS } from '../../constants'
import {
  isFieldDefined,
  resetStory,
  isComplexStory,
  makeLayoutFromSlide,
  isCanvasEmptyForStory,
  ifLayoutsSimilar,
  pasteTextToActiveElement,
} from '../../../../utils/videoStory/helpers'
import { useSlideDuration } from '../../../../hooks/useSlideDuration'
import DurationInfo from '../../../video/components/durationInfo'
import { track } from '../../../../utils/analytics'
import { useRerender } from '../../../../hooks/useRerender'

const ContentEditableMemoized = memo(
  ({ ...props }) => {
    return <ContentEditable {...props}></ContentEditable>
  },
  (prevProps, nextProps) => {
    if (prevProps.disabled !== nextProps.disabled) return false

    const { listChangedOutside, setListChangedOutside } = nextProps
    if (listChangedOutside) {
      setListChangedOutside(false)
      return false
    }
    return true
  },
)

const compareLists = (firstList, secondList) =>
  firstList.length === secondList.length && firstList.every((item, index) => item === secondList[index])

const SlideItem = memo(
  ({
    index,
    video,
    slide,
    videoErrors,
    gptLoading,
    layoutsData,
    textEditorScrollbarRef,
    updateVideo,
    setOpenImageIndex,
    setEditingSlide,
    markSlideStatusAsStory,
    handleClickField,
    handleSlideEnhancementsMenu,
    checkEmptySlide,
    isEditorBusy,
    updateCanvasInStoryMode,
    saveTextEditorChanges,
  }) => {
    const { rerender } = useRerender()
    const slideRef = useRef(null)
    const fieldsRef = {
      header: useRef(null),
      subHeader: useRef(null),
      list: useRef(null),
      speech: useRef(null),
    }
    const timeoutsRef = useRef({})

    // https://github.com/lovasoa/react-contenteditable?tab=readme-ov-file#known-issues
    const headerValueRef = useRef(slide.story?.header || '')
    const subHeaderValueRef = useRef(slide.story?.subHeader || '')
    const listValueRef = useRef(slide.story?.list || [''])
    const speechValueRef = useRef(slide.speech || '')

    const [isAddingNewSlide, setIsAddingNewSlide] = useState(false)
    const [listChangedOutside, setListChangedOutside] = useState(false)
    const scrollbarNode = textEditorScrollbarRef.current?.view
    const scrollbarOffset = scrollbarNode?.getBoundingClientRect()

    const { slideDurationOrApprox, slideDurationApproxLimit } = useSlideDuration({ slide })

    // sync after AI enhancement
    useEffect(() => {
      if (!speechValueRef.current || slide.speech !== speechValueRef.current) speechValueRef.current = slide.speech
      if (!headerValueRef.current || slide.story.header !== headerValueRef.current)
        headerValueRef.current = slide.story.header
      if (!subHeaderValueRef.current || slide.story.subHeader !== subHeaderValueRef.current)
        subHeaderValueRef.current = slide.story.subHeader
      if (
        !listValueRef.current ||
        (listValueRef.current &&
          slide.story.list &&
          !slide.story.list.some((l) => !l) &&
          !compareLists(listValueRef.current, slide.story.list))
      ) {
        listValueRef.current = slide.story.list.slice(0, MAX_LIST_ITEMS)
        setListChangedOutside(true)
      }
    }, [slide?.speech, slide?.story])

    const handleBlurField = (e) => {
      // if placeholder is removed, then we need to add it back
      // (workaround for React delayed rendering)
      if (!e.target.innerHTML || e.target.innerHTML.startsWith('<span class="placeholder">')) {
        setTimeout(
          () => (e.target.innerHTML = formatEditorFieldValue('', inputFieldsPlaceholders[e.target.dataset.name])),
          EDITOR_SYNC_TIMEOUT_MS,
        )
      }
    }

    const handlePasteListItem = (e) => {
      try {
        e.preventDefault()

        const text = e.clipboardData.getData('text/plain')
        pasteTextToActiveElement(text)
        syncStoryField('list')

        e.stopPropagation()
      } catch {
        // ignore
      }
    }

    const handleKeyDown = (e) => {
      if (['Meta', 'Control'].includes(e.key)) return

      if ((e.metaKey || e.ctrlKey) && ['c', 'a']) {
        return
      }

      syncStoryField(e.currentTarget.dataset.name)
    }

    const syncStoryField = (field) => {
      if (field === 'list') {
        // sync list state with outer non-react state, updated directly in DOM
        syncListInternalState()
      }
      if (timeoutsRef.current[field]) clearTimeout(timeoutsRef.current[field])
      timeoutsRef.current[field] = setTimeout(
        (name) => saveTextEditorChanges(fieldsRef[name].current),
        SAVE_TRIGGER_DELAY_MS,
        field,
      )
    }

    const syncListInternalState = () => {
      const value = Array.from(fieldsRef.list.current.querySelectorAll('li')).map((li) => li.innerText)
      listValueRef.current = value
    }

    const [, drop] = useDrop({
      accept: 'slide',
      collect: (monitor) => ({
        handlerId: monitor.getHandlerId(),
      }),
      hover: (item, monitor) => {
        if (!slideRef.current) return

        const dragIndex = item.index
        const hoverIndex = index

        if (dragIndex === hoverIndex) return

        const hoverBoundingRect = slideRef.current?.getBoundingClientRect()
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
        const clientOffset = monitor.getClientOffset()
        const hoverClientY = clientOffset.y - hoverBoundingRect.top

        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return

        moveSlide(dragIndex, hoverIndex)

        item.index = hoverIndex
      },
    })

    const [{ isDragging }, drag, preview] = useDrag({
      type: 'slide',
      canDrag: !isEditorBusy,
      item: () => ({ index }),
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    })

    drag(drop(slideRef))

    const handleDragSlide = (e) => {
      const targetHeight = e.target.getBoundingClientRect().height
      if (e.pageY > scrollbarOffset.bottom - targetHeight / 2) {
        scrollbarNode.scrollTo({
          top: scrollbarNode.scrollTop + 50,
          behavior: 'smooth',
        })
      } else if (e.pageY < scrollbarOffset.top + targetHeight / 2) {
        scrollbarNode.scrollTo({
          top: scrollbarNode.scrollTop - 50,
          behavior: 'smooth',
        })
      }
    }

    const moveSlide = (dragIndex, hoverIndex) => {
      const dragSlides = video.slides
      const dragSlide = video.slides[dragIndex]

      dragSlides.splice(dragIndex, 1)
      dragSlides.splice(hoverIndex, 0, dragSlide)

      updateVideo({ slides: [...dragSlides] }, { updatedSlideId: dragSlide.id })
    }

    const deleteSlide = (index) => {
      if (video.slides.length === 1) {
        const slide = video.slides[0]
        slide.speech = ''
        resetStory(slide)
        updateVideo({ slides: [slide] })
      } else {
        const [removedSlide] = video.slides.splice(index, 1)
        const update = { slides: [...video.slides] }

        const layoutCandidate = makeLayoutFromSlide(removedSlide)
        if (!video.extraLayouts.some((l) => ifLayoutsSimilar(l, layoutCandidate)))
          update.extraLayouts = [...video.extraLayouts, layoutCandidate]

        updateVideo(update)
      }
    }

    const applyPptxChanges = async (slide) => {
      if (!slide.pptx) return
      track('story_ppt_static_convert_confirm', {
        id: video._id,
        type: slide.pptx.isStatic ? 'toStatic' : 'toDynamic',
      })
      updateVideo({ slides: [...video.slides] })
      await updateCanvasInStoryMode(slide)
    }

    /**
     * Apply layout from extraLayouts or from other slide
     * - remove layout from extraLayouts (only if exists, as for slide it will not exist)
     * - use current slide layout and store it to extra, so we keep it in layouts list
     */
    const applyLayoutToSlide = async (layout) => {
      // drop static if created from dynamic pptx but after is set to static, as with applying layout it assumes is dynamic now
      if (slide.pptx?.isStatic) slide.pptx.isStatic = false
      const extraLayoutFromSlide = makeLayoutFromSlide(slide)
      const { id, objects, version, background, story, avatar } = clone(layout)
      slide.canvas = { objects, version, background }
      // we need to make sure we have ONLY keys from layout but override with real slide values from current slide on top
      Object.keys(story).forEach((key) => {
        if (key === 'isTooComplex') return

        if (key in slide.story) {
          // keep lenght of array from layout
          if (['list', 'images'].includes(key)) story[key] = story[key].map((v, i) => slide.story[key][i] || null)
          else story[key] = slide.story[key]
        } else {
          // just reset to initial value
          if (['list', 'images'].includes(key)) story[key] = Array(story[key].length).fill(null)
          else story[key] = ''
        }
      })
      slide.story = story
      slide.avatar = avatar

      const filteredExtraLayouts = video.extraLayouts.filter((l) => l.id !== id)

      markSlideStatusAsStory(video.slides.indexOf(slide))
      updateVideo({ slides: [...video.slides], extraLayouts: [extraLayoutFromSlide, ...filteredExtraLayouts] })
      await updateCanvasInStoryMode(slide)

      track('story_change_slide_layout', { id: video._id })
    }

    const addSlide = async (previousSlideIdx) => {
      setIsAddingNewSlide(true)
      const newSlide = {
        ...clone(video.slides[getRandomNumber(video.slides.length)]),
        status: 'story',
        speech: '',
        id: uniqueId(),
      }
      delete newSlide.pptx
      if (video.extraLayouts.length) {
        const { objects, version, background, story, avatar } =
          video.extraLayouts[getRandomNumber(video.extraLayouts.length)]
        newSlide.canvas = { objects, version, background }
        newSlide.story = clone(story)
        newSlide.avatar = avatar
      }
      resetStory(newSlide, { keepIsTooComplex: true })
      video.slides.splice(previousSlideIdx + 1, 0, newSlide)
      updateVideo({ slides: [...video.slides] })
      setIsAddingNewSlide(false)
    }

    const checkSlideContainsErrors = (index) =>
      videoErrors && [...videoErrors.values()].flat().some((value) => value === index)

    // prepare content for ContentEditable, reverse in formatInnerHtmlToText function
    const formatEditorFieldValue = (value, placeholder) =>
      value
        ? value
            .replaceAll?.(/\n|\r\n/g, '<br>')
            // Rename 'mark' to 'm' as <mark> tag is reserved https://developer.mozilla.org/en-US/docs/Web/HTML/Element/mark
            .replaceAll(/<mark([^>]*)>/g, `<m$1>`)
            // Convert self-closing tags to tags with empty inner content
            .replaceAll(/<(\w+)([^>]*)\/>/g, `<$1$2></$1>`)
        : `<span class="placeholder">${placeholder}</span>`

    const handleContentEditableChange = useCallback(
      (event, valueRef) => {
        valueRef.current = event.target.value === '<br>' ? '' : event.target.value
        rerender()
        setTimeout(() => {
          handleClickField(event.nativeEvent)
        }, 1)
      },
      [rerender, handleClickField],
    )

    const slideEnhancementsMenuItems = [
      {
        label: (
          <Tooltip
            title="GPT will generate any missed content required to create slides: headers, subheaders, speech text and even images."
            placement="left"
          >
            <span className="menu-item-label">
              <Icon name="text_fields" />
              <span>Add missing content</span>
            </span>
          </Tooltip>
        ),
        key: 'add-missed-texts',
      },
      {
        label: (
          <Tooltip title="Make speech text shorter to make sure it fits slide limit of 60 seconds." placement="left">
            <span className="menu-item-label">
              <Icon name="horizontal_minimize" />
              <span>Make shorter</span>
            </span>
          </Tooltip>
        ),
        key: 'shorter',
      },
      {
        label: (
          <Tooltip title="Make speech text longer" placement="left">
            <span className="menu-item-label">
              <Icon name="horizontal_maximize" />
              <span>Make longer</span>
            </span>
          </Tooltip>
        ),
        key: 'longer',
        show: () => slideDurationOrApprox < slideDurationApproxLimit * 0.5,
      },
      {
        label: (
          <Tooltip title="Make your speech sound more professional" placement="left">
            <span className="menu-item-label">
              <Icon name="office_bag" />
              <span>More professional</span>
            </span>
          </Tooltip>
        ),
        key: 'professional',
      },
      {
        label: (
          <Tooltip title="Make your speech sound more engaging" placement="left">
            <span className="menu-item-label">
              <Icon name="light_bulb" />
              <span>More engaging</span>
            </span>
          </Tooltip>
        ),
        key: 'engaging',
      },
      {
        label: (
          <Tooltip title="Make your speech sound happier" placement="left">
            <span className="menu-item-label">
              <Icon name="happy_face" />
              <span>Sound happier</span>
            </span>
          </Tooltip>
        ),
        key: 'happier',
      },
      {
        label: (
          <Tooltip title="Re-write speech text with different words" placement="left">
            <span className="menu-item-label">
              <Icon name="paper_plane" />
              <span>Re-write</span>
            </span>
          </Tooltip>
        ),
        key: 'rewrite',
      },
      {
        label: (
          <Tooltip title="Apply custom prompt to slide speech text" placement="left">
            <span className="menu-item-label">
              <Icon name="robot" />
              <span>Custom prompt</span>
            </span>
          </Tooltip>
        ),
        key: 'custom-prompt',
      },
    ]
    return (
      <div ref={preview} style={{ opacity: isDragging ? 1 : 1 }}>
        <Row
          gutter={[12, 20]}
          data-sliderow={slide.id}
          className={`slide-item ${checkSlideContainsErrors(index) ? 'error' : ''}`}
        >
          <Col
            ref={slideRef}
            contentEditable={false}
            suppressContentEditableWarning={true}
            className="template-column"
            style={{ cursor: isEditorBusy ? 'default' : 'move' }}
            onDrag={handleDragSlide}
          >
            <EditSlidePopover
              slideIndex={index}
              slide={slide}
              video={video}
              layoutsData={layoutsData}
              applyPptxChanges={applyPptxChanges}
              applyLayoutToSlide={applyLayoutToSlide}
              isEditorBusy={isEditorBusy}
            />
          </Col>
          <Col key={slide.id} className="content-column">
            {!isCanvasEmptyForStory(slide.story) && (
              <div contentEditable={false} suppressContentEditableWarning={true} className="text-editor-notes">
                Slide visuals{' '}
                <Tooltip
                  title="The slide visuals depend on the selected layout. To choose a layout, simply click on the slide thumbnail."
                  placement="bottom"
                >
                  <Icon name="info" />
                </Tooltip>
              </div>
            )}
            <div className="text-editor-slide">
              {isComplexStory(slide.story) && (
                <div contentEditable={false} suppressContentEditableWarning={true} class="text-editor-complex-warning">
                  <Icon name="info" />
                  This slide can only be edited after being converted to video due to its visual complexity (it contains
                  too many different elements).
                </div>
              )}
              {isFieldDefined(slide.story.header) && (
                <div contentEditable={false} suppressContentEditableWarning={true}>
                  <ContentEditable
                    innerRef={fieldsRef.header}
                    html={formatEditorFieldValue(headerValueRef.current, inputFieldsPlaceholders.header)}
                    data-name="header"
                    data-slide={slide.id}
                    tagName="h2"
                    suppressContentEditableWarning={true}
                    onClickCapture={handleClickField}
                    onBlurCapture={handleBlurField}
                    onKeyDown={handleKeyDown}
                    disabled={isEditorBusy}
                    onChange={(e) => handleContentEditableChange(e, headerValueRef)}
                  />
                </div>
              )}
              {isFieldDefined(slide.story.subHeader) && (
                <div contentEditable={false} suppressContentEditableWarning={true}>
                  <ContentEditable
                    innerRef={fieldsRef.subHeader}
                    html={formatEditorFieldValue(subHeaderValueRef.current, inputFieldsPlaceholders.subHeader)}
                    data-name="subHeader"
                    data-slide={slide.id}
                    tagName="p"
                    className="text-editor-subheader"
                    suppressContentEditableWarning={true}
                    onClickCapture={handleClickField}
                    onBlurCapture={handleBlurField}
                    onKeyDown={handleKeyDown}
                    disabled={isEditorBusy}
                    onChange={(e) => handleContentEditableChange(e, subHeaderValueRef)}
                  />
                </div>
              )}
              {slide.story.list && (
                <div contentEditable={false} suppressContentEditableWarning={true}>
                  <ContentEditableMemoized
                    innerRef={fieldsRef.list}
                    listChangedOutside={listChangedOutside}
                    setListChangedOutside={setListChangedOutside}
                    html={listValueRef.current
                      .map(
                        (item, i) =>
                          `<li id="list-item-${slide.id}-${i}">${
                            item
                              ? item.replaceAll?.(/\n|\r\n/g, '<br>')
                              : `<span class="placeholder">${inputFieldsPlaceholders.list}</span>`
                          }</li>`,
                      )
                      .join('')}
                    data-name="list"
                    data-slide={slide.id}
                    tagName="ul"
                    className="text-editor-list"
                    suppressContentEditableWarning={true}
                    disabled={isEditorBusy}
                    onClickCapture={(e) => {
                      if (e.target.tagName === 'UL') return
                      handleClickField(e)
                    }}
                    onPasteCapture={handlePasteListItem}
                    onKeyDown={handleKeyDown}
                  />
                </div>
              )}
              {!!slide.story.images?.length && (
                <div className="text-editor-btns">
                  {Array(slide.story.images.length)
                    .fill(0)
                    .map((_, i) =>
                      slide.story.images && slide.story.images[i] ? (
                        <div
                          key={i}
                          className="text-editor-image"
                          onClick={() => {
                            if (isEditorBusy) return
                            setOpenImageIndex(i)
                            setEditingSlide(slide)
                          }}
                          contentEditable={false}
                          suppressContentEditableWarning={true}
                        >
                          <img
                            src={slide.story.images[i].thumbnail || slide.story.images[i].src}
                            className="image-thumbnail"
                            contentEditable={false}
                            suppressContentEditableWarning={true}
                          />
                        </div>
                      ) : (
                        <Button
                          key={i}
                          type="dashed"
                          className="btn-add-item"
                          contentEditable={false}
                          suppressContentEditableWarning={true}
                          icon={<Icon name="add_template" />}
                          disabled={isEditorBusy}
                          onClick={() => {
                            setOpenImageIndex(i)
                            setEditingSlide(slide)
                          }}
                        />
                      ),
                    )}
                </div>
              )}
              {slide.voiceType === 'text' && (
                <div>
                  <div
                    contentEditable={false}
                    suppressContentEditableWarning={true}
                    className="text-editor-notes speech-notes"
                  >
                    Speech text
                  </div>
                  <div contentEditable={false} suppressContentEditableWarning={true}>
                    <ContentEditable
                      innerRef={fieldsRef.speech}
                      html={formatEditorFieldValue(speechValueRef.current, inputFieldsPlaceholders.speech)}
                      data-name="speech"
                      data-slide={slide.id}
                      tagName="p"
                      className="text-editor-speech"
                      suppressContentEditableWarning={true}
                      onClickCapture={handleClickField}
                      onBlurCapture={handleBlurField}
                      onKeyDown={handleKeyDown}
                      disabled={isEditorBusy}
                      onChange={(e) => handleContentEditableChange(e, speechValueRef)}
                    />
                  </div>
                </div>
              )}
            </div>
            <Divider>
              <Tooltip placement="top" title={video.slides.length > 99 ? 'Max slides limit reached' : ''}>
                <Button
                  className="btn-add-slide"
                  contentEditable={false}
                  suppressContentEditableWarning={true}
                  shape="circle"
                  size="small"
                  disabled={video.slides.length > 99 || isEditorBusy}
                  loading={isAddingNewSlide}
                  icon={<Icon name="plus" />}
                  onClick={() => addSlide(index)}
                />
              </Tooltip>
            </Divider>
          </Col>
          <Col contentEditable="false" suppressContentEditableWarning={true} className="options-column">
            {gptLoading.slide === slide.id ? (
              <LoadingOutlined className="loader" />
            ) : (
              <Dropdown
                disabled={checkEmptySlide(slide) || isEditorBusy}
                overlayClassName="slide-enhancements-dropdown"
                trigger={['click']}
                placement="leftBottom"
                menu={{
                  className: 'slide-enhancements-menu',
                  items: slideEnhancementsMenuItems
                    .filter((item) => !Object.hasOwn(item, 'show') || item.show(slide))
                    .map((item) => {
                      if (
                        item.key !== 'add-missed-texts' &&
                        !slide.speech &&
                        !slide.story.header &&
                        !slide.story.subHeader
                      )
                        return { ...item, disabled: true }
                      return item
                    }),
                  onClick: (e) => handleSlideEnhancementsMenu(e, slide),
                }}
              >
                <Button
                  icon={<Icon name="magic_wand" />}
                  size="small"
                  className="btn-slide-option"
                  loading={isEditorBusy}
                ></Button>
              </Dropdown>
            )}
            <Button
              disabled={isEditorBusy}
              icon={<Icon name="delete" />}
              size="small"
              className="btn-slide-option delete"
              onClick={() => deleteSlide(index)}
            />
            <DurationInfo data={slide}>
              <Icon name="clock" />
              <div translate="yes">{slide.duration ? slideDurationOrApprox : `~${slideDurationOrApprox}`}s</div>
            </DurationInfo>
          </Col>
        </Row>
      </div>
    )
  },
)

export default SlideItem
