import React, { useState } from 'react'
import { Button, Col, Row, Tooltip } from 'antd'
import Icon from '../../../../../components/Icon'
import { LoadingOutlined } from '@ant-design/icons'
import Scrollbars from 'react-custom-scrollbars'

const getLayoutDescriptiveTitle = (story = {}) => {
  const { initial, isTooComplex, ...storyFields } = story

  if (isTooComplex || !Object.keys(storyFields).length) return 'Only speech text can be edited in Storyboard mode'
  const blocks = []
  if (Object.hasOwn(storyFields, 'header')) blocks.push('Heading')
  if (Object.hasOwn(storyFields, 'subHeader')) blocks.push('Subheader')
  if (Object.hasOwn(storyFields, 'list')) blocks.push('List')
  if (Object.hasOwn(storyFields, 'images')) blocks.push('Images')

  if (blocks.length === 1) return blocks[0]
  return [blocks[0], ...blocks.slice(1).map((b) => b.toLowerCase())].join(' + ')
}

const ChangeLayout = ({
  layoutsData,
  isApplyingLayout,
  selectedLayout,
  setSelectedLayout,
  setIsApplyingLayout,
  applyLayoutToSlide,
  toggleLayoutsPopover,
  layoutItemLimiterWidth,
  layoutPreviewLimiterWidth,
}) => {
  const [isOpenDefaultLayouts, setIsOpenDefaultLayouts] = useState(false)

  const { videoLayouts, defaultLayouts, thumbnails } = layoutsData

  const layouts = isOpenDefaultLayouts ? videoLayouts.concat(defaultLayouts) : videoLayouts

  const changeSlideTemplate = async () => {
    setIsApplyingLayout(true)
    await applyLayoutToSlide(selectedLayout)
    setIsApplyingLayout(false)
    toggleLayoutsPopover()
  }

  const toggleShowDefaultLayouts = () => {
    setIsOpenDefaultLayouts((prevState) => !prevState)
  }

  return (
    <>
      <div className={`confirmation-content ${selectedLayout ? 'visible' : 'hidden'}`}>
        <div
          className="slide-item-image layout-image"
          style={{
            backgroundImage: `url(${thumbnails[selectedLayout?.id]?.src || selectedLayout?.screenshot})`,
          }}
        >
          {!(thumbnails[selectedLayout?.id]?.src || selectedLayout?.screenshot) && <LoadingOutlined />}
          <div className="video-format-limiter left" style={layoutPreviewLimiterWidth} />
          <div className="video-format-limiter right" style={layoutPreviewLimiterWidth} />
        </div>
        <div className="warning-message">
          <Icon name="info" />
          <p className="warning-message-text">
            Changing the layout will not save your default design, and some content may be lost.
          </p>
        </div>
        <p className="confirmation-question-text">Do you want to proceed and change the slide layout?</p>
        <div className="confirmation-buttons-wrapper">
          <Button onClick={toggleLayoutsPopover}>Cancel</Button>
          <Button type="primary" loading={isApplyingLayout} onClick={changeSlideTemplate}>
            Change
          </Button>
        </div>
      </div>
      <Scrollbars className={`layouts-scrollbar scrollbar ${selectedLayout ? 'hidden' : 'visible'}`}>
        <Row gutter={[8, 8]}>
          {layouts.map((slide) => (
            <Col key={slide.id} span={12}>
              <Tooltip title={getLayoutDescriptiveTitle(slide.story)} placement="bottom" align={{ offset: [0, 2] }}>
                <div
                  className="slide-item-image"
                  style={{
                    backgroundImage: `url(${thumbnails[slide.id]?.src || slide.screenshot})`,
                  }}
                  onClick={() => setSelectedLayout(slide)}
                >
                  {!(thumbnails[slide.id]?.src || slide.screenshot) && <LoadingOutlined />}
                  <div className="video-format-limiter left" style={layoutItemLimiterWidth} />
                  <div className="video-format-limiter right" style={layoutItemLimiterWidth} />
                </div>
              </Tooltip>
            </Col>
          ))}
        </Row>
        <Button
          className="btn-show-more"
          type="dashed"
          icon={isOpenDefaultLayouts ? <Icon name="up_arrow" /> : <Icon name="down_arrow" />}
          block
          onClick={toggleShowDefaultLayouts}
        >
          Show {isOpenDefaultLayouts ? 'less' : 'more'}
        </Button>
      </Scrollbars>
    </>
  )
}

export default ChangeLayout
