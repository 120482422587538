import { Table, Spin, Button, Space, Popconfirm } from 'antd'

import { getColumnSearchProps } from '../../../../utils/table'
import { academyIcons } from '../constants'

export const LessonsTable = (props) => {
  const { lessons, deleteLesson, editLesson } = props

  const lessonsColumns = [
    {
      title: 'ID',
      dataIndex: '_id',
      ...getColumnSearchProps('_id'),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Order',
      dataIndex: 'order',
      width: 100,
    },
    {
      title: 'Actions',
      fixed: 'right',
      width: 90,
      render: (record) => (
        <Space>
          <Button type="ghost" icon={academyIcons.editOutlined} onClick={editLesson(record)} />
          <Popconfirm
            title="Are you sure you want to delete this lesson? This action cannot be undone."
            onConfirm={deleteLesson(record)}
            okText="Delete"
          >
            {academyIcons.delete}
          </Popconfirm>
        </Space>
      ),
    },
  ]
  return (
    <div>
      {lessons ? (
        <Table sticky columns={lessonsColumns} dataSource={lessons} rowKey="_id" pagination={false} />
      ) : (
        <Spin size="large" />
      )}
    </div>
  )
}
