import { Col, Button } from 'antd'

import Icon from './Icon'

const backIcon = <Icon name="back" />
const frontIcon = <Icon name="front" />
const forwardIcon = <Icon name="forward" />
const backwardIcon = <Icon name="backward" />

const style = {
  col: { display: 'flex', justifyContent: 'space-between' },
  toBack: { fontSize: '9px' },
  backward: { fontSize: '9px' },
  forward: { fontSize: '9px' },
  toFront: { fontSize: '9px' },
}

export const LayerControl = (props) => {
  const { setActiveObjectModifier } = props

  const handleBack = () => {
    setActiveObjectModifier({ change: 'layering', action: 'sendToBack' })
  }

  const handleBackwards = () => setActiveObjectModifier({ change: 'layering', action: 'sendBackwards' })

  const handleForward = () => setActiveObjectModifier({ change: 'layering', action: 'bringForward' })

  const handleFront = () => setActiveObjectModifier({ change: 'layering', action: 'bringToFront' })

  return (
    <Col span={18} style={style.col}>
      <Button icon={backIcon} size="small" className="btn-layering" onClick={handleBack}>
        <span style={style.toBack}>To Back</span>
      </Button>
      <Button icon={backwardIcon} size="small" className="btn-layering" onClick={handleBackwards}>
        <span style={style.backward}>Backward</span>
      </Button>
      <Button icon={forwardIcon} size="small" className="btn-layering" onClick={handleForward}>
        <span style={style.forward}>Forward</span>
      </Button>
      <Button icon={frontIcon} size="small" className="btn-layering" onClick={handleFront}>
        <span style={style.toFront}>To Front</span>
      </Button>
    </Col>
  )
}
