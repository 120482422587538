import { Scrollbars } from 'react-custom-scrollbars'

export const isPhonemeValidationError = (text) => {
  if (!text || typeof text !== 'string') {
    return false
  }
  return text.startsWith('Phoneme Validation Errors')
}

export const PhonemeValidationError = ({ text }) => {
  return (
    <Scrollbars autoHeight autoHeightMax={400}>
      <div style={{ whiteSpace: 'pre-wrap' }}>{text}</div>
    </Scrollbars>
  )
}
