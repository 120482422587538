import React from 'react'
import Icon from '../../../../../components/Icon'
import { Button } from 'antd'

const ChangePptx = ({ isStatic, isApplyingLayout, handlePptxContentChange, setCurrentView }) => {
  return (
    <>
      <div className="warning-message">
        <Icon name="info" />
        <p className="warning-message-text">
          {isStatic
            ? 'The slide may differ from the original, but you can adjust it after conversion to video.'
            : 'Reverting to an image will not save the slide content, except for speech.'}
        </p>
      </div>
      <p className="confirmation-question-text">
        {isStatic
          ? 'Do you want to proceed and convert back to dynamic slide?'
          : 'Do you want to proceed and revert to static image?'}
      </p>
      <div className="confirmation-buttons-wrapper">
        <Button type="default" onClick={() => setCurrentView('editSlide')}>
          Cancel
        </Button>
        <Button loading={isApplyingLayout} type="primary" onClick={handlePptxContentChange}>
          {isStatic ? 'Convert' : 'Revert'}
        </Button>
      </div>
    </>
  )
}

export default ChangePptx
