import { Card, Modal } from 'antd'
import { CheckOutlined } from '@ant-design/icons'
import { Scrollbars } from 'react-custom-scrollbars'

import Icon from '../../../../components/Icon'

import { style } from './constants'
import { request } from '../../../../utils/api'

const { confirm } = Modal

export const CustomMusicScrollBar = (props) => {
  const {
    musicUrl,
    pauseMusic,
    musicShift,
    stableApplyMusic,
    createAudio,
    stableDeleteMusic,
    customMusic,
    playerActivePreview,
    playingTrack,
    setCustomMusic,
    setPlayingTrack,
    handleStablePauseClick,
    musicAudio,
  } = props

  const onPlayClick = async (url) => {
    pauseMusic()
    createAudio(url, musicShift)
    setPlayingTrack(url)
    musicAudio.current.play()
  }

  const onApplyClick = (e, uMisuc) => {
    if (!e.target.matches(['svg', 'path'])) {
      if (musicUrl === uMisuc.url) {
        stableDeleteMusic()
      } else stableApplyMusic({ name: uMisuc.name, url: uMisuc.url, musicIsUploaded: true, musicShift })
    }
  }

  const handleRemove = async (file) => {
    const { used } = await request({ method: 'POST', url: '/uploads/checkUploadUsage', data: { url: file.url } })
    const retval = await new Promise((resolve, reject) => {
      confirm({
        title: used
          ? `Seems like this music is used in some of your videos. Are you sure you want to proceed? File ${file.name} will be removed from all videos in your account.`
          : `Are you sure you want to delete ${file.name}?`,
        onOk: async () => {
          const result = customMusic.uploads.filter((cMusic) => cMusic.url !== file.url)
          stableDeleteMusic()
          setCustomMusic((prevValue) => ({ ...prevValue, uploads: [...result] }))
          await request({ method: 'delete', url: `/uploads/${file._id}` })
          resolve(true)
        },
        onCancel: () => {
          reject(true)
        },
      })
    })
    return retval
  }

  return customMusic && customMusic?.uploads.length ? (
    <Scrollbars autoHeight autoHeightMin={445} autoHeightMax={`calc(100vh - ${musicUrl ? 277 : 160}px)`}>
      {customMusic.uploads.map((uMusic) => (
        <Card
          key={uMusic._id}
          size="small"
          className={`item ${musicUrl === uMusic.url ? 'active' : ''}`}
          title={
            <>
              <p>{uMusic.name}</p>
              <div className="active-icon">
                <CheckOutlined />
              </div>
            </>
          }
          extra={
            <>
              {playerActivePreview && musicUrl === uMusic.url ? null : playingTrack === uMusic.url ? (
                <Icon name="pause" onClick={handleStablePauseClick} style={style.marginRight5} />
              ) : (
                <Icon name="play" onClick={() => onPlayClick(uMusic.url)} style={style.marginRight5} />
              )}
              <span>
                <Icon name="delete" onClick={() => handleRemove(uMusic)} />
              </span>
            </>
          }
          onClick={(event) => onApplyClick(event, uMusic)}
        >
          key={uMusic.name}
        </Card>
      ))}
    </Scrollbars>
  ) : null
}
