import React from 'react'
import { Tooltip } from 'antd'
import { WarningFilled } from '@ant-design/icons'
import { useStore } from '../../../../../store'
import './interactivityWarning.less'

export const InteractivityWarning = ({ feature }) => {
  const { user } = useStore((stores) => stores.authStore)
  const isPanoptoAccount = user?.account?.source?.provider === 'panopto'

  if (!isPanoptoAccount) return null

  const messages = {
    chapters:
      'Coming Soon! Chapters and branching will be supported in Panopto in a future release. Until then, they are only available in Elai.',
    interactivity:
      'Coming Soon! Elaiʼs interactive elements will be supported in Panopto in a future release. Until then, they are only available in Elai.',
  }

  const message = messages[feature] || messages.interactivity

  const tooltipContent = (
    <div className="interactivity-warning-tooltip">
      <p>{message}</p>
    </div>
  )

  return (
    <Tooltip title={tooltipContent} color="#1E1E1E" placement="bottom" overlayClassName="interactivity-warning-overlay">
      <span className="interactivity-warning-icon">
        <WarningFilled />
      </span>
    </Tooltip>
  )
}
