import { Row, Col, Card, Button, Tooltip } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import { useState } from 'react'
// import { track } from '../../../../utils/analytics'
import { useStore } from '../../../../store'
// import arrow from '../../../../assets/images/public_custom_avatar/arrow.svg'
import PurchaseModal from '../components/purchaseModal/purchaseModal'

export default ({ avatar }) => {
  const user = useStore((stores) => stores.authStore.user)
  const [plan, setPlan] = useState('plain')
  const [isOpenPurchaseModal, setIsOpenPurchaseModal] = useState(false)

  const canBuyAvatar = !user || user?.canManageWorkspace

  return (
    <div className="purchase">
      <h2>Simple annual fee</h2>
      <Row className="desktop" gutter={[25, 25]}>
        <Col lg={12} md={12} sm={24}>
          <Card>
            <h2>Buy {avatarsData[avatar].title}</h2>
            {avatar === 'mascot' ? (
              <>
                <h3 style={{ marginTop: 20 }}>
                  ${avatarsData[avatar].price.oneTime} <span>/One-time</span>
                </h3>
                <h3>
                  ${avatarsData[avatar].price.annual} <span>/Annual</span>
                </h3>
              </>
            ) : (
              <h3 style={{ marginTop: 20 }}>
                ${avatarsData[avatar].price} <span>/Annual</span>
              </h3>
            )}
            <ul>
              {avatarsData[avatar].description.map((text, index) => (
                <li key={index}>{text}</li>
              ))}
              <li>Use your avatar with any Elai template</li>
            </ul>
            <Tooltip
              title="Please contact your account owner to perform this action."
              overlayStyle={{
                visibility: canBuyAvatar ? 'hidden' : 'visible',
              }}
            >
              <Button
                type="primary"
                disabled={!canBuyAvatar}
                icon={<RightOutlined />}
                className="btn-login btn-arrow"
                size="large"
                onClick={() => {
                  setPlan('plain')
                  setIsOpenPurchaseModal(true)
                  // track(`buy_now_${avatar === 'mascot' ? 'mascot' : `${avatar}_avatar`}`)
                }}
              >
                Buy now
              </Button>
            </Tooltip>
          </Card>
        </Col>
        <Col lg={12} md={12} sm={24}>
          {/*<div className="discount">*/}
          {/*  <div className="discount-text">Save +30%</div>*/}
          {/*  <img src={arrow} alt="arrow" />*/}
          {/*</div>*/}
          <Card className="active">
            <h2>Buy {avatarsData[avatar].title}</h2>
            <p>With Voice cloning</p>
            {avatar === 'mascot' ? (
              <>
                <h3>
                  ${avatarsData[avatar].price.oneTime} <span>/One-time</span>
                </h3>
                <h3>
                  ${avatarsData[avatar].price.annual + voiceCloningPrice} <span>/Annual</span>
                </h3>
              </>
            ) : (
              <h3>
                ${avatarsData[avatar].price + voiceCloningPrice} <span>/Annual</span>
              </h3>
            )}
            <ul>
              <li>Get a digital copy of yourself: avatar + voice</li>
              <li>Create narration with your voice in 32 different languages</li>
              <li>3 minutes of recoding in any language required</li>
            </ul>
            <Tooltip
              title="Please contact your account owner to perform this action."
              overlayStyle={{
                visibility: canBuyAvatar ? 'hidden' : 'visible',
              }}
            >
              <Button
                type="primary"
                icon={<RightOutlined />}
                className="btn-login btn-arrow"
                size="large"
                onClick={() => {
                  setPlan('with-voice')
                  setIsOpenPurchaseModal(true)
                  // track(`buy_now_${avatar === 'mascot' ? 'mascot' : `${avatar}_avatar`}_voice`)
                }}
              >
                Buy now
              </Button>
            </Tooltip>
          </Card>
        </Col>
      </Row>
      <Card className="mobile active">
        <h2>Buy {avatarsData[avatar].title}</h2>
        <p>Save +30%</p>
        <div className="plans-cards-container">
          <Card className={plan === 'plain' ? 'active' : ''} onClick={() => setPlan('plain')}>
            <h4>Basic</h4>
            {avatar === 'mascot' ? (
              <>
                <p>
                  ${avatarsData[avatar].price.oneTime} <span>/One-time</span>
                </p>
                <p>
                  ${avatarsData[avatar].price.annual} <span>/Annual</span>
                </p>
              </>
            ) : (
              <p>
                ${avatarsData[avatar].price} <span>/Annual</span>
              </p>
            )}
          </Card>
          <Card className={plan === 'with-voice' ? 'active' : ''} onClick={() => setPlan('with-voice')}>
            <h4>With Voice cloning</h4>
            {avatar === 'mascot' ? (
              <>
                <p>
                  ${avatarsData[avatar].price.oneTime} <span>/One-time</span>
                </p>
                <p>
                  ${avatarsData[avatar].price.annual + voiceCloningPrice} <span>/Annual</span>
                </p>
              </>
            ) : (
              <p>
                ${avatarsData[avatar].price + voiceCloningPrice} <span>/Annual</span>
              </p>
            )}
          </Card>
        </div>
        <ul>
          {avatarsData[avatar].description.map((text, index) => (
            <li key={index}>{text}</li>
          ))}
        </ul>
        <Tooltip
          title="Please contact your account owner to perform this action."
          overlayStyle={{
            visibility: canBuyAvatar ? 'hidden' : 'visible',
          }}
        >
          <Button
            type="primary"
            disabled={!canBuyAvatar}
            icon={<RightOutlined />}
            className="btn-login btn-arrow"
            size="large"
            onClick={() => setIsOpenPurchaseModal(true)}
          >
            Buy now
          </Button>
        </Tooltip>
      </Card>
      <PurchaseModal
        plan={plan}
        avatar={avatar}
        avatarData={avatarsData[avatar]}
        canBuy={canBuyAvatar}
        isOpenPurchaseModal={isOpenPurchaseModal}
        setIsOpenPurchaseModal={setIsOpenPurchaseModal}
      />
    </div>
  )
}

const voiceCloningPrice = 200

const avatarsData = {
  selfie: {
    title: 'selfie avatar',
    price: 199,
    description: [
      'One avatar from your smartphone or web camera',
      'Automatic background removal (some bg objects can still remain)',
      'Get avatar in 5-7 business days',
    ],
    keyPoints: [
      'We don’t modify your footage, it will be used as it is. So all the artifacts you have in your footage will be held in your avatar',
      <>
        Resubmitting the footage and re-creating an avatar will cost <b>$99 per each new footage</b> one-time fee
      </>,
      'The camera should film at least in Ultra HD (4K) and support 30FPS. Head height should be at least 800px, otherwise lipsync quality might be worse',
      'After the avatar is ready no refunds are allowed',
    ],
  },
  studio: {
    title: 'studio avatar',
    price: 500,
    description: [
      'Highest quality avatar',
      'Video post-production including color correction',
      'Get avatar in 7-10 business days',
    ],
    keyPoints: [
      'The video footage must be recorded in a studio',
      'We don’t modify your footage, it will be used as it is. We only modify your mouth according to speech. So all the artifacts you have in your footage will be held in your avatar',
      'The camera should film at least in Ultra HD (4K) and support 30FPS, better 6K or 8K. Head height should be at least 800px',
      'After the avatar is ready no refunds are allowed',
    ],
  },
  mascot: {
    title: 'cartoon mascot',
    price: { annual: 200, oneTime: 500 },
    description: [
      'Personal cartoon creation',
      '4 different animated bodies to choose from',
      'Work directly with Elai illustrator',
    ],
    keyPoints: [
      <>
        You have <b>3 iterations</b> to approve mascot illustrations and body movements
      </>,
      'The mascot head must fit our specifications. We can not animate any head',
      'Head movements might not be exactly as you expect. AI engine controls the movements and we can not always adjust it',
      'As soon as we start to work on your mascot avatar no refunds are allowed',
    ],
  },
  photo: {
    title: 'photo avatar',
    price: 259,
    description: ['Avatar from photo', 'Several styles to choose from', 'Instant result'],
  },
}
