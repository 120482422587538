import { memo } from 'react'
import { Card, Typography } from 'antd'
import { CheckOutlined } from '@ant-design/icons'

import Icon from '../../../../../components/Icon'

export const MusicItem = memo((props) => {
  const {
    name,
    file,
    genre,
    tags,
    isPlaying,
    isActive,
    musicURL,
    playerActivePreview,
    stableApplyMusic,
    stableDeleteMusic,
    onStablePlayClick,
    handleStablePauseClick,
  } = props

  return (
    <Card
      size="small"
      className={`item ${isActive ? 'active' : ''}`}
      title={
        <>
          <p>{name}</p>
          <Typography.Text ellipsis type="secondary" style={{ fontSize: 11 }}>
            <span className="genre">{genre}</span>
            {tags.map((t) => (
              <span key={t}>{t} </span>
            ))}
          </Typography.Text>
          <div className="active-icon">
            <CheckOutlined />
          </div>
        </>
      }
      extra={
        playerActivePreview && isActive ? null : isPlaying ? (
          <Icon name="pause" onClick={handleStablePauseClick} />
        ) : (
          <Icon name="play" onClick={() => onStablePlayClick(file)} />
        )
      }
      onClick={(e) => {
        if (!e.target.matches(['svg', 'path'])) {
          if (isActive) {
            stableDeleteMusic()
          } else stableApplyMusic({ name, url: musicURL + file })
        }
      }}
    />
  )
})

MusicItem.displayName = 'MusicItem'
