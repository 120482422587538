import { useRef, useEffect, useMemo, useCallback } from 'react'
import { Form, Input, Tooltip } from 'antd'
import { SPECIAL_CHARS_PHONEME_REGEX, SPECIAL_CHARS_WORD_REGEX } from '../../PhonemeModal/usePhonemeModal'

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  form,
  phonemes,
  setPhonemes,
  editingCell,
  setEditingCell,
  resetPreviewAudio,
  ...restProps
}) => {
  const inputRef = useRef(null)

  useEffect(() => {
    if (editingCell) {
      //switch to editing mode all cells when validating form to show errors
      if (editingCell === true) {
        return form.setFieldsValue({
          [`${dataIndex}_${record.key}`]: record[dataIndex],
        })
      }
      //focus on cell of newly added row
      if (editingCell.rowKey === record.key && editingCell.dataIndex === dataIndex) {
        inputRef.current.focus()
      }
    }
  }, [editingCell, record, dataIndex])

  const toggleEditCell = useCallback(() => {
    setEditingCell(true)
    form.setFieldsValue({
      [`${dataIndex}_${record.key}`]: record[dataIndex],
    })
  }, [editingCell, record, dataIndex])

  const handleSave = useCallback(
    (row) => {
      const newData = [...phonemes]
      const index = newData.findIndex((item) => row.key === item.key)
      const item = newData[index]
      newData.splice(index, 1, {
        ...item,
        ...row,
      })
      setPhonemes(newData)
    },
    [phonemes],
  )

  const saveCellData = useCallback(async () => {
    const values = form.getFieldsValue()

    const valuesToSave = {}
    for (const key in values) {
      const [dataIndex, recordKey] = key.split('_')
      if (recordKey.toString() === record.key.toString()) valuesToSave[dataIndex] = values[key]
    }

    toggleEditCell()
    handleSave({
      ...record,
      ...valuesToSave,
    })

    resetPreviewAudio()
  }, [record, toggleEditCell, handleSave, resetPreviewAudio])

  const cellChildNode = useMemo(() => {
    const rules = [
      {
        required: true,
        message: `Please input ${title?.toLowerCase()}`,
      },
    ]
    let childNode = children

    if (dataIndex === 'word')
      rules.push(() => ({
        validator(_, value) {
          const duplicatedWord = phonemes.some((ph) => ph.word === value && ph.key !== record.key)
          if (duplicatedWord) return Promise.reject(new Error('Duplicated word'))
          if (SPECIAL_CHARS_WORD_REGEX.test(value))
            return Promise.reject(new Error('Please remove special characters from word'))
          return Promise.resolve()
        },
      }))

    if (dataIndex === 'pronunciation')
      rules.push(() => ({
        validator(_, value) {
          if (SPECIAL_CHARS_PHONEME_REGEX.test(value))
            return Promise.reject(new Error('Please remove special characters, spaces and numbers from pronunciation'))
          return Promise.resolve()
        },
      }))

    if (editable) {
      childNode =
        editingCell === true || (editingCell.rowKey === record.key && editingCell.dataIndex === dataIndex) ? (
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues[`${dataIndex}_${record.key}`] !== currentValues[`${dataIndex}_${record.key}`]
            }
          >
            {({ getFieldError }) => {
              const errors = getFieldError(`${dataIndex}_${record.key}`)
              return (
                <Tooltip title={errors[0]}>
                  <Form.Item
                    style={{
                      margin: 0,
                    }}
                    name={`${dataIndex}_${record.key}`}
                    rules={rules}
                    help={false}
                  >
                    <Input
                      ref={inputRef}
                      placeholder={`Input ${title.toLowerCase()}`}
                      onPressEnter={saveCellData}
                      onBlur={saveCellData}
                      onChange={() => dataIndex === 'pronunciation' && resetPreviewAudio()}
                    />
                  </Form.Item>
                </Tooltip>
              )
            }}
          </Form.Item>
        ) : (
          <div className="editable-cell-value-wrap" onClick={toggleEditCell}>
            {children}
          </div>
        )
    }

    return childNode
  }, [children, editable, record, dataIndex, title, inputRef, editingCell, phonemes, saveCellData, toggleEditCell])

  return <td {...restProps}>{cellChildNode}</td>
}

export default EditableCell
