export const exportPanoptoCheckUnsupportedElements = (video) => {
  const playerData = video?.playerData

  if (!playerData) {
    return false
  }

  const hasInteractiveElements = playerData?.slides?.some(({ objects }) => objects?.length)
  const hasChapters = playerData?.slides?.some(({ chapterEnabled, chapterTitle }) => chapterEnabled || chapterTitle)
  const hasVttSubtitles = !!playerData?.subtitlesUrl && /.vtt$/i.test(new URL(playerData.subtitlesUrl).pathname)

  return [hasInteractiveElements, hasVttSubtitles, hasChapters].some(Boolean)
}
