import { Dropdown, Spin, Tooltip } from 'antd'

import { ToolBar } from './components/toolbar'

import { useSpeechEditorState } from './useSpeechEditorState'

import './speechEditor.less'
import { memo } from 'react'

export const SpeechEditor = memo((props) => {
  const { data, updateSlide, undoLastChanges, audioCache, resetAudio, languages, discardActiveTextObject } = props

  const {
    voice,
    speech,
    onPaste,
    handleBlur,
    textareaRef,
    handleFocus,
    insertPause,
    insertMarker,
    characterLimit,
    onClickPhoneme,
    onSpeechChange,
    wordForDictionary,
    isOpenPhonemeModal,
    setWordForDictionary,
    speechContextMenuRef,
    handleTextareaKeyDown,
    handleOpenContextMenu,
    setIsOpenPhonemeModal,
    speechContextMenuItems,
    isOpenSpeechContextMenu,
    handleSpeechContextMenuClick,
    isOpenPhonemeMenu,
    handleClickPhonemeMenu,
    isOpenPhonemeInput,
    phonemeButtonRef,
    phonemeMenuRef,
    phonemeInputRef,
    phonemeInputWrapperRef,
    onPhonemeChange,
    handleClosePhonemeInput,
    isMarkInsertAllowed,
    editingPhonemeText,
    isSpeechEditorDisabled,
  } = useSpeechEditorState({
    data,
    updateSlide,
    undoLastChanges,
    discardActiveTextObject,
  })

  const menu = {
    items: speechContextMenuItems,
    onClick: handleSpeechContextMenuClick,
  }
  const dropDownRender = (menu) => <div ref={speechContextMenuRef}>{menu}</div>

  return (
    <div className="speechWrapper">
      <Dropdown
        menu={menu}
        trigger={['contextMenu']}
        overlayClassName="speech-menu"
        open={isOpenSpeechContextMenu}
        dropdownRender={dropDownRender}
      >
        <div onContextMenu={handleOpenContextMenu}>
          <Spin spinning={isSpeechEditorDisabled}>
            <textarea
              value={speech}
              ref={textareaRef}
              onPaste={onPaste}
              onBlur={handleBlur}
              onFocus={handleFocus}
              onChange={onSpeechChange}
              className="resizableEditor"
              onKeyDown={handleTextareaKeyDown}
              placeholder="Type text that avatar will say"
            />
          </Spin>
        </div>
      </Dropdown>
      <div className="characterLimit">
        <Tooltip
          title={`${
            speech.length > characterLimit
              ? `Your text has ${speech.length} symbols out of ${characterLimit} allowed. Please reduce the number of symbols.`
              : `Your text has ${speech.length} symbols out of ${characterLimit} allowed.`
          }`}
        >
          <span style={{ color: speech.length > characterLimit ? '#cf5d60' : '#777777' }}>{speech.length}</span>
        </Tooltip>
      </div>
      <ToolBar
        voice={voice}
        data={data}
        insertPause={insertPause}
        insertMarker={insertMarker}
        onClickPhoneme={onClickPhoneme}
        isOpenPhonemeMenu={isOpenPhonemeMenu}
        handleClickPhonemeMenu={handleClickPhonemeMenu}
        isOpenPhonemeInput={isOpenPhonemeInput}
        phonemeButtonRef={phonemeButtonRef}
        phonemeMenuRef={phonemeMenuRef}
        phonemeInputRef={phonemeInputRef}
        phonemeInputWrapperRef={phonemeInputWrapperRef}
        onPhonemeChange={onPhonemeChange}
        handleClosePhonemeInput={handleClosePhonemeInput}
        editingPhonemeText={editingPhonemeText}
        wordForDictionary={wordForDictionary}
        isOpenPhonemeModal={isOpenPhonemeModal}
        setWordForDictionary={setWordForDictionary}
        setIsOpenPhonemeModal={setIsOpenPhonemeModal}
        audioCache={audioCache}
        resetAudio={resetAudio}
        languages={languages}
        isMarkInsertAllowed={isMarkInsertAllowed}
      />
    </div>
  )
})
