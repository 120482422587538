import { Modal, Button, Alert } from 'antd'
import { useEffect, useState, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import Icon from '../../../../components/Icon'
import onboarding_image from '../../../../assets/images/onboarding-image.jpg'
import { track } from '../../../../utils/analytics'
import './onboardingModal.less'

export const OnboardingModal = () => {
  const location = useLocation()
  const videoRef = useRef(null)
  const [isOpenOnboardingModal, setIsOpenOnboardingModal] = useState(false)
  const [isVisiblePlayButton, setIsVisiblePlayButton] = useState(true)

  const handleCancel = () => setIsOpenOnboardingModal(false)

  const onClickPlayButton = () => {
    setIsVisiblePlayButton(false)
    videoRef.current.play()
    track('onboarding_video_clicked')
  }

  useEffect(() => {
    if (location.state?.showOnboardingModal) {
      setIsOpenOnboardingModal(true)
    }
  }, [location.state?.showOnboardingModal])

  return (
    <Modal
      open={isOpenOnboardingModal}
      closeIcon={<Icon name="close_simple" />}
      className="onboarding-modal"
      onCancel={handleCancel}
      centered
      destroyOnClose
      footer={[
        <div className="onboarding-modal-footer">
          {location.state?.isNewPanoptoUser ? (
            <Alert
              description={
                <span>
                  <b>Please note:</b> Interactive elements and chapters are not currently supported in Panopto. Support
                  for these will be added in a future release.
                </span>
              }
              type="warning"
              showIcon
              icon={<Icon name="info" />}
            />
          ) : (
            <span></span>
          )}
          <Button key="skip" className="btn-skip" type="link" onClick={handleCancel}>
            Skip for now <Icon name="right_arrow" />
          </Button>
        </div>,
      ]}
    >
      <h2 className="onboarding-modal-title">Welcome to Elai!</h2>
      <p className="onboarding-modal-subtitle">
        👋 I'm Gia, your AI video presenter. Create your first AI video today! You can follow a quick guide in the
        video, or start creating right away.{' '}
        {location.state?.isNewPanoptoUser ? (
          <b>
            <a
              href="https://support.panopto.com/s/article/How-to-Use-Elai-with-Panopto"
              target="_blank"
              rel="noreferrer"
            >
              Learn more
            </a>
          </b>
        ) : (
          ''
        )}
      </p>
      <div className={`onboarding-image-container ${isVisiblePlayButton ? '' : 'hidden'}`}>
        <Button
          className="btn-play-circle"
          size="large"
          shape="circle"
          icon={<Icon name="play_filled" />}
          onClick={onClickPlayButton}
        />
        <img className="onboarding-image" src={onboarding_image} alt="Onboarding video thumbnail" />
      </div>
      <div className={`onboarding-video-container ${isVisiblePlayButton ? 'hidden' : ''}`}>
        <video
          ref={videoRef}
          className="onboarding-video"
          src={
            location.state?.isNewPanoptoUser
              ? 'https://d3u63mhbhkevz8.cloudfront.net/assets/videos/onboarding-tour-for-panopto-users.mp4'
              : 'https://d3u63mhbhkevz8.cloudfront.net/assets/videos/onboarding-tour-new.mp4'
          }
          controls
          controlsList="nodownload noremoteplayback"
        />
      </div>
    </Modal>
  )
}
