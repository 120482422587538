import { useState, useRef, useEffect, useCallback } from 'react'
import { InputNumber } from 'antd'

import { ANIMATION_TYPES } from './constants'

const TEMP_LOCAL_VALUES = ['-', '']
const isTempLocalValue = (v) => TEMP_LOCAL_VALUES.includes(v)

export const AnimationsInputNumber = (props) => {
  const { type, activeObjectProps, onInputAnimationTimeChange, onChangeTime } = props

  const value = activeObjectProps.animation?.[type === ANIMATION_TYPES.enter ? 'startTime' : 'endTime']

  const [localValue, setLocalValue] = useState(value)
  const syncValue = useCallback(() => {
    setLocalValue(value)
  }, [value])

  const prevValueRef = useRef()
  const prevValue = prevValueRef.current
  useEffect(() => {
    prevValueRef.current = value
  }, [value])

  useEffect(() => {
    if (prevValue !== value) {
      syncValue()
    }
  }, [value, prevValue, syncValue])

  const handleStep = (v) => {
    const numberValue = Number(v)
    onInputAnimationTimeChange(numberValue, type)
    setLocalValue(numberValue)
  }

  const handleInput = (v) => {
    if (isTempLocalValue(v)) {
      setLocalValue(v)
      return
    }

    const numberValue = Number(v)

    if (Number.isNaN(numberValue)) {
      return
    }

    onChangeTime(numberValue, type)
    setLocalValue(numberValue)
  }

  const handlePressEnter = (e) => {
    e.stopPropagation()
    syncValue()
  }

  const handleBlur = (e) => {
    syncValue()
  }

  return (
    <InputNumber
      {...(type === ANIMATION_TYPES.enter ? { min: 0 } : {})}
      style={{ width: 80 }}
      value={localValue}
      formatter={(value) => (isTempLocalValue(value) ? value : Math.round(value * 10) / 10)}
      onStep={handleStep}
      onInput={handleInput}
      onPressEnter={handlePressEnter}
      onBlur={handleBlur}
    />
  )
}
