export function getRotatedBounds(width, height, angle) {
  if (!width || !height) {
    return null
  }
  // Convert the angle from degrees to radians
  const rad = (angle * Math.PI) / 180

  // Calculate cosine and sine of the angle
  const cos = Math.cos(rad)
  const sin = Math.sin(rad)

  // Determine the coordinates of the 4 corners of the original rectangle after rotation around (0,0)
  const p1 = { x: 0, y: 0 } // top-left corner (remains unchanged)
  const p2 = { x: width * cos, y: width * sin } // top-right corner
  const p3 = { x: -height * sin, y: height * cos } // bottom-left corner
  const p4 = {
    x: width * cos - height * sin,
    y: width * sin + height * cos,
  } // bottom-right corner

  // Arrays of x and y coordinates
  const xs = [p1.x, p2.x, p3.x, p4.x]
  const ys = [p1.y, p2.y, p3.y, p4.y]

  // Determine the boundaries of the bounding box
  const minX = Math.min(...xs)
  const maxX = Math.max(...xs)
  const minY = Math.min(...ys)
  const maxY = Math.max(...ys)

  const rotatedWidth = maxX - minX
  const rotatedHeight = maxY - minY

  // Calculate offsets relative to the original top-left corner (0,0)
  const offsetTop = -minY // if minY is negative, offsetTop will be positive
  const offsetLeft = -minX // if minX is negative, offsetLeft will be positive

  return {
    width: rotatedWidth,
    height: rotatedHeight,
    offsetTop: offsetTop,
    offsetLeft: offsetLeft,
  }
}
