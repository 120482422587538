import { Modal, Input, notification } from 'antd'

export const SlideCountModal = ({
  customPromptModal,
  setCustomPromptModal,
  gptLoading,
  setGptLoading,
  applySlideEnhancement,
}) => {
  const handleConfirmCustomPromptModal = async () => {
    if (!customPromptModal.prompt) return notification.warning({ message: 'Prompt is required' })
    setGptLoading({
      message: 'Applying Prompt',
      isLoading: true,
      slide: customPromptModal.slideId,
    })
    const enhancedVideo = await applySlideEnhancement(customPromptModal.slideId, 'custom-prompt', {
      prompt: customPromptModal.prompt,
    })
    setGptLoading({ isLoading: false })
    if (enhancedVideo) setCustomPromptModal(false)
  }

  return (
    <Modal
      centered
      open={customPromptModal?.slideId}
      width={400}
      className="slide-count-modal"
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'flex', margin: '60px auto 20px' } }}
      okText="Apply"
      confirmLoading={gptLoading.isLoading}
      onOk={handleConfirmCustomPromptModal}
      onCancel={() => setCustomPromptModal(false)}
    >
      <h2>Apply prompt to slide's speech text</h2>
      <Input
        value={customPromptModal.prompt}
        onChange={(e) => setCustomPromptModal({ ...customPromptModal, prompt: e.target.value })}
        placeholder="Eg: Highlight benefits of our company"
        onPressEnter={handleConfirmCustomPromptModal}
      />
    </Modal>
  )
}
