import { memo } from 'react'

import Icon from '../../../components/Icon'

import { ExportPanoptoModalWarningMessage } from './exportPanoptoModalWarningMessage'
import { ExportPanoptoModalFolderSelector } from './exportPanoptoModalFolderSelector'

import { STYLE } from '../constants'

const LOCAL_STYLE = {
  selectorWrapper: {
    marginBottom: '12px',
  },
}

export const ExportPanoptoModalContent = memo(({ hasUnsupportedElements, folder, setFolder }) => {
  return (
    <>
      <p>
        <Icon style={STYLE.icon} name="panopto_logo" />
      </p>

      <h1>Export your video to Panopto</h1>

      <p>
        <span>Your video will be uploaded to Panopto. Please select a folder to store the video in Panopto.</span>{' '}
        <a target="_blank" href="https://support.panopto.com/s/article/Folder-Management" rel="noreferrer">
          Learn more
        </a>
      </p>

      <div style={LOCAL_STYLE.selectorWrapper}>
        <ExportPanoptoModalFolderSelector value={folder} onChange={setFolder} />
      </div>

      {hasUnsupportedElements && (
        <ExportPanoptoModalWarningMessage message="Interactive elements, chapter navigation, and branching will be supported in Panopto as part of a future release. Until then, these elements will not function as expected in Panopto." />
      )}
    </>
  )
})
