import { debounce } from 'throttle-debounce'
import { useCallback, useEffect, useRef, useState } from 'react'

import music from '../../../../data/music'

import { musicURL } from './constants'
import { useStore } from '../../../../store'
import { request } from '../../../../utils/api'
import { useElaiNotification } from '../../../../hooks/useElaiNotification'
import { useStableCallback } from '../../../../hooks/useStableCallback'

const defaultVolume = 0.18

export const useMusicTabState = (props) => {
  const {
    videoRef,
    videoDataMusicShift,
    videoDataMusicEndShift,
    time,
    duration,
    stopVideo,
    stableUpdateVideo,
    playerActivePreview,
    playerCurrentTime,
  } = props

  const notification = useElaiNotification()
  const [musicData, setMusicData] = useState(music)
  const [userMusic, setUserMusic] = useState()
  const [validateShift, setValidateShift] = useState(false)
  const [validateEndShift, setValidateEndShift] = useState()
  const [musicFilters, setMusicFilters] = useState({ genre: false, tags: [] })
  const [playingTrack, setPlayingTrack] = useState('idle')
  const [isMusicUploading, setIsMusicUploading] = useState(false)
  const [shift, setShift] = useState(videoDataMusicShift || 0)
  const [endShift, setEndShift] = useState(videoDataMusicEndShift || 0)

  const userPlan = useStore((stores) => stores.authStore.user.account.plan)
  const isUploadAvailable = !['trial', 'basic'].includes(userPlan)

  const [musicTab, setMusicTab] = useState(() =>
    videoRef.current?.data.musicIsUploaded && isUploadAvailable ? 'upload' : 'tracks',
  )

  const musicAudio = useRef(false)
  const shiftTimeout = useRef(false)
  const shiftPromiseReject = useRef()

  const playMusic = async () => {
    const audio = musicAudio.current
    audio.pause()
    if (shiftTimeout.current) clearTimeout(shiftTimeout.current)
    shiftPromiseReject.current?.()
    audio.currentTime = 0
    // totalDuration = 0
    if (shift < 0) {
      await new Promise((resolve) => audio.addEventListener('canplay', resolve, { once: true }))
      audio.currentTime = Math.abs(shift)
    }
  }

  const debounceVideo = useCallback(
    debounce(800, (data) => stableUpdateVideo({ data })),
    [],
  )

  const createAudio = (url, musicShift = 0) => {
    const audio = new Audio(url)
    audio.volume = videoRef.current?.data?.musicVolume || defaultVolume
    audio.loop = true
    musicAudio.current?.pause?.()
    musicAudio.current = audio
    const newShift = videoRef.current?.data?.musicShift ?? musicShift
    if (shift === newShift || !newShift) playMusic()
    else setShift(newShift)
  }

  const pauseMusic = () => {
    if (musicAudio.current) musicAudio.current.pause()
    if (shiftTimeout.current) clearTimeout(shiftTimeout.current)
    shiftPromiseReject.current?.()
    setPlayingTrack('idle')
  }

  const stableApplyMusic = useStableCallback(({ name, url, musicIsUploaded }) => {
    if (playerActivePreview) {
      stopVideo()
    }
    pauseMusic()
    if (url === videoRef.current?.data.musicUrl) return
    stableUpdateVideo({
      data: {
        ...videoRef.current?.data,
        musicName: name,
        musicUrl: url,
        musicVolume: videoRef.current?.data?.musicVolume || defaultVolume,
        musicIsUploaded,
        shift,
      },
    })
  })

  const stableDeleteMusic = useStableCallback(() => {
    if (playerActivePreview) {
      stopVideo()
    }
    pauseMusic()
    musicAudio.current = null
    const newData = { ...videoRef.current?.data }
    delete newData.musicName
    delete newData.musicUrl
    newData.musicIsUploaded = false
    stableUpdateVideo({ data: newData })
  })

  const uploadMusic = (info) => {
    const { status, error, response } = info.file
    setIsMusicUploading(status === 'uploading')
    if (status === 'done') {
      const newAudio = {
        _id: response._id,
        name: response.name,
        url: response.url,
      }
      setUserMusic((prevValue) => ({
        ...prevValue,
        uploads: [...prevValue.uploads.filter((uMusic) => uMusic._id !== newAudio._id), newAudio],
      }))
    } else if (status === 'error') {
      notification.error({ message: error.toString() })
    }
  }

  const beforeUpload = (file) => {
    if (file.size / 1024 / 1024 > 20) {
      notification.error({
        message: 'File must be smaller than 20MB',
      })
      return false
    }
    return true
  }

  const handleChangeMusicTab = (tab) => setMusicTab(tab)

  useEffect(() => {
    if (musicAudio.current) {
      musicAudio.current.currentTime = 0
    }
    if (playerActivePreview) {
      if (playingTrack) {
        setPlayingTrack(null)
        pauseMusic()
      }
      if (videoRef.current?.data?.musicUrl) {
        createAudio(videoRef.current.data.musicUrl)
        setPlayingTrack(videoRef.current.data.musicUrl)
      }
    } else if (playingTrack === videoRef.current?.data?.musicUrl) {
      setPlayingTrack(null)
      pauseMusic()
    }
  }, [playerActivePreview])

  useEffect(() => {
    if (shift !== undefined && playingTrack && playingTrack !== 'idle') {
      playMusic()
    }
  }, [shift])

  useEffect(() => {
    if (shift === undefined && videoDataMusicShift) setShift(videoDataMusicShift)
  }, [videoDataMusicShift])

  useEffect(() => {
    if (shift === undefined && videoDataMusicEndShift) setEndShift(videoDataMusicEndShift)
  }, [videoDataMusicEndShift])

  useEffect(() => {
    fetchUploads()
    return () => pauseMusic()
  }, [])

  useEffect(() => {
    let filteredMusic = [...music]
    if (musicFilters.genre) filteredMusic = filteredMusic.filter((m) => m.genre === musicFilters.genre)
    if (musicFilters.tags.length)
      filteredMusic = filteredMusic.filter((m) => m.tags.some((t) => musicFilters.tags.includes(t)))
    if (
      !musicFilters.genre &&
      !musicFilters.tags.length &&
      videoRef.current?.data?.musicName &&
      !videoRef.current?.data.musicIsUploaded
    ) {
      filteredMusic.sort((a, b) => {
        if (a.name === videoRef.current?.data.musicName) return -1
        if (b.name === videoRef.current?.data.musicName) return 1
        return 0
      })
    }
    setMusicData(filteredMusic)
  }, [musicFilters])

  /**
   * Play audio if exists and check on every tick
   */
  useEffect(() => {
    // audio is selected and active preview
    if (musicAudio.current && playerActivePreview) {
      const audio = musicAudio.current
      // stop music on video search
      if (time < shift) {
        audio.pause()
      } else if (shift > 0) {
        // if video reach shift play music
        if (time >= shift) {
          audio?.play()
        }
        // play music no matter what ( shift 0 for example)
      } else {
        audio?.play()
      }
      if (endShift > 0) {
        // seems player have right duration of full video, so we can calculate it
        const stopMusicAt = duration - endShift
        if (time >= stopMusicAt) {
          audio?.pause()
        }
      }
    }
  }, [playerActivePreview, playerCurrentTime])

  const handleStablePauseClick = useStableCallback(() => {
    setPlayingTrack(null)
    pauseMusic()
  })
  const handleDraggerClick = () => {
    pauseMusic()
    stopVideo()
  }

  const fetchUploads = useCallback(async () => {
    const data = await request({
      method: 'get',
      url: `/uploads/music`,
    })
    setUserMusic(data)
  }, [])

  const onStablePlayClick = useStableCallback((file) => {
    if (playerActivePreview) stopVideo()
    pauseMusic()
    createAudio(
      musicURL + file,
      videoRef.current?.data && videoRef.current?.data.musicUrl === musicURL + file ? shift : shift,
    )
    setPlayingTrack(musicURL + file)
    musicAudio.current.play()
  })

  const handleShiftInput = (v) => {
    const audioEnd = duration - endShift

    if (v > 0) {
      if (endShift > 0 && audioEnd > 0 && v > audioEnd - 1) {
        setValidateShift(true)
      } else if (v > duration - 1) {
        setValidateShift(true)
      } else {
        setValidateShift(false)
      }
    } else {
      setValidateShift(false)
    }
    debounceVideo({ ...videoRef.current?.data, musicShift: v })
    setShift(v)
  }

  const handleEndShiftInput = (v) => {
    const audioEnd = duration - v

    if (v > 0) {
      if (audioEnd < 0) {
        setValidateEndShift(true)
      } else if (shift <= 0 && audioEnd > duration - 1) {
        setValidateEndShift(true)
      } else if (shift > 0 && audioEnd < shift - 1) {
        setValidateEndShift(true)
      } else {
        setValidateEndShift(false)
      }
    } else {
      setValidateEndShift(false)
    }
    debounceVideo({ ...videoRef.current?.data, musicEndShift: v })
    setEndShift(v)
  }

  return {
    shift,
    musicTab,
    endShift,
    userMusic,
    musicData,
    musicAudio,
    stableApplyMusic,
    pauseMusic,
    stableDeleteMusic,
    uploadMusic,
    onStablePlayClick,
    createAudio,
    validateShift,
    setUserMusic,
    beforeUpload,
    musicFilters,
    playingTrack,
    setMusicFilters,
    setPlayingTrack,
    validateEndShift,
    isMusicUploading,
    handleShiftInput,
    handleStablePauseClick,
    isUploadAvailable,
    handleDraggerClick,
    handleEndShiftInput,
    handleChangeMusicTab,
  }
}
