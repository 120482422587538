import { useEffect, useState } from 'react'
import fabric from '../../utils/fabric/fabric'
import { createThumbnailFromFabricCanvas } from '../video/useSlidesThumbnails'
import { CANVAS_HEIGHT, CANVAS_WIDTH } from '../video/slide/canvas/constans'
import { defaultTemplateId } from '../../utils/constants'
import { request } from '../../utils/api'
import { uniqueId } from '../../utils/helpers'
import { ifLayoutsSimilar } from '../../utils/videoStory/helpers'

const createCanvasForLayout = async (layout) => {
  const canvas = new fabric.Canvas('canvas-' + layout.id, {
    height: Math.round(CANVAS_HEIGHT),
    width: Math.round(CANVAS_WIDTH),
    preserveObjectStacking: true,
  })
  await canvas.loadFromJSON(layout)
  return canvas
}

const filterDuplicatedLayouts = (layouts) => {
  let i = 0
  while (i < layouts.length) {
    const slide = layouts[i]
    let j = i + 1
    while (j < layouts.length) {
      const slide2 = layouts[j]
      if (ifLayoutsSimilar(slide, slide2)) layouts.splice(j, 1)
      else j++
    }
    i++
  }
  return layouts
}

export const useLayoutsData = ({ video, thumbnails }) => {
  const [layoutsData, setLayoutsData] = useState({ videoLayouts: [], defaultLayouts: [], thumbnails })
  const [defaultTemplate, setDefaultTemplate] = useState(null)
  const [extraLayoutThumbnails, setExtraLayoutThumbnails] = useState(null)

  const fetchDefaultTemplate = async () => {
    const template = await request({ method: 'GET', url: `templates/${defaultTemplateId}` })
    setDefaultTemplate(template)
  }

  useEffect(() => {
    fetchDefaultTemplate()
  }, [])

  useEffect(() => {
    if (!video || !defaultTemplate) return

    for (const layout of video.extraLayouts) {
      createCanvasForLayout(layout).then((canvas) => {
        setExtraLayoutThumbnails((thumbnails) => ({
          ...thumbnails,
          [layout.id]: createThumbnailFromFabricCanvas(canvas),
        }))
      })
    }

    const defaultLayouts = defaultTemplate.slides.map((slide) => ({
      ...slide.canvas,
      id: uniqueId(),
      screenshot: slide.screenshot,
      avatar: slide.avatar,
      story: slide.story,
    }))

    const layoutsFromSlides = video.slides.map(({ id, canvas, story, avatar }) => ({
      ...canvas,
      id,
      story,
      avatar,
    }))

    const videoLayouts = filterDuplicatedLayouts(layoutsFromSlides.concat(video.extraLayouts ?? []))

    setLayoutsData((layoutsData) => ({ ...layoutsData, videoLayouts, defaultLayouts }))
  }, [video?.extraLayouts, video?.slides, defaultTemplate])

  useEffect(() => {
    setLayoutsData((layoutsData) => ({ ...layoutsData, thumbnails: { ...thumbnails, ...extraLayoutThumbnails } }))
  }, [thumbnails, extraLayoutThumbnails])

  return layoutsData
}
