export const osMap = { WINDOWS: 'Windows', MAC: 'Mac', LINUX: 'Linux' }
export const ACCOUNT_PLAN = { BASIC: 'basic', ADVANCED: 'advanced', UNLIMITED: 'unlimited', CUSTOM: 'custom' }
export const defaultTemplateId = '637b5c563923fa041a039421'
export const defaultBlankVideoName = 'Blank video'
export const maxVideoNameLength = 100
export const ONBOARDING_TEMPLATE_ID = '665d6aff1e58445a13d84072'

export const tutorialVideos = {
  interactiveVideos: {
    maxresThumbnail: 'https://img.youtube.com/vi/BqoCl4rU3ao/maxresdefault.jpg',
    thumbnail: 'https://img.youtube.com/vi/BqoCl4rU3ao/mqdefault.jpg',
    url: 'https://www.youtube.com/watch?v=BqoCl4rU3ao',
    name: 'Interactive videos',
  },
  foldersAndTags: {
    thumbnail: 'https://img.youtube.com/vi/JR94Xv6cMXY/mqdefault.jpg',
    url: 'https://www.youtube.com/watch?v=JR94Xv6cMXY',
    name: 'Folders and tags',
  },
  createTemplate: {
    thumbnail: 'https://img.youtube.com/vi/IEFl2CIzAL4/mqdefault.jpg',
    url: 'https://www.youtube.com/watch?v=IEFl2CIzAL4',
    name: 'Create template',
  },
  inviteTeammate: {
    thumbnail: 'https://img.youtube.com/vi/oJitx5zO6Ss/mqdefault.jpg',
    url: 'https://www.youtube.com/watch?v=oJitx5zO6Ss',
    name: 'Invite teammate',
  },
  assignRoles: {
    thumbnail: 'https://img.youtube.com/vi/oaRg-jaCDjk/mqdefault.jpg',
    url: 'https://www.youtube.com/watch?v=oaRg-jaCDjk',
    name: 'Assign roles',
  },
  createAccount: {
    thumbnail: 'https://img.youtube.com/vi/RDlUs5PjqlE/mqdefault.jpg',
    url: 'https://www.youtube.com/watch?v=RDlUs5PjqlE',
    name: 'Create account',
  },
  deleteAccount: {
    thumbnail: 'https://img.youtube.com/vi/YGlPkii2OsU/mqdefault.jpg',
    url: 'https://www.youtube.com/watch?v=YGlPkii2OsU',
    name: 'Delete account',
  },
}
