import React, { useState, useCallback } from 'react'
import { Dropdown, Button } from 'antd'

import Icon from '../../../../../../components/Icon'

import { ASSETS_TYPES, assetsTypesMenuItems } from '../../constants'

export const FileUploaderTypeSelect = ({ value, disabled, onChange }) => {
  const [open, setOpen] = useState(false)

  const handleChange = useCallback(
    ({ key }) => {
      if (key !== value) {
        onChange(key)
      }
      setOpen(false)
    },
    [value, onChange],
  )

  return (
    <Dropdown
      key="type-select-dropdown"
      menu={{
        items: assetsTypesMenuItems,
        selectedKeys: [value || ASSETS_TYPES.ALL],
        onClick: handleChange,
      }}
      trigger={['click']}
      open={open}
      onOpenChange={setOpen}
      disabled={disabled}
    >
      <Button
        type={open ? 'primary' : 'default'}
        icon={<Icon name="filters" />}
        className="uploads-media-header-button"
      />
    </Dropdown>
  )
}
