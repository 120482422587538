import { memo } from 'react'
import { Col } from 'antd'
import SlidesCarousel from '../../../../../components/SlidesCarousel'

export const TemplateItem = memo((props) => {
  const { video, index, setVisible } = props

  const onTemplateClick = (v) => {
    setVisible({ isOpen: true, video })
  }

  return (
    <Col span={12}>
      <div
        className={`template-container ${
          video.data?.format === '9_16' ? 'vertical' : video.data?.format === '1_1' ? 'square' : ''
        }`}
      >
        <SlidesCarousel
          collection={{
            ...video,
            items: video.slides.map((slide) => ({ ...slide, src: slide.screenshot })),
          }}
          index={index}
          onCarouselClick={onTemplateClick}
        />
      </div>
    </Col>
  )
})

TemplateItem.displayName = 'TemplateItem'
