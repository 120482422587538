import { useCallback, useEffect, useState, useMemo, useRef } from 'react'
import { Tooltip } from 'antd'
import { EventEmitter } from 'events'
import * as storage from 'idb-keyval'
import { sleep, updateVideoVersionInCache } from '../../utils/helpers'
import { THUMBNAIL_MODES } from './constants'
import configService from '../../utils/config'
import Icon from '../../components/Icon'

const THUMBNAIL_PENDING_CACHE_KEY = 'thumbnail-pending-cache'
const CONTROLS_VISIBILITY_DELAY_MS = 10

const thumbnailCacheEnabled = window.indexedDB !== undefined

const thumbnailsRefUpdateEmitter = new EventEmitter()
thumbnailsRefUpdateEmitter.setMaxListeners(200)

const THUMBNAILS_REF_UPDATE_EVENT_NAME = 'thumbnails_ref_update'

const noop = () => {}

const createEmptyElement = () => {
  const element = document.createElement('div')
  element.classList.add('item-thumbnail-empty')
  return element
}

const getStorageKey = (videoId, slideId) => `canvas-thumbnail-${videoId}-${slideId}`

const createImage = (srcBase64) => {
  const img = new Image()
  img.src = srcBase64
  return img
}

const createSafariVideoErrorThumbnail = () => ({
  reactNode: (
    <Tooltip title="Slide thumbnails may not appear in Safari but video playback will work fine. For best results, please use a different browser.">
      <div className="item-thumbnail-error">
        <div>
          <p style={{ textAlign: 'center' }}>
            <Icon name="image" style={{ fontSize: '20px', textAlign: 'center' }} />
          </p>
          <span>Thumbnail not available</span>
        </div>
      </div>
    </Tooltip>
  ),
})

const updatedThumbnails = []

export const createThumbnailFromFabricCanvas = (canvas, { cacheKey, forceCache } = {}) => {
  try {
    const imgSrc = canvas.toDataURL({
      format: 'jpeg',
      quality: 0.8,
    })
    if (!cacheKey) return createImage(imgSrc)

    if (thumbnailCacheEnabled) {
      if (forceCache) storage.set(cacheKey, imgSrc).catch(noop)
      else {
        updatedThumbnails.push({ cacheKey, imgSrc })

        const pendingCache = localStorage.getItem(THUMBNAIL_PENDING_CACHE_KEY) || ''
        localStorage.setItem(THUMBNAIL_PENDING_CACHE_KEY, pendingCache + (pendingCache ? ';' : '') + cacheKey)
      }
    }
    return createImage(imgSrc)
  } catch (e) {
    if (cacheKey) {
      storage.del(cacheKey).catch(noop)
    }
    const isSafariVideoError = e.name === 'SecurityError'
    return isSafariVideoError ? createSafariVideoErrorThumbnail() : createEmptyElement()
  }
}

const dropThumbnailCache = async (video) => {
  if (!thumbnailCacheEnabled) return
  if (video?.slides?.length) await storage.delMany(video.slides.map((slide) => getStorageKey(video._id, slide.id)))
  else await storage.clear()
}

const flushThumbnailsCache = (video) => {
  if (!video || !thumbnailCacheEnabled) return
  updateVideoVersionInCache(video)
  updatedThumbnails.forEach(({ cacheKey, imgSrc }) => storage.set(cacheKey, imgSrc).catch(noop))
  updatedThumbnails.length = 0
  localStorage.removeItem(THUMBNAIL_PENDING_CACHE_KEY)
}

const isImageUrl = (url) => {
  const cleanUrl = url.split('?')[0].split('#')[0]
  const extension = cleanUrl.split('.').pop().toLowerCase()

  return ['jpg', 'jpeg', 'png', 'webp'].includes(extension)
}

const optimizeCanvasThumbnailsObjectsData = (objects) => {
  if (!objects) {
    return objects
  }
  return objects.map((obj) => {
    if (obj.type === 'video' && obj.thumbnail && isImageUrl(obj.thumbnail)) {
      return { ...obj, type: 'image', src: obj.thumbnail }
    }

    if (obj.type === 'group') {
      return { ...obj, objects: optimizeCanvasThumbnailsObjectsData(obj.objects) }
    }

    return obj
  })
}

export const useSlidesThumbnails = ({
  calculateZoom,
  canvasRegistry,
  createBaseCanvas,
  disposeCanvas,
  handleCanvasReady,
  ready,
  showControls,
  video,
  videoRef,
}) => {
  const thumbnailsRef = useRef({})

  const checkThumbnailsReady = useCallback(
    (video) => !!video && Object.keys(thumbnailsRef.current).length === video?.slides?.length,
    [],
  )

  // Updates the thumbnails reference and emits an event
  // Do not update thumbnails reference directly, use this function instead
  const setThumbnailsRef = useCallback(
    (valueOrUpdater) => {
      const prevValue = thumbnailsRef.current

      // Works the same way as setState callback
      const newValue = typeof valueOrUpdater === 'function' ? valueOrUpdater(prevValue) : valueOrUpdater

      thumbnailsRef.current = newValue

      setThumbnailsReady(checkThumbnailsReady(videoRef?.current))

      thumbnailsRefUpdateEmitter.emit(THUMBNAILS_REF_UPDATE_EVENT_NAME)
    },
    [checkThumbnailsReady, videoRef],
  )

  const [thumbnailsReady, setThumbnailsReady] = useState(() => checkThumbnailsReady(video))
  const [templateThumbnails, setTemplateThumbnails] = useState({})

  const { isTesting } = configService.get().env

  useEffect(() => {
    setThumbnailsReady(checkThumbnailsReady(video))
  }, [video?.slides])

  const templateThumbnailsReady = useMemo(
    () => video && Object.keys(templateThumbnails).length === video.slides.length,
    [templateThumbnails, video?.slides],
  )

  const syncThumbnailsWithSlides = () => {
    const updatedThumbnails = Object.fromEntries(
      Object.entries(thumbnailsRef?.current).filter(([slideId]) =>
        videoRef.current.slides.find((s) => s.id === Number(slideId)),
      ),
    )
    if (Object.keys(updatedThumbnails).length !== Object.keys(thumbnailsRef?.current)) {
      setThumbnailsRef(updatedThumbnails)
    }
  }

  const createOptimizedThumbnailAsync = async (slide) => {
    if (!slide.canvas) return

    const zoom = calculateZoom()
    const canvas = createBaseCanvas({ element: `canvas-${slide.id}-temp-thumbnail`, zoom })
    canvas.setZoom(zoom)
    canvas.wrapperEl.style.display = 'none'

    const canvasJSON = {
      ...slide.canvas,
      objects: optimizeCanvasThumbnailsObjectsData(slide.canvas.objects),
    }

    await canvas.loadFromJSON(canvasJSON, handleCanvasReady)
    await updateThumbnail(slide, { canvas, showControlsWithDelay: false, forceCache: !ready })

    disposeCanvas(canvas)
  }

  const updateThumbnail = async (
    slide,
    { canvas, showControlsWithDelay, forceCache, thumbnailMode = THUMBNAIL_MODES.REGULAR } = {},
  ) => {
    canvas = canvas || canvasRegistry.current[slide.id]

    if (!canvas?.lowerCanvasEl || !canvas.getObjects().length) {
      return
    }

    if (canvas.activeLoading) {
      await canvas.activeLoading
    }

    showControls(canvas, false, thumbnailMode)

    const thumbnailCanvas = createThumbnailFromFabricCanvas(canvas, {
      cacheKey: getStorageKey(video._id, slide.id),
      forceCache,
    })

    const thumbnailsSetter = thumbnailMode === THUMBNAIL_MODES.REGULAR ? setThumbnailsRef : setTemplateThumbnails
    thumbnailsSetter((t) => ({
      ...t,
      [slide.id]: thumbnailCanvas,
    }))

    if (showControlsWithDelay) await sleep(CONTROLS_VISIBILITY_DELAY_MS)
    showControls(canvas, true, thumbnailMode)
  }

  const updateThumbnailImmediately = (slide) =>
    updateThumbnail(slide, { showControlsWithDelay: false, forceCache: true })

  const deleteThumbnailsCache = () => {
    if (!ready && thumbnailCacheEnabled) {
      const pendingCache = localStorage.getItem(THUMBNAIL_PENDING_CACHE_KEY)
      if (pendingCache) {
        storage.delMany(pendingCache.split(';'))
        localStorage.removeItem(THUMBNAIL_PENDING_CACHE_KEY)
      }
    }
  }

  useEffect(() => {
    if (isTesting && thumbnailsReady) localStorage.setItem('__thumbnailsReady', 'true')
  }, [thumbnailsReady])

  const clearTemplateThumbnails = () => {
    Object.values(templateThumbnails).forEach((thumbnail) => {
      if (thumbnail.src) thumbnail.src = '' // clear src to prevent memory leaks
    })
    setTemplateThumbnails({})
  }

  const clearThumbnails = useCallback(() => {
    setThumbnailsRef({})
  }, [setThumbnailsRef])

  const applyThumbnailFromCache = useCallback(
    async (slideId, videoId) => {
      if (thumbnailCacheEnabled && !thumbnailsRef.current?.[slideId]) {
        const cachedThumbnail = await storage.get(getStorageKey(videoId, slideId)).catch(() => null)
        if (cachedThumbnail) {
          setThumbnailsRef((t) => ({ ...t, [slideId]: createImage(cachedThumbnail) }))
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    [setThumbnailsRef],
  )

  return {
    applyThumbnailFromCache,
    clearTemplateThumbnails,
    createOptimizedThumbnailAsync,
    deleteThumbnailsCache,
    dropThumbnailCache,
    flushThumbnailsCache,
    clearThumbnails,
    syncThumbnailsWithSlides,
    templateThumbnails,
    templateThumbnailsReady,
    thumbnailCacheEnabled,
    thumbnailsRef,
    thumbnailsReady,
    updatedThumbnails,
    updateThumbnail,
    updateThumbnailImmediately,
  }
}

// Custom hook to get the thumbnail for a specific slide.
export const useGetSlideThumbnail = ({ slideId, thumbnailsRef }) => {
  // initialize thumbnail state from thumbnailsRef
  const [thumbnail, setThumbnail] = useState(thumbnailsRef?.current?.[slideId])

  useEffect(() => {
    const handler = () => {
      const thumbnailFromRef = thumbnailsRef?.current?.[slideId]
      if (thumbnail !== thumbnailFromRef) setThumbnail(thumbnailFromRef)
    }

    // listen thumbnailsRef changes and update thumbnail state
    thumbnailsRefUpdateEmitter.on(THUMBNAILS_REF_UPDATE_EVENT_NAME, handler)
    return () => thumbnailsRefUpdateEmitter.off(THUMBNAILS_REF_UPDATE_EVENT_NAME, handler)
  }, [slideId, thumbnailsRef, thumbnail])

  return thumbnail
}

// Custom hook to get all current thumbnails
export const useGetSlidesThumbnails = ({ thumbnailsRef }) => {
  // initialize thumbnails state from thumbnailsRef
  const [thumbnails, setThumbnails] = useState(thumbnailsRef?.current)

  useEffect(() => {
    const handler = () => {
      setThumbnails(thumbnailsRef?.current)
    }

    // listen thumbnailsRef changes and update thumbnails state
    thumbnailsRefUpdateEmitter.on(THUMBNAILS_REF_UPDATE_EVENT_NAME, handler)
    return () => thumbnailsRefUpdateEmitter.off(THUMBNAILS_REF_UPDATE_EVENT_NAME, handler)
  }, [thumbnailsRef])

  return thumbnails
}
