import { memo, useMemo } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import { Upload, Row, Col, Tooltip, Select, Spin, Segmented, InputNumber } from 'antd'
import { SoundOutlined, SearchOutlined, QuestionCircleOutlined } from '@ant-design/icons'

import music from '../../../../data/music'
import Icon from '../../../../components/Icon'
import { useMusicTabState } from './useMusicTabState'
import LazySlider from '../../../../components/LazySlider'
import { customUploadRequest } from '../../../../utils/api'
import { CustomMusicScrollBar } from './customMusicScrollBar'
import { MusicItem } from './components/musicItem'

import { useSlideDuration } from '../../../../hooks/useSlideDuration'
import { DEFAULT_SLIDE_DURATION } from '../../slide/canvas/constans'

import { style } from './constants'
import configService from '../../../../utils/config'

const { Dragger } = Upload

const saveIcon = <Icon name="save" />
const headphoneIcon = <Icon name="headphone" />

const musicURL = 'https://d3u63mhbhkevz8.cloudfront.net/music/'
const defaultVolume = 0.18

export const MusicContent = memo((props) => {
  const {
    videoRef,
    videoDataMusicUrl,
    videoDataMusicVolume,
    videoDataMusicShift,
    videoDataMusicEndShift,
    videoId,
    time,
    duration,
    stableUpdateVideo,
    playerActivePreview,
    playerCurrentTime,
    stopVideo,
  } = props

  const {
    shift,
    musicTab,
    endShift,
    userMusic,
    musicData,
    musicAudio,
    stableApplyMusic,
    pauseMusic,
    stableDeleteMusic,
    uploadMusic,
    onStablePlayClick,
    createAudio,
    validateShift,
    setUserMusic,
    beforeUpload,
    musicFilters,
    playingTrack,
    setMusicFilters,
    setPlayingTrack,
    validateEndShift,
    isMusicUploading,
    handleShiftInput,
    handleStablePauseClick,
    isUploadAvailable,
    handleDraggerClick,
    handleEndShiftInput,
    handleChangeMusicTab,
  } = useMusicTabState({
    videoRef,
    videoDataMusicShift,
    videoDataMusicEndShift,
    time,
    duration,
    stopVideo,
    playerActivePreview,
    playerCurrentTime,
    stableUpdateVideo,
  })

  const url = videoDataMusicUrl ? new URL(videoDataMusicUrl).pathname : null
  const { apiUrl } = configService.get().urls

  return (
    <div className="music tab-content">
      {videoDataMusicUrl && (
        <>
          <Row align="middle" style={style.marginBottom16}>
            <Col span={6}>Volume</Col>
            <Col span={18}>
              <LazySlider
                min={0.03}
                max={0.45}
                step={0.01}
                tooltip={{ formatter: (v) => Math.round(v * 100) }}
                value={videoDataMusicVolume === undefined ? defaultVolume : videoDataMusicVolume}
                onStep={(v) => {
                  if (musicAudio.current) musicAudio.current.volume = v
                }}
                onChange={(v) => {
                  if (musicAudio.current) musicAudio.current.volume = v
                  stableUpdateVideo({ data: { ...videoRef.current.data, musicVolume: v } })
                }}
              />
            </Col>
          </Row>
          <Row align="middle" style={style.marginBottom16}>
            <Col span={10}>
              Beginning Shift{' '}
              <Tooltip title="Positive value means music will start in X seconds after video starts (starts with delay), negative value - music will be trimmed for X seconds in the beginning.">
                <QuestionCircleOutlined />
              </Tooltip>
            </Col>
            <Col span={14}>
              {validateShift ? (
                <Tooltip title="Your speech is shorter than inputed value">
                  <InputNumber
                    value={shift}
                    onChange={handleShiftInput}
                    style={{ borderColor: `${validateShift ? '#cf5d60' : '#bdbdbd'}` }}
                  />
                </Tooltip>
              ) : (
                <InputNumber value={shift} onChange={handleShiftInput} />
              )}
            </Col>
          </Row>
          <Row align="middle" style={style.marginBottom16}>
            <Col span={10}>
              End Shift{' '}
              <Tooltip title="Music will stop playing in X seconds before end of the video.">
                <QuestionCircleOutlined />
              </Tooltip>
            </Col>
            <Col span={14}>
              {validateEndShift ? (
                <Tooltip title="Your speech is shorter than inputed value">
                  <InputNumber
                    min={0}
                    value={endShift}
                    onChange={handleEndShiftInput}
                    style={{ borderColor: `${validateEndShift ? '#cf5d60' : '#bdbdbd'}` }}
                  />
                </Tooltip>
              ) : (
                <InputNumber min={0} value={endShift} onChange={handleEndShiftInput} />
              )}
            </Col>
          </Row>
        </>
      )}
      <Segmented
        value={musicTab}
        className="segmented-default"
        block
        options={[
          {
            label: (
              <span>
                {headphoneIcon}
                Stock Music
              </span>
            ),
            value: 'tracks',
          },
          {
            label: (
              <Tooltip
                overlayStyle={{ visibility: isUploadAvailable ? 'hidden' : 'visible' }}
                title="Please upgrade to paid plan to add your own music"
              >
                <span>
                  {saveIcon}
                  Upload
                </span>
              </Tooltip>
            ),
            value: 'upload',
          },
        ]}
        onChange={handleChangeMusicTab}
      />
      <div className="music-tracks" style={{ display: musicTab === 'tracks' ? 'flex' : 'none' }}>
        <Row justify="space-between" wrap={false} style={{ width: '99%', margin: '0 auto 10px' }}>
          <Col flex="33%">
            <Select
              style={style.width100P}
              defaultValue="All genres"
              size="small"
              onChange={(v) => setMusicFilters({ ...musicFilters, genre: v })}
            >
              <Select.Option>All genres</Select.Option>
              {music
                .map((obj) => obj.genre)
                .filter((g, i, self) => self.indexOf(g) === i)
                .map((genre) => (
                  <Select.Option key={genre}>{genre}</Select.Option>
                ))}
            </Select>
          </Col>
          <Col flex="65%">
            <Select
              mode="multiple"
              style={style.width100P}
              placeholder="Search by tags"
              suffixIcon={<SearchOutlined />}
              showSearch={false}
              showArrow="true"
              maxTagCount="responsive"
              size="small"
              onChange={(values) => setMusicFilters({ ...musicFilters, tags: values })}
            >
              {music
                .map((obj) => obj.tags)
                .flat()
                .filter((g, i, self) => self.indexOf(g) === i)
                .map((t) => (
                  <Select.Option key={t}>{t}</Select.Option>
                ))}
            </Select>
          </Col>
        </Row>
        <div className="music-tracks-wrapper">
          <Scrollbars style={{ minHeight: 400 }}>
            {musicData.length ? (
              musicData.map(({ name, file, genre, tags, shift }) => (
                <MusicItem
                  key={file + name}
                  name={name}
                  file={file}
                  genre={genre}
                  tags={tags}
                  isPlaying={playingTrack === musicURL + file}
                  isActive={url === '/music/' + file}
                  musicURL={musicURL}
                  playerActivePreview={playerActivePreview}
                  stableApplyMusic={stableApplyMusic}
                  stableDeleteMusic={stableDeleteMusic}
                  onStablePlayClick={onStablePlayClick}
                  handleStablePauseClick={handleStablePauseClick}
                />
              ))
            ) : (
              <p style={style.nothingFound}>Nothing found</p>
            )}
          </Scrollbars>
        </div>
      </div>
      <div className="music-upload" style={{ display: musicTab === 'upload' ? 'flex' : 'none' }}>
        <Dragger
          name="file"
          action={`${apiUrl}/uploads/music/${videoId}`}
          accept=".mp3, .wav, .ogg, .aac, .m4a"
          multiple={false}
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={uploadMusic}
          disabled={!isUploadAvailable}
          customRequest={customUploadRequest}
          style={style.marginBottom16}
          onClick={handleDraggerClick}
        >
          <p className="ant-upload-drag-icon">
            <SoundOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">We are currently supporting most audio formats (mp3, wav, ogg, aac, m4a).</p>
        </Dragger>
        {isMusicUploading && <Spin size="large" />}
        <CustomMusicScrollBar
          musicUrl={videoDataMusicUrl}
          customMusic={userMusic}
          playerActivePreview={playerActivePreview}
          playingTrack={playingTrack}
          handleStablePauseClick={handleStablePauseClick}
          pauseMusic={pauseMusic}
          createAudio={createAudio}
          musicShift={videoDataMusicShift}
          setPlayingTrack={setPlayingTrack}
          stableDeleteMusic={stableDeleteMusic}
          stableApplyMusic={stableApplyMusic}
          setCustomMusic={setUserMusic}
          musicAudio={musicAudio}
        />
      </div>
    </div>
  )
})

MusicContent.displayName = 'MusicContent'

export const Music = memo((props) => {
  const { video, videoRef, slide, stableUpdateVideo, player, stopVideo, activeSlide } = props

  const { getApproxDuration } = useSlideDuration({ slide })

  const durationOffset = useMemo(() => {
    return video.slides
      .slice(0, activeSlide)
      .reduce((duration, slide) => duration + (slide.duration || getApproxDuration(slide) || DEFAULT_SLIDE_DURATION), 0)
  }, [activeSlide, player.activePreview])

  const time =
    (player.activePreview && player.status !== 'playing' ? 0 : player.currentTime) +
    (player.activePreview ? durationOffset : 0)

  const duration = video.slides.reduce(
    (duration, slide) => duration + (slide.duration || getApproxDuration(slide) || DEFAULT_SLIDE_DURATION),
    0,
  )

  return (
    <MusicContent
      videoRef={videoRef}
      videoDataMusicUrl={video?.data?.musicUrl}
      videoDataMusicVolume={video?.data?.musicVolume}
      videoDataMusicShift={video?.data?.musicShift}
      videoDataMusicEndShift={video?.data?.musicEndShift}
      videoId={video?._id}
      time={time}
      duration={duration}
      stopVideo={stopVideo}
      stableUpdateVideo={stableUpdateVideo}
      playerActivePreview={player.activePreview}
      playerCurrentTime={player.currentTime}
    />
  )
})

Music.displayName = 'Music'
