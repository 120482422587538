import React, { useRef, useState } from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { MinusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { Button, Form, Input, Modal, Row, Select, Spin, Tooltip } from 'antd'
import Icon from '../Icon'
import { useStore } from '../../store'
import { request } from '../../utils/api'
import { ROLES } from '../../routes/workspace/constants'
import './createWorkspaceModal.less'
import { sleep } from '../../utils/helpers'
import { useElaiNotification } from '../../hooks/useElaiNotification'

const { Option } = Select

const CreateWorkspaceModal = ({ isOpenCreateWorkspaceModal, setIsOpenCreateWorkspaceModal }) => {
  const notification = useElaiNotification()
  const [form] = Form.useForm()
  const [step, setStep] = useState(1)
  const [name, setName] = useState('')
  const scrollRef = useRef(null)
  const { refreshSession, user } = useStore((stores) => stores.authStore)
  const [isCreatingWorkspace, setIsCreatingWorkspace] = useState(false)

  const handleSkipAddUsers = async () => {
    form.setFieldValue('users', [])
    await onFinish({})
  }

  const onFinish = async (data) => {
    if (step === 1) {
      setStep(2)
      return
    }
    if (user?.accounts.find((u) => u?.name === name)) {
      notification.warning({
        key: 'workspace-already-exist',
        message: `Workspace "${name}" already exist`,
        duration: 6,
      })
      setStep(1)
      return
    }
    data.name = name
    setIsCreatingWorkspace(true)
    const res = await request({
      method: 'post',
      url: `/accounts`,
      data,
    })
    if (res) {
      notification.success({
        key: 'create-workspace-success',
        message: 'Workspace successfully created',
      })
      setIsOpenCreateWorkspaceModal(false)
      setStep(1)
      form.resetFields()
    }
    setIsCreatingWorkspace(false)
    refreshSession()
  }

  return (
    <Modal
      centered
      open={isOpenCreateWorkspaceModal}
      width={400}
      className="photo-avatar-modal"
      footer={null}
      onCancel={() => {
        setStep(1)
        setIsOpenCreateWorkspaceModal(false)
      }}
    >
      <Form layout="vertical" form={form} onFinish={onFinish} scrollToFirstError>
        {step === 1 ? (
          <>
            <h2>Create a new workspace</h2>
            <p className="subheader">Effortlessly organize videos and teams for seamless video creation.</p>

            <Form.Item
              name="name"
              label="Workspace name"
              extra="You can edit your workspace information anytime"
              rules={[
                {
                  required: true,
                  message: 'Workspace name is required',
                },
                {
                  max: 100,
                  message: 'Workspace name must be less than 100 characters',
                },
              ]}
            >
              <Input onChange={(e) => setName(e.target.value)} />
            </Form.Item>
          </>
        ) : (
          <>
            <Button type="link" className="button-back" onClick={() => setStep(1)}>
              <Icon name="left_arrow" />
              Back
            </Button>
            <h2>Whom would you like to add to the team?</h2>
            <p className="subheader">Add team members' email addresses and assign user roles.</p>
            <Form.List name="users">
              {(fields, { add, remove }) => (
                <>
                  <h4>Collaborators</h4>
                  <Scrollbars autoHeight autoHeightMax={240} ref={scrollRef}>
                    {fields.map(({ key, name, ...field }) => (
                      <div key={'div' + key} className="create-workspace-modal-users-row">
                        <Form.Item
                          {...field}
                          name={[name, 'email']}
                          // TODO validate users emails not working
                          rules={[
                            {
                              required: true,
                              message: `Email is required`,
                            },
                            {
                              max: 129,
                              message: 'Email must be less than 129 characters',
                            },
                            {
                              type: 'email',
                              message: 'The input is not valid E-mail!',
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter email"
                            style={{ width: 285 }}
                            addonAfter={
                              <Form.Item
                                {...field}
                                noStyle
                                name={[name, 'role']}
                                rules={[{ required: true, message: 'Role missing' }]}
                              >
                                <Select placement="bottomRight" dropdownStyle={{ minWidth: 120 }}>
                                  {ROLES.map((item) => (
                                    <Option key={item.role} value={item.role}>
                                      <Row
                                        justify="space-between"
                                        className="create-workspace-modal-role-select-name-row"
                                      >
                                        {item.role}
                                        <Tooltip placement="topLeft" title={item.tooltip}>
                                          <QuestionCircleOutlined />
                                        </Tooltip>
                                      </Row>
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            }
                          />
                        </Form.Item>

                        <MinusCircleOutlined
                          onClick={() => remove(name)}
                          className="create-workspace-modal-remove-user-button"
                        />
                      </div>
                    ))}

                    <Form.Item noStyle>
                      <Button
                        type="dashed"
                        onClick={async () => {
                          add({ role: 'user' })
                          await sleep(300)
                          scrollRef.current.scrollToBottom()
                          console.log()
                        }}
                        block
                        style={{ width: 300 }}
                      >
                        <Icon name="plus" /> Add team member
                      </Button>
                    </Form.Item>
                  </Scrollbars>
                </>
              )}
            </Form.List>
          </>
        )}
        <div className="create-workspace-modal-buttons-wrapper">
          {!isCreatingWorkspace ? (
            <>
              <Form.Item>
                <Button type="primary" htmlType="submit" size="large">
                  {step === 1 ? 'Continue' : 'Create'}
                </Button>
              </Form.Item>
              {step === 2 && (
                <Form.Item>
                  <Button type="link" htmlType="submit" onClick={handleSkipAddUsers}>
                    Skip for now
                  </Button>
                </Form.Item>
              )}
            </>
          ) : (
            <Spin />
          )}
        </div>
      </Form>
    </Modal>
  )
}

export default CreateWorkspaceModal
