import { useCallback } from 'react'
import { Alert } from 'antd'

import { Voice } from './voice'
import Canvas from './canvas/canvas'
import { SlideAdmin } from './slideAdmin'
import PlayerManager from './playerManager'

const marginTop15 = { marginTop: 15 }

const Slide = (props) => {
  const {
    slide,
    video,
    canvasRef,
    audioCache,
    fetchVideo,
    updateVideo,
    updateSlide,
    activeSlide,
    player,
    // for accessing actual state from outside react context
    playerRef,
    setPlayer,
    togglePlay,
    playNextSlide,
    manualChangingTime,
    canvasRegistry,
    undoLastChanges,
    workingAreaRef,
    activeSidebarTab,
    setActiveSidebarTab,
    updateActiveSlide,
    canvasActiveObject,
    canvasesContainerRef,
    activeObjectModifier,
    setCanvasActiveObject,
    setActiveObjectModifier,
    interactiveQuestion,
  } = props

  const { activePreview } = player

  const handleChangeBackground = (color) => {
    updateSlide({ canvas: { ...slide.canvas, background: color } }, { slideId: slide.id })
    canvasRef.current?.changeCanvasBackground(color)
  }
  const handleChangeSlideTime = (time) => {
    canvasRef.current?.onChangeSlideTime(time)
  }

  const discardActiveTextObject = useCallback(() => {
    if (canvasActiveObject?.type === 'textbox') {
      setActiveObjectModifier('discardActiveObject')
    }
  }, [canvasActiveObject?.type, setActiveObjectModifier])

  return (
    <div className="working-area" ref={workingAreaRef}>
      <Canvas
        ref={canvasRef}
        data={slide}
        video={video}
        player={player}
        playerRef={playerRef}
        setPlayer={setPlayer}
        canvasRegistry={canvasRegistry}
        updateSlide={updateSlide}
        activeSlide={activeSlide}
        activeSidebarTab={activeSidebarTab}
        setActiveSidebarTab={setActiveSidebarTab}
        fetchVideo={fetchVideo}
        canvasActiveObject={canvasActiveObject}
        setCanvasActiveObject={setCanvasActiveObject}
        activeObjectModifier={activeObjectModifier}
        setActiveObjectModifier={setActiveObjectModifier}
        canvasesContainerRef={canvasesContainerRef}
        playNextSlide={playNextSlide}
        interactiveQuestion={interactiveQuestion}
      />
      <div className="slide-controls">
        <PlayerManager
          slides={video.slides}
          slide={slide}
          canvasRegistry={canvasRegistry}
          activeSlide={activeSlide}
          updateActiveSlide={updateActiveSlide}
          player={player}
          setPlayer={setPlayer}
          togglePlay={togglePlay}
          manualChangingTime={manualChangingTime}
          changeCanvasBackground={handleChangeBackground}
          onChangeSlideTime={handleChangeSlideTime}
        />
        <Voice
          key={slide.id}
          video={video}
          data={slide}
          undoLastChanges={undoLastChanges}
          audioCache={audioCache}
          updateVideo={updateVideo}
          updateSlide={updateSlide}
          player={player}
          setPlayer={setPlayer}
          discardActiveTextObject={discardActiveTextObject}
        />
        {activePreview ? (
          <Alert
            message="This is just a preview. Click 'Render' to generate a video with avatar speaking."
            type="info"
            style={marginTop15}
          />
        ) : (
          <SlideAdmin
            data={slide}
            video={video}
            updateSlide={updateSlide}
            fetchVideo={fetchVideo}
            updateVideo={updateVideo}
          />
        )}
      </div>
    </div>
  )
}

export default Slide
