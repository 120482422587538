import { useState, useCallback, useEffect } from 'react'
import { debounce } from 'throttle-debounce'
import { Row, Col, Input, Tooltip, Switch } from 'antd'
import Icon from '../../../../components/Icon'
import SlideSelect from '../interactivity/components/slideSelect'
import { hasAnimation } from '../../../../utils/canvas/canvas'
import { useStore } from '../../../../store'
import { InteractivityWarning } from '../interactivity/components/interactivityWarning'

const URL_REGEX =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/

const InteractivityControls = ({
  video,
  activeSlide,
  activeObjectProps,
  setActiveObjectModifier,
  objectTypeInGroupCantBeInteractive,
  data,
  updateSlide,
}) => {
  const { user } = useStore((stores) => stores.authStore)

  const [link, setLink] = useState(activeObjectProps.meta?.interactivity?.link)

  const [isQuestionAdded, setIsQuestionAdded] = useState(false)

  const onChangeInteractivitySwitch = (checked) => {
    if (isQuestionAdded) return
    const { animation } = activeObjectProps
    const interactivity = checked ? { ...activeObjectProps.meta?.interactivity, type: 'slide', slide: null } : null
    if (interactivity && hasAnimation(activeObjectProps)) {
      animation.type = 'fade_in'
      animation.exitType = 'fade_out'
    }

    if (interactivity) {
      updateSlide({ transition: null, interactivity: { ...data.interactivity, nextSlide: null } })
    }

    setActiveObjectModifier({
      change: 'interactivitySwitch',
      value: {
        meta: {
          ...activeObjectProps.meta,
          interactivity,
        },
        animation,
      },
    })
  }

  const changeInteractivityType = (type) => {
    if (type === activeObjectProps.meta?.interactivity?.type) return
    setActiveObjectModifier({
      change: {
        meta: {
          ...activeObjectProps.meta,
          interactivity: { ...activeObjectProps.meta?.interactivity, type },
        },
      },
    })
  }

  const handleChangeSlide = (value) => {
    setActiveObjectModifier({
      change: {
        meta: {
          ...activeObjectProps.meta,
          interactivity: { ...activeObjectProps.meta?.interactivity, slide: value },
        },
      },
    })
  }

  const saveLink = (value) => {
    if (value === activeObjectProps.meta.interactivity.link || !URL_REGEX.test(value)) return
    setActiveObjectModifier({
      change: {
        meta: {
          ...activeObjectProps.meta,
          interactivity: { ...activeObjectProps.meta.interactivity, link: value },
        },
      },
    })
  }

  const debounceSaveLink = useCallback(
    debounce(800, (value) => saveLink(value)),
    [activeObjectProps.meta],
  )

  const onChangeLink = (event) => {
    const value = event.target.value
    setLink(value)
    debounceSaveLink(value)
  }

  useEffect(() => {
    if (activeObjectProps.meta?.interactivity) setLink(activeObjectProps.meta.interactivity.link)
  }, [activeObjectProps.id])

  useEffect(() => {
    const hasQuestions = video.slides[activeSlide].canvas.objects.some((element) => element.type === 'question')
    setIsQuestionAdded(hasQuestions)
  }, [video])

  const interactiveSwitchTooltip = objectTypeInGroupCantBeInteractive
    ? `This group contains an element of type {${objectTypeInGroupCantBeInteractive}} unsupported for interactivity. Please set up interaction separately for such elements.`
    : isQuestionAdded
    ? 'Reminder: Additional interactive elements cannot be added to a slide with "Questions"'
    : null

  return (
    <div className="interactivity interactivity-elements-controls">
      <Row align="middle" justify="space-between" className="controls-title-wrapper">
        <Col className="title-info-wrapper">
          <h4 className="controls-title-wrapped">Interactivity</h4>
          {user?.account?.source?.provider === 'panopto' ? (
            <InteractivityWarning feature="interactivity" />
          ) : (
            <Tooltip title="You can add interactivity to any element, allowing viewers to click on them in the video. These elements can redirect viewers to another video section or open a link in a new tab. After the avatar's speech, the video pauses until the viewer clicks.">
              <Icon name="info" />
            </Tooltip>
          )}
        </Col>
        <Col>
          <Tooltip title={interactiveSwitchTooltip} placement="topRight">
            <Switch
              disabled={objectTypeInGroupCantBeInteractive || isQuestionAdded}
              checked={activeObjectProps.meta?.interactivity}
              onChange={onChangeInteractivitySwitch}
            />
          </Tooltip>
        </Col>
      </Row>
      {activeObjectProps.meta?.interactivity && (
        <>
          <Row gutter={8} className="interaction-type-controls">
            <Col span={12}>
              <div
                className={`interaction-card ${activeObjectProps.meta.interactivity.type === 'slide' ? 'active' : ''}`}
                onClick={() => changeInteractivityType('slide')}
              >
                <Icon name="external_link" />
                <span className="interaction-title">Go to the slide</span>
              </div>
            </Col>
            <Col span={12}>
              <div
                className={`interaction-card ${activeObjectProps.meta?.interactivity?.type === 'link' ? 'active' : ''}`}
                onClick={() => changeInteractivityType('link')}
              >
                <Icon name="link" className="transformed" />
                <span className="interaction-title">Open a link</span>
              </div>
            </Col>
          </Row>
          {activeObjectProps.meta.interactivity.type === 'slide' && (
            <SlideSelect
              slides={video.slides}
              activeSlide={activeSlide}
              title={<span>Go to the slide</span>}
              value={activeObjectProps.meta.interactivity.slide || null}
              handleChangeSlide={handleChangeSlide}
            />
          )}
          {activeObjectProps.meta.interactivity.type === 'link' && (
            <div className="url-input-wrapper">
              <p className="input-label">URL</p>
              <Input
                placeholder="Paste a link here"
                value={link}
                status={link && !URL_REGEX.test(link) ? 'error' : null}
                allowClear
                onChange={onChangeLink}
              />
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default InteractivityControls
