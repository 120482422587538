import { Col, Tooltip, TreeSelect } from 'antd'

import { STYLE } from '../constants'
import { getFontFamilyWithFallback, mapSubfamilyToStyle } from '../../../../../../utils/fabric/fontUtils'
import { useEffect, useRef, useState } from 'react'
import { textTypes } from '../../../../../../utils/canvas/canvas'
import configService from '../../../../../../utils/config'

const { TreeNode } = TreeSelect

const checkIsPartOfTextSelected = (textObject) => {
  const { selectionStart, selectionEnd, text } = textObject
  return selectionStart !== selectionEnd && (selectionStart !== 0 || selectionEnd !== text.length)
}

export const FontSelection = (props) => {
  const [changeFontDisabled, setChangeFontDisabled] = useState(false)
  const intervalCheckSelection = useRef(null)
  const { activeObjectProps, getTextSelectionFontFamily, userFonts, setActiveObjectModifier, customFontsForSelect } =
    props

  const { systemFonts } = configService.get()
  const treeSelectValue = getTextSelectionFontFamily(activeObjectProps)

  const myFontsTitle = <span style={STYLE.myFontsTitle}>My Fonts</span>
  const allFontsTitle = <span style={STYLE.allFontsTitle}>All Fonts</span>

  const handleFontSelect = (v) => {
    if (checkIsPartOfTextSelected(activeObjectProps)) return

    const font = customFontsForSelect.find((font) => font._id === v)
    if (font) {
      // custom font
      setActiveObjectModifier({
        change: {
          fontFamily: getFontFamilyWithFallback(font.name),
          ...mapSubfamilyToStyle(font.subfamily),
          fontId: font._id,
        },
      })
    } else {
      setActiveObjectModifier({ change: { fontFamily: getFontFamilyWithFallback(v), fontId: null } })
    }
  }

  useEffect(() => {
    if (!textTypes.includes(activeObjectProps?.type)) return

    intervalCheckSelection.current = setInterval(() => {
      setChangeFontDisabled(checkIsPartOfTextSelected(activeObjectProps))
    }, 100)
    return () => {
      clearInterval(intervalCheckSelection.current)
    }
  }, [activeObjectProps?.type])

  return (
    <Col>
      <Tooltip
        title="You cannot change the font for a specific word. Please select the entire text block to change the font."
        overlayStyle={{
          visibility: changeFontDisabled ? 'visible' : 'hidden',
        }}
      >
        <TreeSelect
          style={STYLE.width128}
          treeDefaultExpandAll
          value={treeSelectValue}
          onChange={handleFontSelect}
          popupClassName="fonts-select"
          showSearch={true}
          disabled={changeFontDisabled}
        >
          {userFonts.length > 0 && (
            <TreeNode value="custom" title={myFontsTitle} selectable={false}>
              {customFontsForSelect.map((font) => {
                return (
                  <TreeNode
                    key={font._id}
                    value={font._id}
                    checkable={false}
                    title={
                      <span
                        className="font-name"
                        style={{ fontFamily: `"${font.name}"`, ...mapSubfamilyToStyle(font.subfamily) }}
                      >
                        {font.displayName}
                      </span>
                    }
                  />
                )
              })}
            </TreeNode>
          )}
          <TreeNode value="general" title={allFontsTitle} selectable={false}>
            {systemFonts.map((font) => {
              return (
                <TreeNode
                  key={font}
                  value={font}
                  checkable={false}
                  title={
                    <span className="font-name" style={{ fontFamily: font }}>
                      {font}
                    </span>
                  }
                />
              )
            })}
          </TreeNode>
        </TreeSelect>
      </Tooltip>
    </Col>
  )
}
