import { useState, useEffect, useCallback } from 'react'
import { useStableCallback } from '../../hooks/useStableCallback'
import { clone, uniqueId } from '../../utils/helpers'
import { blankSlide } from '../../utils/videos'
import { keyMap } from './constants'
import { useStore } from '../../store'

// Remove deleted slide as next slide for interactive slides
const removeNextInteractivitySlide = (slides, i) => {
  const slideId = slides[i].id
  const updatedSlides = slides.map((slide) => {
    if (slide.interactivity?.nextSlide === slideId) slide.interactivity.nextSlide = null
    const objects = slide.canvas.objects.map((obj) => {
      if (obj.type === 'question') {
        if (obj.onCorrect?.slide === slideId) obj.onCorrect.slide = null
        if (obj.onWrong?.slide === slideId) obj.onWrong.slide = null
      } else if (obj.meta?.interactivity?.slide === slideId) obj.meta.interactivity.slide = null
      return obj
    })
    return { ...slide, canvas: { ...slide.canvas, objects } }
  })
  return updatedSlides
}

export const useSlideActions = (props) => {
  const {
    video,
    activeSlide,
    canvasActiveObject,
    canvasRegistry,
    changesHistory,
    updateVideo,
    updateActiveSlide,
    undoLastChanges,
    togglePlay,
  } = props

  const [undoRequest, setUndoRequest] = useState(false)

  const addSlide = useCallback(() => {
    console.log('track')
    const { slides } = video
    const slide = clone(blankSlide())
    const prevSlide = slides[activeSlide]
    const prevSlideAvatar = prevSlide.canvas.objects.find((obj) => obj.type === 'avatar')
    const currentAvatar = slide.canvas.objects.find((obj) => obj.type === 'avatar')

    slide.id = uniqueId()
    slide.avatar = prevSlide.avatar
    currentAvatar.src = prevSlideAvatar.src
    currentAvatar.tilt = prevSlideAvatar.tilt
    slide.language = prevSlide.language
    slide.voice = prevSlide.voice
    slide.voiceProvider = prevSlide.voiceProvider
    slide.newlyAdded = true
    slides.splice(activeSlide + 1, 0, slide)

    updateVideo({ slides })
    updateActiveSlide(activeSlide + 1)
  }, [video?.slides, activeSlide])

  const stableAddSlide = useStableCallback(addSlide)

  const { user } = useStore((stores) => stores.authStore)
  const slidesLimit = user.account.status === 'trial' ? 3 : 100

  const deleteSlide = useCallback(
    (i) => {
      const { slides } = video
      canvasRegistry.deleteCanvas(slides[i])
      const updatedSlides = removeNextInteractivitySlide(slides, i)
      updatedSlides.splice(i, 1)
      updateVideo({ slides: updatedSlides })
      if (i <= activeSlide) updateActiveSlide(activeSlide === 0 ? '0' : activeSlide - 1)
    },
    [video?.slides, canvasRegistry, activeSlide],
  )

  const stableDeleteSlide = useStableCallback(deleteSlide)

  const copySlide = useCallback(async () => {
    const selection = window.getSelection()
    if (!canvasActiveObject && selection?.type !== 'Range') {
      const selectedSlide = video.slides[activeSlide]
      delete selectedSlide.status
      delete selectedSlide.screenshot
      selectedSlide.copiedSlide = true
      await navigator.clipboard.writeText(JSON.stringify(selectedSlide))
    }
  }, [canvasActiveObject, video?.slides[activeSlide]])

  const pasteSlide = useCallback(async () => {
    if (!canvasActiveObject) {
      try {
        const temp = await navigator.clipboard.readText()
        const slide = JSON.parse(temp)
        if (slide?.copiedSlide) {
          delete slide.copiedSlide
          slide.id = uniqueId()
          const updatedSlides = [...video.slides]
          updatedSlides.splice(activeSlide + 1, 0, slide)
          updateVideo({ slides: updatedSlides })
        }
      } catch (error) {
        console.error('Error pasting slide:', error.message)
      }
    }
  }, [canvasActiveObject, video?.slides, activeSlide])

  const duplicateSlide = useCallback(
    (i) => {
      const { slides } = video
      const s = clone(slides[i])
      s.id = uniqueId()
      delete s.status
      delete s.screenshot
      const updatedSlides = [...slides]
      updatedSlides.splice(i + 1, 0, s)
      updateVideo({ slides: updatedSlides })
      updateActiveSlide(i + 1)
    },
    [video?.slides],
  )

  const stableDuplicateSlide = useStableCallback(duplicateSlide)

  /**
   * Handle all keydown events related to video and slides besides of canvas objects events
   */
  useEffect(() => {
    const keydownHandler = async (e) => {
      const tagName = e.target.tagName?.toLowerCase()
      if (tagName === 'textarea' || (tagName === 'input' && e.target.type !== 'radio')) return

      if (e.keyCode === keyMap.BACKSPACE || e.keyCode === keyMap.DELETE) {
        if (!canvasActiveObject && video?.slides.length > 1) deleteSlide(activeSlide)
      } else if (e.code === 'Space') {
        e.preventDefault()
        togglePlay()
      } else if (e.code === 'KeyN') {
        // if user reached limit of slides do not add a new one
        if (video?.slides.length >= slidesLimit) return
        addSlide()
      }

      if (!e.metaKey && !e.ctrlKey) return

      if (e.code === 'KeyZ') {
        // UNDO
        // Update onkeydown for each slide
        e.preventDefault()
        setUndoRequest(true)
      } else if (e.code === 'KeyC') {
        await copySlide()
      } else if (e.code === 'KeyV') {
        await pasteSlide()
      } else if (e.code === 'KeyD') {
        e.preventDefault()
        if (video?.slides.length >= slidesLimit) return
        duplicateSlide(activeSlide)
      }
    }
    document.addEventListener('keydown', keydownHandler)
    return () => document.removeEventListener('keydown', keydownHandler)
  }, [
    video?._id,
    changesHistory.length,
    activeSlide,
    canvasActiveObject,
    copySlide,
    pasteSlide,
    addSlide,
    deleteSlide,
    duplicateSlide,
  ])

  /**
   * Workaround to ensure that undo will be processed in React context
   */
  useEffect(() => {
    if (undoRequest) {
      setUndoRequest(false)
      undoLastChanges()
    }
  }, [undoRequest])

  return {
    stableAddSlide,
    stableDeleteSlide,
    stableDuplicateSlide,
  }
}
