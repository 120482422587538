import React, { useState } from 'react'
import { Button, Tooltip } from 'antd'
import Icon from '../../../../../../components/Icon'
import LibraryFilter from './libraryFilter'

const LibraryFilterButton = (props) => {
  const { filesType, setShouldApplyFilter, filter, setQuery, updateFilter } = props
  const [isVisible, setIsVisible] = useState(false)
  const [notFittingHeight, setNotFittingHeight] = useState(null)

  const filterHandler = () => {
    setIsVisible(!isVisible)
  }

  const onApplyFilter = () => {
    setIsVisible(false)
    setShouldApplyFilter(true)
  }

  return (
    <Tooltip
      arrow={false}
      title={
        <LibraryFilter
          filter={filter}
          setQuery={setQuery}
          filesType={filesType}
          updateFilter={updateFilter}
          onApplyFilter={onApplyFilter}
        />
      }
      // move the tooltip to the left if it's not fitting at the bottom
      placement={notFittingHeight ? 'left' : 'bottomRight'}
      open={isVisible}
      color="#ffffff"
      trigger="click"
      onOpenChange={filterHandler}
      id={`search-media-tooltip-${filesType}`}
      classNames={{ root: 'search-media-tooltip' }}
      onPopupAlign={(domNode) => {
        const rect = domNode.getBoundingClientRect()
        // if the tooltip is not fitting the height, we need to move it to the left
        if (rect.bottom > window.innerHeight - 10) {
          setNotFittingHeight(rect.height)

          // if the tooltip is fitting the height, we need to move it back to the bottom
        } else if (notFittingHeight && rect.height < notFittingHeight) {
          setNotFittingHeight(null)
        }
      }}
    >
      <Button
        type={isVisible ? 'primary' : 'default'}
        icon={<Icon name="filters" />}
        onClick={filterHandler}
        className="search-media-header-filter-button"
      />
    </Tooltip>
  )
}

export default LibraryFilterButton
