import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Popover, Tooltip, Button } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import Icon from '../../../../../components/Icon'
import { videoFormats } from '../../../../../data/formats'
import { CANVAS_WIDTH } from '../../../../video/slide/canvas/constans'
import ChangeLayout from './changeLayout'
import clone from 'lodash/clone'
import EditSlideButtons from './editSlideButtons'
import ChangePptx from './changePptx'
import { track } from '../../../../../utils/analytics'

const layoutItemWidth = 130
const layoutPreviewWidth = 270

const titles = {
  editSlide: <h4>Edit Slide</h4>,
  pptxStatic: <h4>Revert slide to static image</h4>,
  pptxDynamic: <h4>Convert slide to dynamic</h4>,
  changeLayout: (
    <>
      <h4>Change layout</h4>
      <Tooltip title="Choose the new layout for your slide.">
        <Icon name="info" />
      </Tooltip>
    </>
  ),
}

const EditSlidePopover = ({
  slideIndex,
  slide,
  layoutsData,
  video,
  applyPptxChanges,
  applyLayoutToSlide,
  isEditorBusy,
}) => {
  const [isOpenLayoutsPopover, setIsOpenLayoutsPopover] = useState(false)
  const [isApplyingLayout, setIsApplyingLayout] = useState(false)
  const [selectedLayout, setSelectedLayout] = useState(null)
  const [currentView, setCurrentView] = useState('editSlide')

  const slideItemThumbnailRef = useRef(null)

  const toggleLayoutsPopover = () => {
    if (isEditorBusy) return
    setIsOpenLayoutsPopover((prevState) => !prevState)
    if (selectedLayout) setSelectedLayout(null)
  }

  const onClickBackButton = () => {
    if (selectedLayout) return setSelectedLayout(null)
    if (isFromPptx) setCurrentView('editSlide')
  }

  const getLimiterWidth = (layoutImageWidth) => {
    const videoFormatWidth = (videoFormats[video.data?.format || '16_9'].width * layoutImageWidth) / CANVAS_WIDTH
    return { width: (layoutImageWidth - videoFormatWidth) / 2 }
  }

  const isFromPptx = slide?.pptx?.dynamic && slide?.pptx?.static

  const layoutItemLimiterWidth = useMemo(() => getLimiterWidth(layoutItemWidth), [video.data?.format])
  const layoutPreviewLimiterWidth = useMemo(() => getLimiterWidth(layoutPreviewWidth), [video.data?.format])

  const isBackButtonVisible = useMemo(
    () => (currentView !== 'editSlide' && isFromPptx) || (!isFromPptx && selectedLayout),
    [currentView, isFromPptx, selectedLayout],
  )

  const handlePopoverOnOpenChange = (isOpen) => {
    if (selectedLayout) setSelectedLayout(null)
    if (isFromPptx) setCurrentView('editSlide')
    setIsOpenLayoutsPopover(isOpen)
  }

  const handlePptxContentChange = async () => {
    const { pptx } = slide
    slide.avatar = clone(pptx[pptx.isStatic ? 'dynamic' : 'static'].avatar)
    slide.canvas = clone(pptx[pptx.isStatic ? 'dynamic' : 'static'].canvas)
    slide.story = clone(pptx[pptx.isStatic ? 'dynamic' : 'static'].story)
    slide.pptx.isStatic = !pptx.isStatic
    setCurrentView('editSlide')
    setIsApplyingLayout(true)
    setIsOpenLayoutsPopover((prevState) => !prevState)
    await applyPptxChanges(slide)
    setIsApplyingLayout(false)
  }

  useEffect(() => {
    if (!isFromPptx) setCurrentView('changeLayout')
  }, [isFromPptx])

  useEffect(() => {
    if (!['pptxStatic', 'pptxDynamic'].includes(currentView)) return
    if (slide.pptx) {
      track('story_ppt_static_convert', { id: video._id, type: slide.pptx.isStatic ? 'toDynamic' : 'toStatic' })
    }
  }, [currentView])

  if (!layoutsData.thumbnails) return null

  return (
    <Popover
      open={isOpenLayoutsPopover}
      overlayClassName="edit-slide-popover"
      placement="rightTop"
      arrow={false}
      trigger="click"
      onOpenChange={handlePopoverOnOpenChange}
      getPopupContainer={(triggerNode) => triggerNode}
      content={
        <div className="edit-slide-popover-content">
          <div className="popover-title-wrapper">
            <div className="popover-title">
              <Button
                className={`title-btn ${isBackButtonVisible ? 'visible' : 'hidden'}`}
                type="text"
                size="small"
                icon={<Icon name="left_arrow" />}
                onClick={onClickBackButton}
              />
              {titles[currentView]}
            </div>
            <Button
              type="text"
              size="small"
              className="title-btn close"
              icon={<Icon name="close_simple" />}
              onClick={toggleLayoutsPopover}
            />
          </div>
          {['pptxStatic', 'pptxDynamic', 'editSlide'].includes(currentView) && (
            <div className="confirmation-content visible">
              <div
                className={`slide-item-image layout-image`}
                style={{
                  backgroundImage: `url(${layoutsData.thumbnails[slide.id]?.src})`,
                }}
              >
                <div className="video-format-limiter left" style={layoutItemLimiterWidth} />
                <div className="video-format-limiter right" style={layoutItemLimiterWidth} />
              </div>
              {currentView === 'editSlide' && (
                <EditSlideButtons
                  isStatic={slide.pptx?.isStatic}
                  setCurrentView={setCurrentView}
                  isApplyingLayout={isApplyingLayout}
                />
              )}
              {['pptxStatic', 'pptxDynamic'].includes(currentView) && (
                <ChangePptx
                  isStatic={slide.pptx.isStatic}
                  setCurrentView={setCurrentView}
                  isApplyingLayout={isApplyingLayout}
                  handlePptxContentChange={handlePptxContentChange}
                />
              )}
            </div>
          )}
          {currentView === 'changeLayout' && (
            <ChangeLayout
              layoutsData={layoutsData}
              isApplyingLayout={isApplyingLayout}
              selectedLayout={selectedLayout}
              setSelectedLayout={setSelectedLayout}
              setIsApplyingLayout={setIsApplyingLayout}
              applyLayoutToSlide={applyLayoutToSlide}
              toggleLayoutsPopover={toggleLayoutsPopover}
              layoutItemLimiterWidth={layoutItemLimiterWidth}
              layoutPreviewLimiterWidth={layoutPreviewLimiterWidth}
            />
          )}
        </div>
      }
    >
      <div
        ref={slideItemThumbnailRef}
        className={`slide-item-thumbnail unselectable ${isEditorBusy ? 'disabled' : ''}`}
        contentEditable={false}
        suppressContentEditableWarning={true}
      >
        <div className="slide-item-index">
          <div>{slideIndex + 1 < 10 ? `0${slideIndex + 1}` : slideIndex + 1}</div>
          {slide.pptx?.isStatic && (
            <Tooltip placement="bottom" title="This slide is a static image">
              <Icon name="image_rectangle" className="own-colors" />
            </Tooltip>
          )}
        </div>
        <Tooltip
          placement="bottom"
          title={isFromPptx ? 'Edit slide' : 'Change slide layout'}
          getPopupContainer={() => slideItemThumbnailRef.current}
          trigger={isEditorBusy ? [] : ['hover']}
        >
          <div
            className={`slide-item-image ${isOpenLayoutsPopover ? 'active' : ''}`}
            style={{
              backgroundImage: `url(${isApplyingLayout ? null : layoutsData.thumbnails[slide.id]?.src})`,
            }}
            onClick={toggleLayoutsPopover}
          >
            {(isApplyingLayout || !layoutsData.thumbnails[slide.id]?.src) && <LoadingOutlined />}
            {!isApplyingLayout && (
              <div className="item-image-mask">
                <Icon name={isFromPptx ? 'edit' : 'web_layout'} />
              </div>
            )}
            <div className="video-format-limiter left" style={layoutItemLimiterWidth} />
            <div className="video-format-limiter right" style={layoutItemLimiterWidth} />
          </div>
        </Tooltip>
      </div>
    </Popover>
  )
}

export { EditSlidePopover }
